import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Fade, Container, Row, Col, Form, Modal} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import {Formik} from 'formik';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {Redirect} from 'react-router-dom';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import {SPORTS, imageTypes, affiliationType} from '../constants';
import {useTranslation} from 'react-i18next';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import binIcon from '../images/bin_icon.svg';

registerPlugin(FilePondPluginImagePreview);

const PartnerField = ({
  ownedField,
  save,
  token,
  removeFile,
  showCalendar,
  deleteField,
  profile,
  openFixedCostsModal,
  isFixedCostsModalVisible,
  closeFixedCostsModal,
  createFixedCost,
  deleteFixedCost,
  affiliations
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (ownedField === null) {
    return null;
  }

  const imagesNames = (ownedField.imagesNames || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  const background = (ownedField.background || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  let dialog = null;
  const onRemove = id => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('FIELD_DELETE_CONFIRM'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteField(id),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const onDeleteCost = (costId, fieldId) => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('CONFIRM_DELETE_COST'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteFixedCost(costId, fieldId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={Object.assign({}, ownedField)}
      onSubmit={save}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100}}>
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={6}>
                    <Row>
                      <Col>
                        <h4>{values.id ? values.name : t('NEW_FIELD')}</h4>
                      </Col>
                      {
                        ownedField.id === null ?
                          null :
                          <Col>
                            <Button variant="light" className="float-right" type="button" onClick={showCalendar(ownedField.id)}>{t('CALENDAR')}</Button>
                          </Col>
                      }
                    </Row>
                    {
                      ownedField.id === null ?
                        null :
                        <Fragment>
                          <Form.Label>{t('FIELD_VIDEO_COVER')}</Form.Label>
                          <FilePond
                            files={background}
                            server={
                              {
                                url: `${Configuration.apiEndPoint}/partners/`,
                                process: {
                                  url: `./fields/${ownedField.id}/images`,
                                  ondata: formData => {
                                    formData.append('image_type', imageTypes.background);
                                    return formData;
                                  }
                                },
                                revert: `./fields/${ownedField.id}/images`,
                                restore: `./fields/${ownedField.id}/images`,
                                fetch: `./fields/${ownedField.id}/images`,
                                load: `./fields/${ownedField.id}/images?load=`,
                                headers: {Authorization: `Bearer ${token}`},
                                remove: (source, load) => {
                                  removeFile(source, ownedField.id);
                                  load();
                                }
                              }
                            }
                          />
                          <Form.Label>{t('PHOTO')}</Form.Label>
                          <FilePond
                            allowMultiple
                            files={imagesNames}
                            server={
                              {
                                url: `${Configuration.apiEndPoint}/partners/`,
                                process: {
                                  url: `./fields/${ownedField.id}/images`,
                                  ondata: formData => {
                                    formData.append('image_type', imageTypes.field);
                                    return formData;
                                  }
                                },
                                revert: `./fields/${ownedField.id}/images`,
                                restore: `./fields/${ownedField.id}/images`,
                                fetch: `./fields/${ownedField.id}/images`,
                                load: `./fields/${ownedField.id}/images?load=`,
                                headers: {Authorization: `Bearer ${token}`},
                                remove: (source, load) => {
                                  removeFile(source, ownedField.id);
                                  load();
                                }
                              }
                            }
                            maxFiles={3}
                          />
                        </Fragment>
                    }
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('SPORTS')}*</Form.Label>
                        <Form.Control required as="select" name="sport" value={values.sport || ''} onChange={handleChange}>
                          <option value="">{t('SPORTS_SELECT')}</option>
                          {Object.keys(SPORTS).map(s => <option key={s} value={s}>{s}</option>)}
                        </Form.Control>
                        <Form.Text className="text-muted">
                        {t('SPORTS')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('NAME')}*</Form.Label>
                        <Form.Control required name="name" type="text" placeholder={t('NAME')} maxLength="50" value={values.name || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('NAME')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                        <Form.Control required name="description" type="text" placeholder={t('DESCRIPTION')} maxLength="255" value={values.description || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('DESCRIPTION')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Check name="covered" type="checkbox" label={t('COVERED_FIELD')} checked={values.covered} onChange={handleChange}/>
                      </Form.Group>
                      {
                      affiliations.filter(a => a.type === affiliationType.partner).length > 0 ?
                      <Form.Group>
                        <Form.Label>{t('CHOOSE_AFFILIATIONS')}</Form.Label>
                        {
                          affiliations.filter(a => a.type === affiliationType.partner).map((affiliation, index) => {
                            return (
                              <Form.Check
                                key={index}
                                type="checkbox"
                                label={affiliation.name}
                                checked={values.affiliationsIds && values.affiliationsIds.includes(affiliation.affiliationId)}
                                onChange={val => setFieldValue('affiliationsIds', val.target.checked ? [...(values.affiliationsIds || []), affiliation.affiliationId] : values.affiliationsIds.filter(a => a !== affiliation.affiliationId))}
                                name="affiliationsIds"
                                value={affiliation.affiliationId}
                              />
                            );
                          })
                        }
                      </Form.Group>
                      : null
                      }
                      <Form.Group>
                        <Form.Label>{t('TERRAIN_TYPE')}*</Form.Label>
                        <Form.Control required as="select" name="terrain" value={values.terrain || ''} onChange={handleChange}>
                          <option value="">{t('TERRAIN_TYPE')}</option>
                          {values.sport && SPORTS[values.sport] ? SPORTS[values.sport].terrain.map(s => <option key={s} value={s}>{s}</option>) : null}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {t('TERRAIN_TYPE')}
                        </Form.Text>
                      </Form.Group>
                      {
                      profile.isSpecialist ?
                      null :
                      <Form.Group>
                        <Form.Label>{t('FIELD_PLANNING')}*</Form.Label>
                        <Form.Check required name="bookable" type="radio" label={t('FIELD_PLANNING_BOOKING')} checked={values.bookable} onChange={() => setFieldValue('bookable', true)}/>
                        <Form.Check required name="bookable" type="radio" label={t('FIELD_PLANNING_CALENDAR')} checked={values.bookable === false} onChange={() => setFieldValue('bookable', false)}/>
                      </Form.Group>
                      }
                      <Form.Group>
                        <Form.Label>{t('ADDITIONAL_COST_INFO')}</Form.Label>
                        <Form.Control name="additionalCost" as="textarea" maxLength={255} rows="6" placeholder={t('ADDITIONAL_COST_INFO')} value={values.additionalCost || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('ADDITIONAL_COST')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('POLICY')}</Form.Label>
                        <Form.Control name="bookingPolicy" as="textarea" maxLength={255} placeholder={t('POLICY_PLACEHOLDER')} value={values.bookingPolicy || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('POLICY_DESC')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('FIELD_UNBOOKING_POLICY')}*</Form.Label>
                        <Form.Check required name="unbookableHours" type="radio" label={t('FIELD_UNBOOKING_OFFLINE')} checked={values.unbookableHours === null} onChange={() => setFieldValue('unbookableHours', null)}/>
                        <Form.Check required name="unbookableHours" type="radio" label={t('FIELD_UNBOOKING_ONLINE')} checked={values.unbookableHours != null} onChange={() => setFieldValue('unbookableHours', values.unbookableHours || 24)}/>
                        <Form.Control required name="unbookableHours" type="number" min={0} value={values.unbookableHours} disabled={values.unbookableHours === null} onChange={handleChange} placeholder="Inserisci qui il numero di ore"/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('TERM_OF_USE')}</Form.Label>
                        <Form.Control name="termOfUse" as="textarea" maxLength={255} placeholder={t('TERM_OF_USE')} value={values.termOfUse || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('TERM_OF_USE')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('FIXED_COSTS')}</Form.Label>
                        <Form.Text style={{marginBottom: 10}}>{values.id === null ? t('FIXED_COST_INFO_NEW_FIELD') : t('FIXED_COSTS_INFO')}</Form.Text>
                        {values.id !== null ?
                        <div>
                          <Button type="button" variant="danger" style={{marginBottom: 10}} onClick={openFixedCostsModal}>{t('FIXED_COST_ADD')}</Button>
                        </div>
                        : null}
                      </Form.Group>
                      {(values.costs || []).map(c => {
                        return(
                          <Form.Group key={c.id}>
                            <Form.Label>{c.name}</Form.Label>
                            <Form.Row>
                            <Form.Control name="cost_value" type="text" value={(c.hourPrice.toFixed(2) + " €" || '')} readOnly style={{width: '50%'}}/>
                            <a href="#" onClick={onDeleteCost(c.id, ownedField.id)}><img style={{width: 40, height: 40}} src={binIcon} alt={t('ICON_DELETE')}/></a>
                            </Form.Row>
                            <Form.Text className="text-muted">
                              {c.name}
                            </Form.Text>
                          </Form.Group>
                        )}
                      )}
                      <Button type="submit" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                      {
                        ownedField.id === null ?
                          null :
                          <Button type="button" variant="secondary" style={{marginBottom: 50, marginLeft: 10}} onClick={onRemove(ownedField.id)}>{t('DELETE')}</Button>
                      }
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
            <Dialog
              ref={el => {
                dialog = el;
              }}/>

            <Modal show={isFixedCostsModalVisible} onHide={closeFixedCostsModal}>
              <Modal.Header closeButton>
                <Modal.Title>{t('FIXED_COST_ADD')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Formik
                  initialValues={{fieldId: ownedField.id, name: null, hourPrice: null}}
                  onSubmit={createFixedCost}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label>{t('NAME')}</Form.Label>
                          <Form.Control required name="name" type="text" value={values.name || ''} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('HOUR_PRICE')}</Form.Label>
                          <Form.Control required name="hourPrice" type="number" step={0.01} min={0.01} value={values.hourPrice || ''} onChange={handleChange}/>
                        </Form.Group>
                        <Button type="submit" variant="secondary">
                          {t('FIXED_COST_ADD')}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={closeFixedCostsModal}>
                  {t('CLOSE_BUTTON')}
                </Button>
              </Modal.Footer>
            </Modal>
          </Fragment>
        );
      }}
    </Formik>
  );
};

PartnerField.propTypes = {
  ownedField: PropTypes.object,
  save: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  token: PropTypes.string,
};

PartnerField.defaultProps = {
  ownedField: null,
  token: null
};

export default PartnerField;

import {push} from 'connected-react-router';
import jwt from 'jsonwebtoken';
import * as api from '../api';
import {ROLES} from '../constants';
import {showMessage} from './messages';
import {getPartners, getBookings, getVideo, getVideoStreaming, getPlayerPermanentVideos, getPlayerPermanentPartnerVideos, getFavouritePartners, getSubscriptions, getEvents, playerGetStoragePrices, getFavouriteRtvChannels, getPlayerPlaylists, postUsageData, playerGetRtvCategories, getPlayerAffiliations} from './player';
import {getOwnedFields, getOwnedServices, getOwnedRtvChannels, partnerFieldsActiveTab, partnerGetStoragePrices, getPartnerPermanentVideos, getPartnerPlaylists, getPartnerVideos, partnerGetRtvCategories, partnerGetAffiliations} from './partner';
import Cookies from 'universal-cookie';
import i18n from '../locale/i18n';
import {getSponsors, getStoragePrices, searchPartners, getRtvCategories} from './admin';
// Auth
export const loginSuccessfull = (token, profile) => ({
  type: 'LOGIN_SUCCESSFULL',
  token,
  profile
});

export const checkToken = ({token, email}, noRedirect = false) => async dispatch => {
  const tokenData = jwt.decode(token);
  let profile = null;
  if (tokenData.role === ROLES.player) {
    profile = await api.getPlayerProfile({uid: tokenData.id, email, role: tokenData.role}, token);
  } else if (tokenData.role === ROLES.partner) {
    profile = await api.getPartnerProfile({uid: tokenData.id, email, role: tokenData.role}, token);
  }

  if ((tokenData.role === ROLES.player || tokenData.role === ROLES.partner) && profile.status === 200) {
    dispatch(loginSuccessfull(token, profile.json));
    if (tokenData.role === ROLES.player) {
      dispatch(getPartners());
      dispatch(getFavouritePartners());
      dispatch(getFavouriteRtvChannels());
      dispatch(getBookings('all'));
      dispatch(getVideo());
      dispatch(getVideoStreaming());
      dispatch(getPlayerPermanentVideos());
      dispatch(getPlayerPermanentPartnerVideos());
      dispatch(getPlayerPlaylists());
      dispatch(playerGetStoragePrices());
      dispatch(getSubscriptions());
      dispatch(getEvents());
      dispatch(playerGetRtvCategories());
      dispatch(getPlayerAffiliations());
      dispatch(postUsageData(0));
      if (!noRedirect) {
        dispatch(push('/'));
      }
    }

    if (tokenData.role === ROLES.partner) {
      dispatch(getOwnedFields());
      dispatch(getOwnedServices());
      dispatch(getOwnedRtvChannels());
      dispatch(partnerGetStoragePrices());
      dispatch(getPartnerPermanentVideos());
      dispatch(getPartnerVideos());
      dispatch(getPartnerPlaylists());
      dispatch(postUsageData(0));
      dispatch(partnerGetRtvCategories());
      dispatch(partnerGetAffiliations());
      if (profile.json.isSpecialist) dispatch(partnerFieldsActiveTab("servizi"));
      dispatch(push('/partner-fields'));
    }
  } else if (tokenData.role === ROLES.admin) {
    dispatch(loginSuccessfull(token, {user: {role: ROLES.admin}}));
    dispatch(getSponsors());
    dispatch(getStoragePrices());
    dispatch(postUsageData(0));
    dispatch(searchPartners('all'));
    dispatch(getRtvCategories());
    dispatch(push('/'));
  } else {
    dispatch(showMessage('SESSION', 'INVALID_SESSION'));
    dispatch(push('/login'));
  }
};

export const login = credential => async (dispatch, getState) => {
  const res = await api.login(credential);
  if (res.status === 200) {
    const cookies = new Cookies();
    cookies.set('replayerAuth', {token: res.json.token, email: credential.email});
    const tokenData = jwt.decode(res.json.token);
    let profile = null;
    if (tokenData.role === ROLES.player) {
      profile = await api.getPlayerProfile({uid: tokenData.id, email: credential.email, role: tokenData.role}, res.json.token);
      dispatch(updateLanguage(i18n.language, res.json.token));
    } else if (tokenData.role === ROLES.partner) {
      profile = await api.getPartnerProfile({uid: tokenData.id, email: credential.email, role: tokenData.role}, res.json.token);
      dispatch(updateLanguage(i18n.language, res.json.token));
    }

    if (tokenData.role === ROLES.player) {
      dispatch(loginSuccessfull(res.json.token, profile.json));
      dispatch(getPartners());
      dispatch(getFavouritePartners());
      dispatch(getFavouriteRtvChannels());
      dispatch(getBookings('all'));
      dispatch(getVideo());
      dispatch(getVideoStreaming());
      dispatch(playerGetStoragePrices());
      dispatch(getPlayerPermanentVideos());
      dispatch(getPlayerPermanentPartnerVideos());
      dispatch(getPlayerPlaylists());
      dispatch(getSubscriptions());
      dispatch(getEvents());
      dispatch(getPlayerAffiliations());
      dispatch(postUsageData(0));
      dispatch(playerGetRtvCategories());
      dispatch(push('/'));
    }

    if (tokenData.role === ROLES.partner) {
      dispatch(loginSuccessfull(res.json.token, profile.json));
      dispatch(getOwnedFields());
      dispatch(getOwnedServices());
      dispatch(getOwnedRtvChannels());
      dispatch(partnerGetStoragePrices());
      dispatch(getPartnerPermanentVideos());
      dispatch(getPartnerVideos());
      dispatch(getPartnerPlaylists());
      dispatch(postUsageData(0));
      dispatch(partnerGetRtvCategories());
      dispatch(partnerGetAffiliations());
      if (profile.json.isSpecialist) dispatch(partnerFieldsActiveTab("servizi"));
      dispatch(push('/partner-fields'));
    }

    if (tokenData.role === ROLES.admin) {
      dispatch(loginSuccessfull(res.json.token, {user: {role: ROLES.admin}}));
      dispatch(getStoragePrices());
      dispatch(postUsageData(0));
      dispatch(searchPartners('all'));
      dispatch(getRtvCategories());
      dispatch(push('/'));
    }
  } else if (res.status === 403 && res.json.error === 'invalid_credentials') {
    dispatch(showMessage('LOGIN', 'LOGIN_FAILED'));
  } else if (res.status === 403 && res.json.error === 'user_not_actived') {
    dispatch(showMessage('LOGIN', 'LOGIN_USER_NOT_ACTIVE'));
  } else {
    dispatch(showMessage('LOGIN', `Error ${res.status}: ${res.json.error}`));
  }
};

export const signUpPlayer = signUpData => async dispatch => {
  signUpData.language = i18n.language;
  const res = await api.signUpPlayer(signUpData);
  if (res.status === 200 || res.status === 201) {
    dispatch(push('/'));
    dispatch(showMessage('SIGNING_UP', 'SIGNING_UP_SUCCESSFUL'));
  } else if (res.status === 400 && res.json.error === 'email_already_registered') {
    dispatch(showMessage('SIGNING_UP', 'EMAIL_ALREADY_REGISTERED'));
  } else if (res.status === 400 && res.json.error === 'password_not_matched') {
    dispatch(showMessage('SIGNING_UP', 'PASSWORD_NO_MATCHED'));
  } else if (res.status === 400 && res.json.error === 'invalid_email') {
    dispatch(showMessage('SIGNING_UP', 'EMAIL_BAD_FORMAT'));
  } else {
    dispatch(showMessage('SIGNING_UP', `Error ${res.status}: ${res.json.error}`));
  }
};

export const signUpPartner = signUpData => async dispatch => {
  const res = await api.signUpPartner(signUpData);
  if (res.status === 200 || res.status === 201) {
    dispatch(push('/'));
    dispatch(showMessage('SIGNING_UP_REQUEST', 'SIGNING_UP_REQUEST_SUCCESSFUL'));
  } else {
    dispatch(showMessage('SIGNING_UP_REQUEST', `Error ${res.status}: ${res.json.error}`));
  }
};

export const logout = () => async dispatch => {
  const cookies = new Cookies();
  cookies.remove('replayerAuth');
  dispatch({
    type: 'LOGOUT'
  });
};

export const showRecoveryPasswordModal = () => ({
  type: 'LOGIN_SHOW_RECOVERY_PASSWORD_MODAL'
});

export const hideRecoveryPasswordModal = () => ({
  type: 'LOGIN_HIDE_RECOVERY_PASSWORD_MODAL'
});

export const recoveryPassword = email => async dispatch => {
  const res = await api.sendResetPwd(email);
  if (res.status === 200 || res.status === 201) {
    dispatch(push('/'));
    dispatch(showMessage('LOGIN_PASSWORD_RECOVERY', 'PASSWORD_RECOVERY_REQUEST_SUCCESSFUL'));
  } else {
    dispatch(showMessage('LOGIN_PASSWORD_RECOVERY', `Error ${res.status}: ${res.json.error}`));
  }
};

export const verifyEmail = token => async dispatch => {
  const res = await api.verifyEmail(token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('WELCOME', 'LOGIN_REQUIRED'));
  } else {
    dispatch(showMessage('VERIFY_EMAIL', 'VERIFY_EMAIL_FAILED'));
  }

  dispatch(push('/login'));
};

export const resetPassword = (data, token) => async dispatch => {
  const res = await api.resetPassword(data, token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('LOGIN_PASSWORD_RECOVERY', 'CHANGE_PASSWORD_SUCCESSFUL'));
  } else if (res.status === 400 && res.json.error === 'password_not_matched') {
    dispatch(showMessage('LOGIN_PASSWORD_RECOVERY', 'PASSWORD_NO_MATCHED'));
  } else if (res.status === 403 && res.json.error === 'invalid_token') {
    dispatch(showMessage('LOGIN_PASSWORD_RECOVERY', 'CHANGE_PASSWORD_REQUEST_EXPIRED'));
  } else {
    dispatch(showMessage('LOGIN_PASSWORD_RECOVERY', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(push('/login'));
};

export const confirmChangeEmail = token => async dispatch => {
  const res = await api.confirmChangeEmail(token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('CONFIRM_EMAIL', 'CONFIRM_EMAIL_SUCCESSFUL'));
  } else {
    dispatch(showMessage('CONFIRM_EMAIL', 'CONFIRM_EMAIL_FAILED'));
  }

  dispatch(push('/login'));
};

export const updateLanguage = (language, token) => async (dispatch, getState) => {
  const res = await api.updateUserLanguage(language, token);

  if (res.status !== 200) {
    dispatch(showMessage('CHANGE_LANGUAGE', `Error ${res.status}: ${res.json.error}`));
  }
};
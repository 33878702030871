/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import r from '../images/title_r.svg';
import {topUpWallet} from '../actions/admin';
import {useTranslation} from 'react-i18next';

const AdminHome = ({
  token,
  topUpWallet
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Formik
      initialValues={{email: null, credit: null, cause: null}}
      onSubmit={async (values, {resetForm}) => {
        await topUpWallet(values);
        resetForm();
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <img
                      src={r}
                      height="30"
                      className="mx-auto d-block"
                      alt={t('LOGO')}
                    />
                    <Row className="justify-content-center" style={{marginTop: 20}}>
                      <h1>{t('TITLE_REPLAYER')}</h1>
                    </Row>
                    <Row className="justify-content-center">
                      <h4>{t('MOTTO')}</h4>
                    </Row>
                    <h4 style={{marginTop: 30}}>{t('ACCOUNT_TOPUP')}</h4>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('EMAIL')}</Form.Label>
                        <Form.Control required name="email" placeholder={t('EMAIL')} type="text" maxLength="255" value={values.email || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('AMOUNT')}</Form.Label>
                        <Form.Control required name="credit" placeholder={t('AMOUNT')} type="number" max={2000} step={1} value={values.credit || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('CAUSE')}</Form.Label>
                        <Form.Control name="cause" placeholder={t('CAUSE')} type="text" maxLength="255" value={values.cause || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="submit">{t('ACCOUNT_TOPUP')}</Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

AdminHome.propTypes = {
  token: PropTypes.string,
  topUpWallet: PropTypes.func.isRequired
};

AdminHome.defaultProps = {
  token: null
};

const mapStateToProps = ({login: {token}}) => ({
  token
});

const mapDispatchToProps = dispatch => ({
  topUpWallet: topUpdata => {
    dispatch(topUpWallet(topUpdata));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminHome));

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-bootstrap-dialog';
import {Button, ToggleButtonGroup, ToggleButton, Form, Row, Col, Popover} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';
import {showMessage} from '../actions/messages';
import {useTranslation} from 'react-i18next';

registerLocale('it', it);

const ServiceSlot = ({slot, save, remove, profile, ownedServices, showMessageRemovingVideo}) => {
  const {t, i18n} = useTranslation();
  const service = serviceId => ownedServices.find(s => s.id === serviceId);

  return (
    <Formik
      enableReinitialize
      initialValues={Object.assign({}, slot, {untilDate: slot.endDatetime, overwriting: false, weekDays: [], remove: false, event: slot.event || false, presetPlaces: slot.presetPlaces || 1, color: slot.color || '#ADB5BD', selectedCameras: []})}
      onSubmit={values => values.remove ? remove(values) : save(values)}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {
        const setVideoSellPermissionByState = state => {
          /*if (state.target.value === 'available') {
            setFieldValue('videoSellPermission', true);
          }*/

          handleChange(state);
        };

        let dialog = null;
        const onRemoveVideo = (message, serviceName, newValue) => {
          dialog.show({
            title: t('CONFIRM'),
            body: message,
            actions: [
              Dialog.Action(
                t('OK'),
                () => setFieldValue(serviceName, newValue),
                'btn btn-secondary'
              ),
              Dialog.Action(
                t('CANCEL'),
                null,
                'btn btn_red_outline_rp'
              )
            ],
            bsSize: 'small',
            onHide: dialog => {
              dialog.hide();
            }
          });
        };

        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t('STATE')}*</Form.Label>
              <Form.Control required as="select" name="state" value={values.state || ''} onChange={setVideoSellPermissionByState}>
                <option value="">{t('GIVE_STATE')}</option>
                <option value="available">{t('AVAILABLE')}</option>
                <option value="reserved">{t('RESERVED')}</option>
                <option value="unavailable">{t('UNAVAILABLE')}</option>
              </Form.Control>
            </Form.Group>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>{t('START_TIME')}*</Form.Label><br/>
                  <DatePicker
                    required
                    showTimeSelect
                    showTimeSelectOnly
                    locale="it"
                    className="form-control"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    timeIntervals={15}
                    selected={(values.startDatetime && new Date(values.startDatetime)) || null}
                    onChange={val => {
                      if (slot.startDatetime && val) {
                        const newDate = new Date(slot.startDatetime);
                        newDate.setHours(val.getHours());
                        newDate.setMinutes(val.getMinutes());
                        setFieldValue('startDatetime', newDate);
                      } else {
                        setFieldValue('startDatetime', val);
                      }
                    }}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{t('FINISH_TIME')}*</Form.Label><br/>
                  <DatePicker
                    required
                    showTimeSelect
                    showTimeSelectOnly
                    locale="it"
                    className="form-control"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    timeIntervals={15}
                    selected={(values.endDatetime && new Date(values.endDatetime)) || null}
                    onChange={val => {
                      if (slot.endDatetime && val) {
                        const newDate = new Date(slot.endDatetime);
                        newDate.setHours(val.getHours());
                        newDate.setMinutes(val.getMinutes());
                        setFieldValue('endDatetime', newDate);
                      } else {
                        setFieldValue('endDatetime', val);
                      }

                      if (val) {
                        const newUntilDate = new Date(values.untilDate);
                        newUntilDate.setHours(val.getHours());
                        newUntilDate.setMinutes(val.getMinutes());
                        setFieldValue('untilDate', newUntilDate);
                      }
                    }}/>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="align-items-center">
              <Col>
                <Form.Group>
                  <Form.Label>{t('REPEAT')}</Form.Label><br/>
                  <DatePicker
                    required
                    locale="it"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    selected={(values.untilDate && new Date(values.untilDate)) || null}
                    onChange={val => setFieldValue('untilDate', val)}/>
                </Form.Group>
              </Col>
              <Col className="d-flex flex-column">
                <Form.Group>
                  <Form.Check style={{marginTop: 10, marginLeft: 20}} name="overwriting-remove" type="radio" label={t('KEEP')} checked={!values.overwriting} onChange={() => {setFieldValue('overwriting', !(values.overwriting)); setFieldValue('remove', false);}}/>
                  <Form.Check style={{marginLeft: 20}} name="overwriting-remove" type="radio" label={t('OVERWRITE')} checked={values.overwriting} onChange={() => {setFieldValue('overwriting', !(values.overwriting)); setFieldValue('remove', false);}}/>
                  {
                    values.slotId ?
                      <Form.Check style={{marginLeft: 20}} name="overwriting-remove" type="radio" label={t('DELETE')} checked={values.remove} onChange={() => setFieldValue('remove', !(values.remove))}/> :
                      null
                  }
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <ToggleButtonGroup name="weekDays" type="checkbox" value={values.weekDays} onChange={weekDays => setFieldValue('weekDays', weekDays)}>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={0}
                    size="sm"
                  >
                    {t('MONDAY')}
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={1}
                    size="sm"
                  >
                    {t('TUESDAY')}
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={2}
                    size="sm"
                  >
                    {t('WEDNESDAY')}
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={3}
                    size="sm"
                  >
                    {t('THURSDAY')}
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={4}
                    size="sm"
                  >
                    {t('FRIDAY')}
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={5}
                    size="sm"
                  >
                    {t('SATURDAY')}
                  </ToggleButton>
                  <ToggleButton
                    type="checkbox"
                    variant="success"
                    value={6}
                    size="sm"
                  >
                    {t('SUNDAY')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Form.Row>
            {
              values.state === 'reserved' ?
                <Fragment>
                  {/*
                  <Form.Group>
                    <Form.Check
                      name="videoSellPermission"
                      type="checkbox"
                      label={t('SALABLE_VIDEO')}
                      checked={values.videoSellPermission}
                      onChange={() => {
                        const newValue = !values.videoSellPermission;
                        if (!newValue) {
                          onRemoveVideo(t('VIDEO_PARTNER_REMOVING_SALABLE'), 'videoSellPermission', newValue);
                        } else {
                          setFieldValue('videoSellPermission', newValue);
                        }
                        if (newValue && values.rtvOrAcademy === rtvType.academy) {
                          setFieldValue('rtvOrAcademy', null);
                        }
                      }}
                    />
                  </Form.Group>
                  {
                    profile.rtvPrice > 0 && service(values.serviceId).rtv ?
                      <Fragment>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            label="Rtv Video"
                            checked={values.rtvOrAcademy === rtvType.rtv}
                            onChange={() => {
                              if (values.rtvOrAcademy === rtvType.rtv) {
                                onRemoveVideo(t('VIDEO_PARTNER_REMOVING_RTV'), 'rtvOrAcademy', null);
                              } else {
                                setFieldValue('rtvOrAcademy', values.rtvOrAcademy === rtvType.rtv ? null : rtvType.rtv);
                              }
                            }}/>
                        </Form.Group>
                        {
                          hasGroupCoach ?
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                label="Academy"
                                checked={values.rtvOrAcademy === rtvType.academy}
                                onChange={() => {
                                  if (values.rtvOrAcademy === rtvType.academy) {
                                    onRemoveVideo(t('VIDEO_PARTNER_REMOVING_RTV'), 'rtvOrAcademy', null);
                                  } else {
                                    const newValue = values.rtvOrAcademy === rtvType.academy ? null : rtvType.academy;
                                    setFieldValue('rtvOrAcademy', newValue);
                                    if (newValue === rtvType.academy) {
                                      setFieldValue('videoSellPermission', false);
                                    }
                                  }
                                }}/>
                            </Form.Group> :
                            null
                        }
                      </Fragment> :
                      null
                  }*/}
                  <Form.Group>
                    <Form.Check
                      name="event"
                      type="checkbox"
                      label={t('SPECIAL_EVENT')}
                      checked={values.event}
                      onChange={() => setFieldValue('event', !(values.event && values.event))}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('TAG_COLOR')}*</Form.Label><br />                    
                        <OverlayTrigger
                          trigger="click"
                          key="bottom"                          
                          placement="bottom"
                          overlay={
                            <Popover>
                              <Popover.Title as="h3">{t('TAG_COLOR')}</Popover.Title>
                              <Popover.Content>
                                <Row style={{marginBottom: 10}}>
                                  <Col><Button style={{backgroundColor: '#E53935', border: 0, height: 25}} onClick={() => setFieldValue('color', '#E53935')}/></Col>
                                  <Col><Button style={{backgroundColor: '#D81B60', border: 0, height: 25}} onClick={() => setFieldValue('color', '#D81B60')}/></Col>
                                  <Col><Button style={{backgroundColor: '#8E24AA', border: 0, height: 25}} onClick={() => setFieldValue('color', '#8E24AA')}/></Col>
                                  <Col><Button style={{backgroundColor: '#5E35B1', border: 0, height: 25}} onClick={() => setFieldValue('color', '#5E35B1')}/></Col>
                                  <Col><Button style={{backgroundColor: '#9D86C3', border: 0, height: 25}} onClick={() => setFieldValue('color', '#9D86C3')}/></Col>                                  
                                </Row>
                                <Row>                                  
                                  <Col><Button style={{backgroundColor: '#FFB300', border: 0, height: 25}} onClick={() => setFieldValue('color', '#FFB300')}/></Col>                                  
                                  <Col><Button style={{backgroundColor: '#FB8C00', border: 0, height: 25}} onClick={() => setFieldValue('color', '#FB8C00')}/></Col>
                                  <Col><Button style={{backgroundColor: '#F4511E', border: 0, height: 25}} onClick={() => setFieldValue('color', '#F4511E')}/></Col>
                                  <Col><Button style={{backgroundColor: '#AB6158', border: 0, height: 25}} onClick={() => setFieldValue('color', '#AB6158')}/></Col>
                                  <Col><Button style={{backgroundColor: '#ADB5BD', border: 0, height: 25}} onClick={() => setFieldValue('color', '#ADB5BD')}/></Col>
                                </Row>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button block size='lg' style={{backgroundColor: values.color, border: 0, height: 25}}/>
                        </OverlayTrigger>
                  </Form.Group>
                </Fragment> :
                null
            }
            {
              values.state === 'available' ?
                <Fragment>
                  <Form.Group>
                    <Form.Label>{t('PRICE')}</Form.Label>
                    <Form.Control required={service(values.serviceId).payment} name="price" type="number" min="0.00" step="any" value={values.price || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('PLACES')}</Form.Label>
                    <Form.Control name="presetPlaces" type="number" min="1" max="30" step="1" value={values.presetPlaces || 1} onChange={handleChange}/>
                  </Form.Group>
                </Fragment> :
                null
            }
            {
              values.state === 'reserved' || values.state === 'unavailable' ?
                <Form.Group>
                  <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                  <Form.Control required name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')}value={values.description || ''} onChange={handleChange}/>
                </Form.Group>
              : null
            }
            {
              values.presetPlaces > 1 ?
                <Form.Group>
                  <Form.Label>{t('DESCRIPTION')}</Form.Label>
                  <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')}value={values.description || ''} onChange={handleChange}/>
                </Form.Group>
              : null
            }
            <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>{values.remove ? t('DELETE') : t('SAVE')}</Button>
            <Dialog
              ref={el => {
                dialog = el;
              }}/>
          </Form>
        );
      }}
    </Formik>
  );
};

ServiceSlot.propTypes = {
  slot: PropTypes.object,
  ownedServices: PropTypes.array,
  save: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  showMessageRemovingVideo: PropTypes.func.isRequired
};

ServiceSlot.defaultProps = {
  slot: null,
  ownedServices: []
};

const mapStateToProps = ({login: {profile}, partner: {ownedServices}}) => ({
  profile,
  ownedServices
});

const mapDispatchToProps = dispatch => ({
  showMessageRemovingVideo: (message) => {
    dispatch(showMessage('VIDEOS', message));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceSlot));

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import ShowPartner from '../components/show-partner';
import {filterBookingFieldId, getSlots, addPartnerToFavourite, removePartnerFromFavourite, showPlayerNewSubscriptionModal, showPlayerNewEventSubscriptionModal, getRtvVideos, getVideosStore, getVideosStorePartnerVideo, rtvChannelSelected, partnerSelected, showPlayerChooseRtvChannelModal, requestAffiliation, renewAffiliation} from '../actions/player';
import {showMessage} from '../actions/messages';
import {subscriptionStatuses} from '../constants';

const mapStateToProps = ({login: {profile, token}, player: {selectedPartner, selectedPartnerFields, selectedPartnerServices, favouritePartners, subscriptions, selectedPartnerEvents, affiliations}}) => ({
  profile,
  token,
  partner: selectedPartner,
  fields: selectedPartnerFields,
  services: selectedPartnerServices,
  isFavourite: favouritePartners.some(p => p.uid === selectedPartner.uid),
  subscriptions,
  events: selectedPartnerEvents,
  affiliations
});

const mapDispatchToProps = dispatch => ({
  showCalendar: (partnerId, fieldId = null) => () => {
    dispatch(filterBookingFieldId(fieldId));
    dispatch(getSlots(partnerId, fieldId, new Date()));
    dispatch(push('player-booking-calendar'));
  },
  showServiceCalendar: (partnerId, serviceId = null) => () => {
    dispatch(filterBookingFieldId(serviceId));
    dispatch(getSlots(partnerId, serviceId, new Date(), 'service'));
    dispatch(push('player-service-booking-calendar'));
  },
  showDetail: field => () => {
    dispatch(showMessage('INFO_COSTS', field.additionalCost || 'INFO_COSTS_EMPTY'));
  },
  addToCoachNotAllowed: () => {
    dispatch(showMessage('MEMBERSHIP_REQUEST', 'MEMBERSHIP_COACH_NOT_ALLOWED'));
  },
  toggleFavourite: (uid, isFavourite) => () => {
    if (isFavourite) {
      dispatch(removePartnerFromFavourite(uid));
    } else {
      dispatch(addPartnerToFavourite(uid));
    }
  },
  showReplayerTV: (rtvChannelId, partner, subscriptionStatus) => () => {
    const rtvEventChannel = partner.rtvChannels.find(c => c.id === rtvChannelId);
    if (!rtvEventChannel) {
      dispatch(showMessage('RTV_CHANNELS', 'NO_RTV_EVENT_CHANNEL'));
    }

    dispatch(rtvChannelSelected(rtvEventChannel));    

    if (subscriptionStatus.status === subscriptionStatuses.valid || subscriptionStatus.status === subscriptionStatuses.unlimited) {
      dispatch(getRtvVideos(partner.uid));
      dispatch(push('/player-replayer-tv'));
      return;
    }

    if (subscriptionStatus.status === subscriptionStatuses.canRequestEvent) {
      dispatch(showPlayerNewEventSubscriptionModal());
      return;
    }

    dispatch(showPlayerNewSubscriptionModal());
    return;
  },
  showVideoStore: (partnerUid, fieldId) => () => {
    dispatch(getVideosStore(partnerUid, fieldId));
    dispatch(getVideosStorePartnerVideo(partnerUid));
    dispatch(push(`/player-video-store-partner?fieldId=${fieldId}`));
  },
  showServiceVideoStore: (partnerUid, serviceId) => () => {
    dispatch(getVideosStore(partnerUid, null, serviceId));
    dispatch(getVideosStorePartnerVideo(partnerUid));
    dispatch(push(`/player-video-store-partner?serviceId=${serviceId}`));
  },
  rtvMembershipNotAllowed: () => {
    dispatch(showMessage('MEMBERSHIP_REQUEST', 'RTV_MEMBERSHIP_ALREADY_UNLIMITED'));
  },
  chooseReplayerTV: (partner) => () => {
    dispatch(rtvChannelSelected(null));
    dispatch(partnerSelected(partner));
    dispatch(showPlayerChooseRtvChannelModal());
  },
  requestAffiliation: (affiliationId, playerId, playerOnlinePayment, affiliationName, partnerName) => () => {
    dispatch(requestAffiliation(affiliationId, playerId, playerOnlinePayment, affiliationName, partnerName));
  },
  renewAffiliation: (playerId, affiliationId, onlinePayment, affiliationName, partnerName) => () => {
    dispatch(renewAffiliation(playerId, affiliationId, onlinePayment, affiliationName, partnerName));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowPartner));

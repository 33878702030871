import React, {Fragment, useState} from 'react';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Tab, Tabs} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Modal, Row, Col, Table} from 'react-bootstrap';
import {showAffiliationModal, hideAffiliationModal, editAffiliation, deleteAffiliation, createAffiliation, selectAffiliation, activateDeactivateAffiliation, setPartnerAffiliationsActiveTab, deletePlayerFromAffiliation, editAffiliationPlayer, renewPlayerAffiliation, acceptOrRefuseAffiliationRenewRequest} from '../actions/partner';
import {Formik} from 'formik';
import { Form } from 'react-bootstrap';
import { renewalType, affiliationType, durationType } from '../constants';
import { FaUser, FaPencilAlt, FaTrashAlt, FaCalendarAlt } from "react-icons/fa";
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdAutorenew } from "react-icons/md";
import Dialog from 'react-bootstrap-dialog';
import ReactPaginate from 'react-paginate';
import checkIcon from '../images/membership_check_24.svg';
import DatePicker from 'react-datepicker';

const PartnerAffiliations = ({
    profile,
    isAffiliationModalVisible,
    showAffiliationModal,
    hideAffiliationModal,
    newOrEditAffiliation,
    affiliations,
    activateDeactivateAffiliation,
    deleteAffiliation,
    selectedAffiliation,
    showEditAffiliationModal,
    affiliatedPlayers,
    partnerAffiliationsActiveTab,
    setActiveTab,
    deletePlayerFromAffiliation,
    editPlayer,
    renewPlayerAffiliation,
    affiliationRenewRequests,
    approveRenewRequest,
    refuseRenewRequest
}) => {
    const {t, i18n} = useTranslation();

    const [requestsOffset, setRequestsOffset] = useState(0);
    const [requestsFilter, setRequestsFilter] = useState("");
    const [renewRequestsOffset, setRenewRequestsOffset] = useState(0);
    const [renewRequestsFilter, setRenewRequestsFilter] = useState("");
    const [affiliatedPlayersOffset, setAffiliatedPlayersOffset] = useState(0);
    const [affiliatedNameFilter, setAffiliatedNameFilter] = useState("");
    const [affiliatedAffiliationFilter, setAffiliatedAffiliationFilter] = useState("");
    const [affiliatedStateFilter, setAffiliatedStateFilter] = useState("");

    const affiliatedAffiationOptions = affiliations.filter(a => a.players.length > 0).map(a => ({value: a.affiliationId, label: a.name}));

    const playerRequests = affiliatedPlayers.filter(affiliation => affiliation.active === null && (requestsFilter === "" || affiliation.player.name.toLowerCase().indexOf(requestsFilter.toLowerCase()) > -1 || affiliation.player.surname.toLowerCase().indexOf(requestsFilter.toLowerCase()) > -1));
    
    const renewRequests = affiliationRenewRequests.filter(affiliation => renewRequestsFilter === "" || affiliation.player.name.toLowerCase().indexOf(renewRequestsFilter.toLowerCase()) > -1 || affiliation.player.surname.toLowerCase().indexOf(renewRequestsFilter.toLowerCase()) > -1);

    const affiliated = affiliatedPlayers.filter(affiliation => affiliation.active !== null && 
        (affiliatedNameFilter === "" || affiliation.player.name.toLowerCase().indexOf(affiliatedNameFilter.toLowerCase()) > -1 || affiliation.player.surname.toLowerCase().indexOf(affiliatedNameFilter.toLowerCase()) > -1) &&
        (affiliatedAffiliationFilter === "" || affiliation.affiliationId == affiliatedAffiliationFilter) &&
        (affiliatedStateFilter === "" || (affiliatedStateFilter === 'active' && affiliation.active) || (affiliatedStateFilter === 'nonactive' && !affiliation.active) || (affiliatedStateFilter === 'payed' && affiliation.payed) || (affiliatedStateFilter === 'toBePayed' && !affiliation.payed) || (affiliatedStateFilter === 'expired' && affiliation.expiryTimestamp !== null && new Date(affiliation.expiryTimestamp) < new Date()) || (affiliatedStateFilter === 'expiredCertificate' && affiliation.medicalCertificateTimestamp !== null && new Date(affiliation.medicalCertificateTimestamp) < new Date()) || (affiliatedStateFilter === 'noCertificate' && affiliation.medicalCertificateTimestamp === null))
    );

    const affiliationPaymentCheck = affiliationId => {
        const affiliation = affiliations.find(a => a.affiliationId === affiliationId);
        return affiliation.onlinePayment === null;
    }

    let dialog = null;

    const activateDeactivateAffiliationDialog = (value, affiliation) => {
        dialog.show({
          title: value ? t('ACTIVATE_AFFILIATION') : t('DEACTIVATE_AFFILIATION'),
          body: value ? 
          <Fragment>
            <Form.Label>
                {t('ACTIVATE_AFFILIATION_DESC')}
            </Form.Label>
            <Form.Label>
                {t('ACTIVATE_AFFILIATION_DESC_TWO')}
            </Form.Label>
          </Fragment>
          : t('DEACTIVATE_AFFILIATION_DESC'),
          actions: [
            Dialog.Action(
              value ? t('YES_ACTIVATE') : t('YES_DEACTIVATE'),
              () => activateDeactivateAffiliation(value, affiliation),
              'btn btn-secondary'
            ),
            Dialog.Action(
              t('CANCEL'),
              null,
              'btn btn_red_outline_rp'
            )
          ],
          bsSize: 'small',
          onHide: dialog => {
            dialog.hide();
          }
        });
      };

      const deleteAffiliationDialog = (affiliation) => {
        dialog.show({
          title: t('DELETE_AFFILIATION'),
          body: t('DELETE_AFFILIATION_DESC', {name: affiliation.name}),
          actions: [
            Dialog.Action(
              t('DELETE'),
              () => deleteAffiliation(affiliation.affiliationId),
              'btn btn-secondary'
            ),
            Dialog.Action(
              t('CANCEL'),
              null,
              'btn btn_red_outline_rp'
            )
          ],
          bsSize: 'small',
          onHide: dialog => {
            dialog.hide();
          }
        });
      };

      const confirmDeletePlayerFromAffiliation = (playerId,affiliationId) => {
        dialog.show({
          title: t('CONFIRM'),
          body: t('CONFIRM_DELETE_PLAYER_FROM_GROUP'),
          actions: [
            Dialog.Action(
              t('DELETE'),
              () => deletePlayerFromAffiliation(playerId,affiliationId),
              'btn btn-secondary'
            ),
            Dialog.Action(
              t('CANCEL'),
              null,
              'btn btn_red_outline_rp'
            )
          ],
          bsSize: 'small',
          onHide: dialog => {
            dialog.hide();
          }
        });
      };

      const confirmChangeStateDialog = (e, a) => {
        dialog.show({
            title: t('CONFIRM'),
            body: t('CONFIRM_ACTIVATE_SUBSCRIPTION'),
            actions: [
              Dialog.Action(
                t('YES_ACTIVATE'),
                () => editPlayer(a.affiliationId, a.playerId, e.target.checked, a.medicalCertificateTimestamp, a.payed),
                'btn btn-secondary'
              ),
              Dialog.Action(
                t('CANCEL'),
                null,
                'btn btn_red_outline_rp'
              )
            ],
            bsSize: 'small',
            onHide: dialog => {
              dialog.hide();
            }
          });
      }


    const getMinimumDuration = durationSelect => {
        if (durationSelect == durationType.days) return 7;
        return 1;
    }

    const getMaximumDuration = durationSelect => {
        if (durationSelect == durationType.days) return 365;
        if (durationSelect == durationType.months) return 12;
        return 1;
    }

    const requests = currentItems => {
        return (
            currentItems.length > 0 ?
            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                <Col xs={12} sm={12} md={8}>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>{t('MEMBERSHIP')}</th>
                                <th>{t('USER')}</th>
                                <th>{t('CONTACT_DETAILS')}</th>
                                <th>{t('REQUEST')}</th>
                                <th className="text-center">{t('APPROVE')}</th>
                                <th className="text-center">{t('DELETE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(a => (
                                <tr key={`${a.affiliationId}-${a.playerId}`}>
                                    <td className="col-md-2">{a.affiliation.name}</td>
                                    <td className="col-md-2">{a.player.name + ' ' + a.player.surname}</td>
                                    <td className="col-md-2">{a.player.user.email + ' ' + a.player.phoneNumber}</td>
                                    <td className="col-md-2">{new Date(a.requestTimestamp).toLocaleDateString()}</td>
                                    <td className="col-md-2 text-center">
                                        <Button variant="outline-primary" onClick={() => editPlayer(a.affiliationId, a.playerId, true, null, false)}>
                                            <img src={checkIcon} alt={t('CHECK_ICON')} style={{width: '1.5em', height: '1.5em'}}/>
                                        </Button>
                                    </td>
                                    <td className="col-md-2 text-center">
                                        <Button variant="outline-secondary" onClick={() => confirmDeletePlayerFromAffiliation(a.playerId, a.affiliationId)}>
                                            <RiDeleteBinLine size="1.5em"/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            : <h6 style={{textAlign: 'center', paddingBottom: 400}}>{requestsFilter === "" ? t('NO_REQUESTS') : t('SEARCH_REQUEST_FAILED')}</h6>
        );
    };

    const renewRequestsTable = currentItems => {
        return (
            currentItems.length > 0 ?
            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                <Col xs={12} sm={12} md={8}>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>{t('MEMBERSHIP')}</th>
                                <th>{t('USER')}</th>
                                <th>{t('CONTACT_DETAILS')}</th>
                                <th>{t('REQUEST')}</th>
                                <th className="text-center">{t('APPROVE')}</th>
                                <th className="text-center">{t('DELETE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(a => (
                                <tr key={a.requestId}>
                                    <td className="col-md-2">{a.affiliation.name}</td>
                                    <td className="col-md-2">{a.player.name + ' ' + a.player.surname}</td>
                                    <td className="col-md-2">{a.player.user.email + ' ' + a.player.phoneNumber}</td>
                                    <td className="col-md-2">{new Date(a.requestTimestamp).toLocaleDateString()}</td>
                                    <td className="col-md-2 text-center">
                                        <Button variant="outline-primary" onClick={() => approveRenewRequest(a)}>
                                            <img src={checkIcon} alt={t('CHECK_ICON')} style={{width: '1.5em', height: '1.5em'}}/>
                                        </Button>
                                    </td>
                                    <td className="col-md-2 text-center">
                                        <Button variant="outline-secondary" onClick={() => refuseRenewRequest(a)}>
                                            <RiDeleteBinLine size="1.5em"/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            : <h6 style={{textAlign: 'center', paddingBottom: 400}}>{requestsFilter === "" ? t('NO_REQUESTS') : t('SEARCH_REQUEST_FAILED')}</h6>
        );
    };

    const showAffiliated = currentItems => {
        return (
            currentItems.length > 0 ?
            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                <Col xs={12} sm={12} md={12}>
                    <Table hover responsive style={{ minHeight: 400 }}>
                        <thead>
                            <tr>
                                <th>{t('STATE')}</th>
                                <th>{t('MEMBERSHIP')}</th>
                                <th>{t('USER')}</th>
                                <th>{t('CONTACT_DETAILS')}</th>
                                <th>{t('EXPIRY')}</th>
                                <th>{t('CERTIFICATE')}</th>
                                <th className="text-center">{t('PAID')}</th>
                                <th className="text-center">{t('RENEW')}</th>
                                <th className="text-center">{t('DELETE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(a => (
                                <tr key={`${a.affiliationId}-${a.playerId}`}>
                                    <td className="col-md-1">
                                        <Form.Check
                                            id={`switch-state-${a.affiliationId}-${a.playerId}`}
                                            type="switch"
                                            className={'custom-switch'}
                                            checked={a.active}
                                            onChange={e => a.affiliation.type === affiliationType.rtv && !a.active && a.expiryTimestamp < new Date().setHours(23, 59, 0, 0) ? confirmChangeStateDialog(e,a) : editPlayer(a.affiliationId, a.playerId, e.target.checked, a.medicalCertificateTimestamp, a.payed)}/>
                                    </td>
                                    <td className="col-md-1">{a.affiliation.name}</td>
                                    <td className="col-md-1">{a.player.name + ' ' + a.player.surname}</td>
                                    <td className="col-md-1">{a.player.user.email + ' ' + a.player.phoneNumber}</td>
                                    <td className="col-md-1" style={{color: a.expiryTimestamp && new Date(a.expiryTimestamp) < new Date().setHours(23, 59, 0, 0) ? 'red' : 'white'}}>{a.expiryTimestamp ? new Date(a.expiryTimestamp).toLocaleDateString(): t('NOTHING')}</td>
                                    <td className="col-md-1" style={{minWidth: 110}}>
                                        <DatePicker
                                            locale="it"
                                            className="form-control custom-datepicker"
                                            dateFormat="dd/MM/yy"
                                            selected={(a.medicalCertificateTimestamp && new Date(a.medicalCertificateTimestamp)) || null}
                                            onChange={val => editPlayer(a.affiliationId, a.playerId, a.active, val, a.payed)}
                                            customInput={a.medicalCertificateTimestamp === null ? <FaCalendarAlt size="1.5em"/> : null}/>
                                    </td>
                                    <td className="col-md-1 text-center">
                                        <Form.Check
                                            id={`switch-payed-${a.affiliationId}-${a.playerId}`}
                                            disabled={affiliationPaymentCheck(a.affiliationId)}
                                            type="switch"
                                            className={'custom-switch'}
                                            checked={a.payed}
                                            onChange={e => editPlayer(a.affiliationId, a.playerId, a.active, a.medicalCertificateTimestamp, e.target.checked)}/>
                                    </td>
                                    <td className="col-md-1 text-center">
                                        <Button disabled={!a.renewable} variant="outline-primary" onClick={() => renewPlayerAffiliation(a.playerId, a.affiliationId)}>
                                            <MdAutorenew size="1.5em"/>
                                        </Button>
                                    </td>
                                    <td className="col-md-1 text-center">
                                        <Button variant="outline-secondary" onClick={() => confirmDeletePlayerFromAffiliation(a.playerId, a.affiliationId)}>
                                            <RiDeleteBinLine size="1.5em"/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            : <h6 style={{textAlign: 'center', paddingBottom: 400}}>{affiliatedNameFilter === "" ? t('NO_AFFILIATED') : t('SEARCH_REQUEST_FAILED')}</h6>
        );
    };

    const endRequestsOffset = requestsOffset + 50;
    const currentRequests = playerRequests.slice(requestsOffset, endRequestsOffset);
    const requestsPageCount = Math.ceil(playerRequests.length / 50);

    const handleRequestsPageClick = (event) => {
        const newOffset = (event.selected * 50) % playerRequests.length;
        setRequestsOffset(newOffset);
    };

    const endAffiliatedOffset = affiliatedPlayersOffset + 50;
    const currentAffiliated = affiliated.slice(affiliatedPlayersOffset, endAffiliatedOffset);
    const affiliatedPageCount = Math.ceil(affiliated.length / 50);

    const handleAffiliatedPageClick = (event) => {
        const newOffset = (event.selected * 50) % affiliated.length;
        setAffiliatedPlayersOffset(newOffset);
    };

    const endRenewRequestsOffset = renewRequestsOffset + 50;
    const currentRenewRequests = renewRequests.slice(renewRequestsOffset, endRenewRequestsOffset);
    const renewRequestsPageCount = Math.ceil(renewRequests.length / 50);

    const handleRenewRequestsPageClick = (event) => {
        const newOffset = (event.selected * 50) % renewRequests.length;
        setAffiliatedPlayersOffset(newOffset);
    };


    const goToFilteredAffiliatedUserTab = affiliationId => {
        setActiveTab('affiliated_users');
        setAffiliatedAffiliationFilter(affiliationId.toString());
        setAffiliatedNameFilter("");
        setAffiliatedStateFilter("");
    }

    return(
        <Fragment>
            <CommonMetaTag/>
            <div style={{ marginLeft: 100, marginRight: 100}}>
                <Tabs activeKey={partnerAffiliationsActiveTab} className="myvideo-tabs" style={{marginTop: 100}} onSelect={t => setActiveTab(t)}>
                <Tab eventKey="affiliation_requests" title={t('AFFILIATION_REQUESTS')} tabClassName="myvideo-tab">
                    <div style={{paddingBottom: 20, paddingTop: 20, marginLeft: 20}}>
                        <Form>
                            <Form.Group>
                                <Form.Control name="requestsFilter" style={{maxWidth: 300}} placeholder={t('SEARCH_USER_BY_NAME')} type="text" maxLength="50" onChange={e => setRequestsFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                            </Form.Group>
                        </Form>
                    </div>
                    {requests(currentRequests)}
                    <ReactPaginate
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handleRequestsPageClick}
                        pageRangeDisplayed={5}
                        pageCount={requestsPageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </Tab>
                <Tab eventKey="affiliation_renew_requests" title={t('AFFILIATION_RENEW_REQUESTS')} tabClassName="myvideo-tab">
                    <div style={{paddingBottom: 20, paddingTop: 20, marginLeft: 20}}>
                        <Form>
                            <Form.Group>
                                <Form.Control name="renewRequestsFilter" style={{maxWidth: 300}} placeholder={t('SEARCH_USER_BY_NAME')} type="text" maxLength="50" onChange={e => setRenewRequestsFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                            </Form.Group>
                        </Form>
                    </div>
                    {renewRequestsTable(currentRenewRequests)}
                    <ReactPaginate
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handleRenewRequestsPageClick}
                        pageRangeDisplayed={5}
                        pageCount={renewRequestsPageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </Tab>
                <Tab eventKey="affiliations" title={t('MEMBERSHIPS')} tabClassName="myvideo-tab">
                    <div style={{marginTop: 40, marginLeft: 20, marginRight:20}}>
                        <div className="d-flex flex-row-reverse">
                            <Button variant="secondary" onClick={showAffiliationModal}>{t('MEMBERSHIP_ADD')}</Button>
                        </div>
                        {affiliations && affiliations.length > 0 ?
                            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                                <Col xs={12} sm={12} md={8}>
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th>{t('MEMBERSHIP')}</th>
                                                <th className="text-center">{t('USERS')}</th>
                                                <th className="text-center">{t('CHANGE')}</th>
                                                <th className="text-center">{t('ONOFF')}</th>
                                                <th className="text-center">{t('DELETE')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {affiliations.map(a => (
                                                <tr key={a.affiliationId}>
                                                <td className="col-md-8">{a.name}</td>
                                                <td className="col-md-1 text-center">{a.players.length > 0 ? <FaUser style={{cursor: 'pointer'}} size="1.5em" onClick={() => goToFilteredAffiliatedUserTab(a.affiliationId)}/> : null}</td>
                                                <td className="col-md-1 text-center">{a.type === affiliationType.partner ? <FaPencilAlt style={{cursor: 'pointer'}} onClick={() => showEditAffiliationModal(a)} size="1.5em"/> : null}</td>
                                                <td className="col-md-1 text-center">
                                                    <Form.Check
                                                    id={`switch-${a.affiliationId}`}
                                                    className={'custom-switch'}
                                                    type="switch"
                                                    checked={a.active}
                                                    onChange={e => activateDeactivateAffiliationDialog(e.target.checked, a)}/>
                                                </td>
                                                <td className="col-md-1 text-center">{a.type === affiliationType.partner ? <FaTrashAlt onClick={() => deleteAffiliationDialog(a)} style={{cursor: 'pointer'}} size="1.5em"/> : null}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        :
                        <Fragment>
                            <div className="col-lg-12 mt-sm-2 text-center" style={{paddingTop: 50, paddingBottom: 600}}>
                                <h5>
                                    {t('NO_AFFILIATION')}
                                    <a style={{color: '#DD004A', textDecoration: 'underline', cursor: 'pointer'}} onClick={showAffiliationModal}>{t('NO_AFFILIATION_TWO')}</a>
                                    {t('NO_AFFILIATION_THREE')}
                                </h5>
                            </div>
                        </Fragment>}
                        <Modal show={isAffiliationModalVisible} onHide={hideAffiliationModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>{selectedAffiliation && selectedAffiliation.affiliationId ? t('MEMBERSHIP_ADMINISTRATION') : t('MEMBERSHIP_ADD')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={selectedAffiliation}
                                    onSubmit={newOrEditAffiliation}
                                >
                                    {({
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    values
                                    }) => {
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group>
                                                <Form.Label>{t('NAME')}*</Form.Label>
                                                <Form.Control required maxLength="70" type="text" name="name" onChange={handleChange} value={values.name || ''}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{t('DESCRIPTION')}</Form.Label>
                                                <Form.Control maxLength="255" type="text" name="description" onChange={handleChange} value={values.description || ''}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{t('MEMBERSHIP_VISIBILITY_CALENDAR')}*</Form.Label>
                                                <Form.Control required name="visibilityCalendar" type="number" min="0" max="90" step="1" value={values.visibilityCalendar} onChange={handleChange}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{t('DISCOUNT')}*</Form.Label>
                                                <Form.Control required type="number" min={0} max={100} name="discount" onChange={handleChange} value={values.discount}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{t('TYPE')}*</Form.Label>
                                                <Form.Check required name="renewalType" type="radio" label={t('NO_RENEWAL')} checked={values.renewalType === renewalType.lifeTime} onChange={() => setFieldValue('renewalType', renewalType.lifeTime)}/>
                                                <Form.Check required name="renewalType" type="radio" label={t('WITH_RENEWAL')} checked={values.renewalType !== renewalType.lifeTime} onChange={() => setFieldValue('renewalType', renewalType.perPlayer)}/>
                                            </Form.Group>
                                            {values.renewalType !== renewalType.lifeTime ?
                                                <div>
                                                    <Form.Group>
                                                        <Form.Label>{t('SUBSCRIPTION_DURATION')}*</Form.Label>
                                                        <Form.Control required as="select" name="durationType" onChange={handleChange} value={values.durationType}>
                                                            <option value="">{t('SELECT_DURATION')}</option>
                                                            <option value={durationType.days}>{t('DAYS')}</option>
                                                            <option value={durationType.months}>{t('MONTHS')}</option>
                                                            <option value={durationType.years}>{t('YEAR')}</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Control required type="number" min={getMinimumDuration(values.durationType)} max={getMaximumDuration(values.durationType)} name="duration" placeholder={t('QUANTITY')} onChange={handleChange} value={values.duration || null}/>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>{t('SUBSCRIPTION_FIRST_PERIOD_START')}</Form.Label>
                                                        <Form.Check required name="renewalTypeTwo" type="radio" label={t('DAY_OF_APPROVAL')} checked={values.renewalType === renewalType.perPlayer} onChange={() => setFieldValue('renewalType', renewalType.perPlayer)}/>
                                                        <Form.Check required name="renewalTypeTwo" type="radio" label={t('IN_THIS_DATE')} checked={values.renewalType === renewalType.fixed} onChange={() => setFieldValue('renewalType', renewalType.fixed)}/>
                                                    {
                                                        values.renewalType === renewalType.fixed ?
                                                        <DatePicker
                                                            locale="it"
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={(values.fixedDatetime && new Date(values.fixedDatetime)) || null}
                                                            minDate={values.durationType == durationType.days ? new Date().setDate(new Date().getDate() - values.duration) :
                                                                values.durationType == durationType.months ? new Date().setMonth(new Date().getMonth() - values.duration) :
                                                                values.durationType == durationType.years ? new Date().setFullYear(new Date().getFullYear() - values.duration) :
                                                                 new Date()}
                                                                 onChange={val => {
                                                                    const updatedVal = new Date(val);
                                                                    updatedVal.setHours(12);
                                                                    setFieldValue('fixedDatetime', updatedVal);
                                                                }}
                                                        />
                                                        : null
                                                    }
                                                    </Form.Group>
                                                </div>
                                            : null}
                                            <Form.Group>
                                                <Form.Label>{t('FOR_A_FEE')}*</Form.Label>
                                                <Form.Check required name="forAFee" type="radio" label={t('YES')} checked={values.onlinePayment !== null} onChange={() => setFieldValue('onlinePayment', false)}/>
                                                <Form.Check required name="forAFee" type="radio" label={t('NO')} checked={values.onlinePayment === null} onChange={() => setFieldValue('onlinePayment', null)}/>
                                            </Form.Group>
                                            {values.onlinePayment !== null && profile.affiliationOnlinePayment ?
                                                <Form.Group>
                                                    <Form.Label>{t('ONLINE_PAYMENT')}*</Form.Label>
                                                    <Form.Check required name="onlinePayment" type="radio" label={t('YES')} checked={values.onlinePayment} onChange={() => setFieldValue('onlinePayment', true)}/>
                                                    <Form.Check required name="onlinePayment" type="radio" label={t('NO')} checked={!values.onlinePayment} onChange={() => setFieldValue('onlinePayment', false)}/>
                                                </Form.Group>
                                            : null}
                                            {values.onlinePayment ?
                                                <Form.Group>
                                                    <Form.Label>{t('AFFILIATION_PRICE')}*</Form.Label>
                                                    <Form.Control required type="number" min={1} name="price" onChange={handleChange} value={values.price || null}/>
                                                </Form.Group>
                                            : null}
                                            <Button type="submit" variant="secondary">
                                                {t('SAVE')}
                                            </Button>
                                        </Form>
                                    );
                                    }}
                                </Formik>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={hideAffiliationModal}>
                                    {t('CLOSE_BUTTON')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Tab>
                <Tab eventKey="affiliated_users" title={t('AFFILIATED_USERS')} tabClassName="myvideo-tab">
                    <div style={{paddingBottom: 20, paddingTop: 20, marginLeft: 20}}>
                        <Form>
                        <Form.Group>
                            <Row>
                                <Form.Control name="affiliatedNameFilter" value={affiliatedNameFilter} style={{maxWidth: 300}} placeholder={t('SEARCH_USER_BY_NAME')} type="text" maxLength="50" onChange={e => setAffiliatedNameFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                                <Form.Control value={affiliatedAffiliationFilter} style={{fontSize: '1em', maxWidth: 300, marginLeft: 10}} name="userTagId" as="select" onChange={e => setAffiliatedAffiliationFilter(e.target.value)}>
                                    <option value={""}>{t('ALL_AFFILIATIONS')}</option>
                                    {affiliatedAffiationOptions.map(o => (<option key={o.value} value={o.value}>{o.label}</option>))}
                                </Form.Control>
                                <Form.Control value={affiliatedStateFilter} style={{fontSize: '1em', maxWidth: 300, marginLeft: 10}} name="userTagId" as="select" onChange={e => setAffiliatedStateFilter(e.target.value)}>
                                    <option value={""}>{t('ALL_STATES')}</option>
                                    <option value={"active"}>{t('ACTIVE')}</option>
                                    <option value={"nonactive"}>{t('NONACTIVE')}</option>
                                    <option value={"payed"}>{t('PAID')}</option>
                                    <option value={"toBePayed"}>{t('TO_PAY')}</option>
                                    <option value={"expired"}>{t('EXPIRED')}</option>
                                    <option value={"expiredCertificate"}>{t('EXPIRED_CERTIFICATE')}</option>
                                    <option value={"noCertificate"}>{t('NO_CERTIFICATE')}</option>
                                </Form.Control>
                            </Row>
                        </Form.Group>
                        </Form>
                    </div>
                    {showAffiliated(currentAffiliated)}
                    <ReactPaginate
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handleAffiliatedPageClick}
                        pageRangeDisplayed={5}
                        pageCount={affiliatedPageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </Tab>
            </Tabs>
            </div>
            <Dialog
              ref={el => {
                dialog = el;
              }}/>
        </Fragment>
    );
};

const mapStateToProps = ({login: {profile}, partner: {isAffiliationModalVisible, affiliations, selectedAffiliation, affiliatedPlayers, partnerAffiliationsActiveTab, affiliationRenewRequests}}) => ({
    profile,
    isAffiliationModalVisible,
    affiliations,
    selectedAffiliation,
    affiliatedPlayers,
    partnerAffiliationsActiveTab,
    affiliationRenewRequests
  });

const mapDispatchToProps = dispatch => ({
    showAffiliationModal: () => {
        dispatch(selectAffiliation({name: '', description: null, discount: 0, renewalType: renewalType.lifeTime, onlinePayment: null, price: null, visibilityCalendar: 0}));
        dispatch(showAffiliationModal());
    },
    hideAffiliationModal: () => {
        dispatch(hideAffiliationModal());
    },
    newOrEditAffiliation: values => {
        if ("durationType" in values && values.durationType !== null) {
            values.durationType = parseInt(values.durationType, 10);
        }
        if(values.renewalType === renewalType.lifeTime) {
            delete values.duration;
            delete values.durationType;
        }
        if (values.renewalType !== renewalType.fixed) {
            delete values.fixedDatetime;
        }
        if(!values.onlinePayment) {
            delete values.price;
        }
        if(values.affiliationId) {
            dispatch(editAffiliation(values));
        }
        else {
            dispatch(createAffiliation(values));
        }
    },
    activateDeactivateAffiliation: (active, a) => {
        dispatch(activateDeactivateAffiliation(a.affiliationId,active));
    },
    deleteAffiliation: affiliationId => {
        dispatch(deleteAffiliation(affiliationId));
    },

    showEditAffiliationModal: affiliation => {
        dispatch(selectAffiliation(affiliation));
        dispatch(showAffiliationModal());
    },
    setActiveTab: tab => {
        dispatch(setPartnerAffiliationsActiveTab(tab));
    },
    deletePlayerFromAffiliation: (playerId,affiliationId) => {
        dispatch(deletePlayerFromAffiliation(playerId,affiliationId));
    },
    editPlayer: (affiliationId, playerId, active, medicalCertificateTimestamp, payed) => {
        dispatch(editAffiliationPlayer(affiliationId, playerId, active, medicalCertificateTimestamp, payed));
    },
    renewPlayerAffiliation: (playerId, affiliationId) => {
        dispatch(renewPlayerAffiliation(playerId, affiliationId))
    },
    approveRenewRequest: request => {
        dispatch(acceptOrRefuseAffiliationRenewRequest(request, true));
    },
    refuseRenewRequest: request => {
        dispatch(acceptOrRefuseAffiliationRenewRequest(request, false));
    }
});

  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerAffiliations));
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Fade, Container, Row, Col, Form, Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import r from '../images/title_r.svg';
import bg from '../images/img_bg_gradient.jpg';
import CommonMetaTag from './common-meta-tag';
import academy from '../images/genoa_academy.svg';


const Login = ({
  login, profile, goto, showRecoveryPasswordModal, isRecoveryPasswordModalVisible, hideRecoveryPasswordModal, recoveryPassword
}) => {
  const {t, i18n} = useTranslation();
  if (profile !== null) {
    return (
      <Redirect to="/"/>
    );
  }

  return (
    <Fragment>
      <Formik
        initialValues={{email: null, password: null}}
        onSubmit={login}
      >
        {({
          handleSubmit,
          handleChange,
          values
        }) => {
          return (
            <Fragment>
              <CommonMetaTag/>
              <Fade in appear style={{transition: 'opacity 0.30s linear'}}>
                <Container fluid>
                  <Row className="vh-100">
                    <Col xs={12} sm={12} md={6} style={{backgroundColor: '#fff', color: '#000'}}>
                      <Row className="justify-content-end" style={{marginTop: 100, marginLeft: 15, marginRight: 15}}>
                        <Col xs={12} sm={12} md={6}>
                          <img
                            src={r}
                            height="30"
                            className="mx-auto d-block"
                            alt={t('LOGO')}
                          />
                          <Row className="justify-content-center" style={{marginTop: 20}}>
                            <h1>Replayer</h1>
                          </Row>
                          <Row className="justify-content-center">
                            <h4>{t('MOTTO')}</h4>
                          </Row>
                          <h4 style={{marginTop: 30}}>{t('LOGIN_ENTER_REPLAYER')}</h4>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group>
                              <Form.Label>{t('EMAIL')}</Form.Label>
                              <Form.Control required name="email" placeholder={t('EMAIL')} type="text" maxLength="255" value={values.email || ''} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group>
                              <Form.Row>
                                <Col>
                                  <Form.Label>{t('PASSWORD')}</Form.Label>
                                </Col>
                                <Col>
                                  <Form.Label><a href="#" onClick={showRecoveryPasswordModal}>{t('LOGIN_PASSWORD_RECOVERY')}</a></Form.Label>
                                </Col>
                              </Form.Row>
                              <Form.Control required name="password" placeholder={t('PASSWORD')} type="password" value={values.password || ''} onChange={handleChange}/>
                            </Form.Group>
                            <Button type="submit" style={{marginBottom: 50}}>{t('LOGIN_BUTTON')}</Button>
                          </Form>
                          <img
                            src={academy}
                            style={{width: '80%', height: 'auto'}}
                            className="mx-auto d-block"
                            alt={t('LOGO')}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={6} style={{backgroundImage: `URL(${bg})`, backgroundSize: 'cover'}}>
                      <Row className="justify-content-start" style={{marginLeft: 30, marginRight: 30}}>
                        <Col xs={12} sm={12} md={6}>
                          <Row style={{marginTop: 120}}>
                            <h4>{t('LOGIN_PLAYER_TITLE')}</h4>
                          </Row>
                          <Row style={{marginTop: 20}}>
                            <p>{t('LOGIN_PLAYER_DESC')}</p>
                          </Row>
                          <Row style={{marginBottom: 20}}>
                            <Button type="button" variant="secondary" size="lg" onClick={goto('/sign-up-player')}>{t('SIGN_IN_BUTTON')}</Button>
                          </Row>
                          <Row style={{marginTop: 20}}>
                            <h4>{t('LOGIN_PARTNER_TITLE')}</h4>
                          </Row>
                          <Row style={{marginTop: 20}}>
                            <p>{t('LOGIN_PARTNER_DESC')}</p>
                          </Row>
                          <Row style={{marginBottom: 50}}>
                            <Button href="https://www.replayer.it/diventa-partner/" target="_blank" rel="noopener noreferrer" variant="primary" size="lg">{t('LOGIN_MORE_INFO_BUTTON')}</Button>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Fade>
            </Fragment>
          );
        }}
      </Formik>
      <Modal show={isRecoveryPasswordModalVisible} onHide={hideRecoveryPasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('LOGIN_PASSWORD_RECOVERY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{email: null}}
            onSubmit={recoveryPassword}
          >
            {({
              handleSubmit,
              handleChange,
              values
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>{t('EMAIL')}</Form.Label>
                    <Form.Control required name="email" placeholder={t('EMAIL')} type="email" value={values.email || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Button type="submit" variant="secondary">{t('LOGIN_PASSWORD_RECOVERY_BUTTON')}</Button>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideRecoveryPasswordModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

Login.propTypes = {
  profile: PropTypes.object,
  login: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired,
  showRecoveryPasswordModal: PropTypes.func.isRequired,
  hideRecoveryPasswordModal: PropTypes.func.isRequired,
  isRecoveryPasswordModalVisible: PropTypes.bool,
  recoveryPassword: PropTypes.func.isRequired
};

Login.defaultProps = {
  profile: null,
  isRecoveryPasswordModalVisible: false
};

export default Login;

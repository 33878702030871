import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import PartnerField from '../components/partner-field';
import {saveOwnedField, deleteFieldImageFile, deletePartnerField, getOwnedFieldSlots, getOwnedFieldBookings, openFixedCostsModal, closeFixedCostsModal, createFixedCost, deleteFixedCost} from '../actions/partner';

const mapStateToProps = ({partner: {ownedField, isFixedCostsModalVisible, affiliations}, login: {token, profile}}) => ({
  ownedField,
  token,
  profile,
  isFixedCostsModalVisible,
  affiliations,
});

const mapDispatchToProps = dispatch => ({
  save: fieldData => {
    if (fieldData.bookable === null){
      fieldData.bookable = false;
    }
    dispatch(saveOwnedField(fieldData));
  },
  removeFile: (fileName, fieldId) => {
    dispatch(deleteFieldImageFile(fileName, fieldId));
  },
  showCalendar: fieldId => () => {
    dispatch(getOwnedFieldSlots(fieldId));
    dispatch(push('partner-field-calendar'));
  },
  showBookingCalendar: fieldId => () => {
    dispatch(getOwnedFieldSlots(fieldId, true, 'field', new Date(), 'WEEK'));
    dispatch(getOwnedFieldBookings(new Date(), 'WEEK'));
    dispatch(push('partner-booking-calendar'));
  },
  deleteField: fieldId => {
    dispatch(deletePartnerField(fieldId));
  },
  openFixedCostsModal: () => {
    dispatch(openFixedCostsModal());
  },
  closeFixedCostsModal: () => {
    dispatch(closeFixedCostsModal());
  },
  createFixedCost: values => {
    values.hourPrice = values.hourPrice * 100;
    dispatch(createFixedCost(values));
    dispatch(closeFixedCostsModal());
  },
  deleteFixedCost: (costId, fieldId) => {
    dispatch(deleteFixedCost(costId, fieldId));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerField));

/* eslint complexity: ["error", 40] */

import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Container, Fade, Carousel, Row, Col, Form, Tab, Tabs, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import terrainIcon from '../images/terreno_campo.svg';
import swimmingPoolIcon from '../images/terreno_piscina.svg';
import pointerIcon from '../images/pointer_icon.svg';
import envelopeIcon from '../images/envelope_icon.svg';
import phoneIcon from '../images/phone_icon.svg';
import indoorIcon from '../images/indoor_icon.svg';
import outdoorIcon from '../images/outdoor_icon.svg';
import editIcon from '../images/edit_icon.svg';
import {SPORTS, xmlType} from '../constants';
import {updatePartnerByAdmin, updatePartnerFieldByAdmin, updatePartnerServiceByAdmin, newXml, showXmlModal, hideXmlModal, saveXml, showCameraModal, hideCameraModal, newCamera, editCamera, createCamera, deleteCamera, modifyCamera, updatePartnerVideoCostByAdmin, newXmlPartnerVideo, showXmlPartnerVideoModal, hideXmlPartnerVideoModal, savePartnerVideoXml, changePartnerPermissions} from '../actions/admin';
import AdminPartnerRtvChannels from './admin-partner-rtv_channels';
import FormFileInput from 'react-bootstrap/esm/FormFileInput';
import Dialog from 'react-bootstrap-dialog';


const AdminShowPartner = ({
  partner,
  token,
  fields,
  services,
  rtvChannels,
  updatePartner,
  updatePartnerField,
  updatePartnerService,
  selectedXml,
  newXmlModal,
  newXmlPartnerVideoModal,
  showXmlModal,
  hideXmlModal,
  hideXmlPartnerVideoModal,
  isXmlModalVisible,
  isXmlPartnerVideoModalVisible,
  hideCameraModal,
  cameraModal,
  selectedCamera,
  isCameraModalVisible,
  createXml,
  createPartnerVideoXml,
  removeFile,
  createOrEditCamera,
  deleteCamera,
  updatePartnerVideoCost,
  changePartnerPermissions,
}) => {
  const {t, i18n} = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const fileChangeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};
  
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (partner === null) {
    return (
      <Fragment>
        <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
          <div className="dashboard_hero_overlay_rp"/>
        </div>
        <div className="main main_raised_rp">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-12 mb-sm-2 mb-md-4">
                  <div className="detail_head_rp rounded">
                    <h4 className="section_title_neg_rp">{t('NO_PARTNER_SELECTED')}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  let dialog = null;

  const deleteCameraDialog = (cameraId, partnerId) => {
    dialog.show({
      title: t('DELETE_CAMERA'),
      body: t('DELETE_CAMERA_INFO'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteCamera(cameraId, partnerId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-sm-2 mb-md-4">
                    <div className="detail_head_rp rounded">
                      <h4 className="section_title_neg_rp">{partner.businessName}</h4>
                      <p>{partner.isSpecialist ? "Sport Specialist" : t('SPORTS_CENTER')}</p>
                      <hr className="hr_blue"/>
                      <ul className="list-unstyled list-inline row">
                        <li className="list-inline-item col-lg-4 mb-1"><img src={pointerIcon} alt={t('ICON_POINTER')}/>{partner.city}, {partner.address}</li>
                        <li className="list-inline-item col-lg-4 mb-1"><img src={envelopeIcon} alt={t('ICON_EMAIL')}/><a href={`mailto:${partner.user.email}`}>{partner.user.email}</a></li>
                        <li className="list-inline-item col-lg-4 mb-1"><img src={phoneIcon} alt={t('ICON_PHONE')}/><a href={`tel:${partner.phoneNumber}`}>{partner.phoneNumber}</a></li>
                      </ul>
                      <hr className="hr_blue"/>
                      <h5 className="section_title_neg_rp">{t('TAX_DATA')}</h5>
                      <ul className="list-unstyled list-inline row">
                        <li className="list-inline-item col-lg-4 mb-1">{t('REGISTERED_OFFICE')}:{" " + partner.registeredOffice}</li>
                        <li className="list-inline-item col-lg-4 mb-1">{t('VAT_NUMBER')}:{" " + partner.iva}</li>
                        <li className="list-inline-item col-lg-4 mb-1">{t('SDI_PEC')}:{" " + partner.sdiPec}</li>
                      </ul>
                      <hr className="hr_blue"/>
                      <div className="row" style={{minHeight: 200}}>
                        {
                          partner.openingHour && partner.openingHour.sportsCenter ?
                            <div className="col-lg-6 mt-sm-2 mb-sm-2 mb-lg-4">
                              <h5 className="section_title_neg_rp">{t('SPORTS_CENTER_HOURS')}</h5>
                              <ul className="list-unstyled">
                                {partner.openingHour.sportsCenter.split('\n').map(t => <li>{t}</li>)}
                              </ul>
                            </div> :
                            null
                        }
                        {
                          partner.openingHour && partner.openingHour.secretary ?
                            <div className="col-lg-6 mt-sm-2 mb-sm-2 mb-lg-4">
                              <h5 className="section_title_neg_rp">{t('SECRETARIAT_HOURS')}</h5>
                              <ul className="list-unstyled">
                                {partner.openingHour.secretary.split('\n').map(t => <li>{t}</li>)}
                              </ul>
                            </div> :
                            null
                        }
                      </div>
                      <hr className="hr_blue"/>
                      <Formik
                        enableReinitialize
                        initialValues={{videoStore: partner.videoStore, affiliationsOnlinePayment: partner.affiliationsOnlinePayment || false}}
                        onSubmit={v => changePartnerPermissions(v, partner.uid)}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values
                        }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                  <Form.Row>
                                    <Form.Check name="videoStore" type="checkbox" label={t('PUBLISH_VIDEO_STORE')} checked={values.videoStore} onChange={handleChange} style={{marginRight: 20}}/>
                                    <Form.Check name="affiliationsOnlinePayment" type="checkbox" label={t('AFFILIATIONS_ONLINE_PAYMENT')} checked={values.affiliationsOnlinePayment} onChange={handleChange}/>
                                  </Form.Row>
                                </Form.Group>
                              <Button type="submit">{t('SAVE')}</Button>
                            </Form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
                <Tabs defaultActiveKey="campi" className="admin-show-partner-tabs" style={{marginTop: 50, marginBottom: 50}}>
                  <Tab eventKey="campi" title={t('FIELDS')} tabClassName="admin-show-partner-tab">
                    <div className="row">
                      {
                        fields.map(f =>
                          (
                            <div key={f.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                              <div className="card sportcenter_card_bg_rp">
                                <div className="card_image_rp">
                                  {
                                    f.imagesNames.length === 0 ?
                                      <div className="videoWrapper card_image_sportcenter_rp"/> :
                                      <Carousel style={{marginBottom: 10}} interval={null}>
                                        {
                                          f.imagesNames.map(i =>
                                            (
                                              <Carousel.Item key={i}>
                                                <img
                                                  className="d-block w-100"
                                                  src={`${Configuration.apiEndPoint}/partners/fields/${f.id}/images?load=${i}`}
                                                  alt={t('FIRST_SLIDE')}
                                                  style={{width: '100%'}}
                                                />
                                              </Carousel.Item>
                                            )
                                          )
                                        }
                                      </Carousel>
                                  }
                                </div>
                                <p style={{textAlign: 'right', marginRight: 5}}>id: {f.id}</p>
                                <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                                  <div className="d-flex">
                                    <div className="sportcenter_rp_title_icon">
                                      <img src={SPORTS[f.sport] ? SPORTS[f.sport].icon : null} alt={t('ICON_SPORT')}/>
                                    </div>
                                    <div>
                                      <h5 className="card-title">{f.name}</h5>
                                      <p>{f.sport}</p>
                                    </div>
                                  </div>
                                  <p>{f.description}</p>
                                  <ul className="card_facilities_list_icons">
                                    {
                                      f.covered ?
                                        <li className="card_facilities_list_icons_item"><img src={`${outdoorIcon}`} alt={t('ICON_OUTDOOR')}/> {t('OPEN_FIELD')}</li> :
                                        <li className="card_facilities_list_icons_item"><img src={`${indoorIcon}`} alt={t('ICON_OUTDOOR')}/> {t('COVERED_FIELD')}</li>
                                    }
                                    {
                                      f.terrain === 'Piscina' ?
                                        <li className="card_facilities_list_icons_item pd-lg-4"><img src={`${swimmingPoolIcon}`} alt={t('ICON_TERRAIN')}/>{f.terrain}</li> :
                                        <li className="card_facilities_list_icons_item pd-lg-4"><img src={`${terrainIcon}`} alt={t('ICON_TERRAIN')}/>{f.terrain}</li>
                                    }
                                  </ul>
                                </div>
                                <div className="card-body d-flex flex-column card_border_bottom_white mb-3" style={{borderBottom: "none"}}>
                                  <Formik
                                    enableReinitialize
                                    initialValues={{id: f.id, rtv: f.rtv, recording: f.recording, payment: f.payment, cameras: f.cameras, videoHourPrice: f.videoHourPrice && f.videoHourPrice ? f.videoHourPrice / 100 : 0.01, notifications: f.notifications}}
                                    onSubmit={updatePartnerField}
                                  >
                                    {({
                                      handleSubmit,
                                      handleChange,
                                      values
                                    }) => {
                                      return (
                                        <Form onSubmit={handleSubmit}>
                                          <Form.Group>
                                            <Form.Check name="payment" type="checkbox" label={t('FIELD_PAYMENT')} checked={values.payment} onChange={handleChange}/>
                                          </Form.Group>
                                          <Form.Label>{t('CAMERA_MANAGEMENT')}</Form.Label>
                                          {
                                          values.cameras.sort((a, b) => a.position - b.position).map( (camera, index) =>
                                            <li class="list-group-item d-flex justify-content-between align-items-center" style={{paddingRight: 0, paddingTop: 2, paddingBottom: 2}}>
                                              <div class="d-flex align-items-center">
                                                <div class="ms-3">
                                                  <p class="fw-bold mb-1">{index+1}. {camera.name} (id: {camera.id})</p>
                                                </div>
                                              </div>
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={() => cameraModal(camera)}><img src={editIcon} alt={t('ICON_MODIFY')}/></a></li>
                                            </li>
                                          )
                                          }
                                          <br/>
                                          {values.cameras.length > 0 ?
                                          <div>
                                            <Form.Group>
                                              <Form.Check name="rtv" type="checkbox" label={t('REPLAYER_TV')} checked={values.rtv} onChange={handleChange}/>
                                            </Form.Group>
                                            <Form.Group>
                                              <Form.Check name="recording" type="checkbox" label={t('VIDEO_RECORDING')} checked={values.recording} onChange={handleChange}/>
                                            </Form.Group>
                                            <Form.Group>
                                              <Form.Check name="notifications" type="checkbox" disabled={values.notifications === null} label={values.notifications !== null ? t('NVR_NOTIFICATIONS') : t('NVR_INACTIVE')} checked={values.notifications} onChange={handleChange}/>
                                            </Form.Group>
                                            <Form.Group>
                                              <Form.Label>{t('VIDEO_HOUR_PRICE')}</Form.Label>
                                              <Form.Control name="videoHourPrice" placeholder={t('VIDEO_HOUR_PRICE')} type="number" min={0.01} max={100} step={0.01} value={values.videoHourPrice || ''} onChange={handleChange}/>
                                            </Form.Group>
                                          </div>
                                          : null}
                                          <Form.Group>
                                            <Button type="submit">{t('SAVE')}</Button>
                                            <Button variant="secondary" onClick={() => cameraModal(null, values.id)} style={{marginLeft: 10}}>{t('ADD_CAMERA')}</Button>
                                          </Form.Group>
                                          <Form.Group>
                                            <Button variant="primary" onClick={() => newXmlModal(values.id)}>{t('XML_UPLOAD')}</Button>
                                          </Form.Group>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      }
                    </div>
                  </Tab>
                  <Tab eventKey="servizi" title={t('SERVICES')} tabClassName="admin-show-partner-tab">
                    <div className="row">
                      {
                        services.map(s =>
                          (
                            <div key={s.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                              <div className="card sportcenter_card_bg_rp">
                                <div className="card_image_rp">
                                  {
                                    s.imagesNames.length === 0 ?
                                      <div className="videoWrapper card_image_sportcenter_rp"/> :
                                      <Carousel style={{marginBottom: 10}} interval={null}>
                                        {
                                          s.imagesNames.map(i =>
                                            (
                                              <Carousel.Item key={i}>
                                                <img
                                                  className="d-block w-100"
                                                  src={`${Configuration.apiEndPoint}/partners/services/${s.id}/images?load=${i}`}
                                                  alt={t('FIRST_SLIDE')}
                                                  style={{width: '100%'}}
                                                />
                                              </Carousel.Item>
                                            )
                                          )
                                        }
                                      </Carousel>
                                  }
                                </div>
                                <p style={{textAlign: 'right', marginRight: 5}}>id: {s.id}</p>
                                <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                                <h5 className="card-title">{s.name}</h5>
                                  <p>{s.description}</p>
                                </div>
                                <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                                  <Formik
                                    initialValues={{id: s.id, rtv: s.rtv, recording: s.recording, payment: s.payment, videoHourPrice: s.videoHourPrice && s.videoHourPrice ? s.videoHourPrice / 100 : 0.01}}
                                    onSubmit={updatePartnerService}
                                  >
                                    {({
                                      handleSubmit,
                                      handleChange,
                                      values
                                    }) => {
                                      return (
                                        <Form onSubmit={handleSubmit}>
                                          {/*
                                          <Form.Group>
                                            <Form.Check name="rtv" type="checkbox" label={t('REPLAYER_TV')} checked={values.rtv} onChange={handleChange}/>
                                          </Form.Group>
                                          <Form.Group>
                                            <Form.Check name="recording" type="checkbox" label={t('VIDEO_RECORDING')} checked={values.recording} onChange={handleChange}/>
                                          </Form.Group>
                                          */}
                                          <Form.Group>
                                            <Form.Check name="payment" type="checkbox" label={t('SERVICE_PAYMENT')} checked={values.payment} onChange={handleChange}/>
                                          </Form.Group>
                                          {/*
                                          <Form.Group>
                                            <Form.Label>{t('VIDEO_HOUR_PRICE')}</Form.Label>
                                            <Form.Control name="videoHourPrice" placeholder={t('VIDEO_HOUR_PRICE')} type="number" min={0.01} max={100} step={0.01} value={values.videoHourPrice || ''} onChange={handleChange}/>
                                          </Form.Group>
                                          */}
                                          <Button type="submit">{t('SAVE')}</Button>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      }
                    </div>
                  </Tab>
                  <Tab eventKey="rtv_channels" title={t('RTV_CHANNELS')} tabClassName="admin-show-partner-tab">
                    <div className="detail_head_rp rounded" style={{ marginBottom: 20 }}>
                      <Formik
                        initialValues={
                          {
                            uid: partner.uid,                            
                            rtvKeepVideos: partner.rtvKeepVideos
                          }
                        }
                        onSubmit={updatePartner}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values
                        }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                              <Row>                                
                                <Col md={12} lg={4}>
                                  <Form.Group>
                                    <Form.Label>{t('REPLAYER_TV_KEEP_VIDEOS')}</Form.Label>
                                    <Form.Control name="rtvKeepVideos" placeholder={t('REPLAYER_TV_KEEP_VIDEOS_DESC')} type="number" min={1} max={100} step={1} value={values.rtvKeepVideos || ''} onChange={handleChange}/>
                                  </Form.Group>
                                </Col>
                              </Row>                              
                              <Button type="submit">{t('SAVE')}</Button>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                    <AdminPartnerRtvChannels/>
                  </Tab>
                  <Tab eventKey="video_partner" title={t('PARTNER_VIDEOS')} tabClassName="admin-show-partner-tab">
                    <div className="detail_head_rp rounded">
                      <Formik
                        initialValues={
                          {
                            uid: partner.uid,
                            videoPartnerCost: partner.videoPartnerCost || 0
                          }
                        }
                        onSubmit={updatePartnerVideoCost}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values
                        }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                              <Row>                                
                                <Col md={12} lg={4}>
                                  <Form.Group>
                                    <Form.Label>{t('PARTNER_VIDEO_COST')}</Form.Label>
                                    <Form.Control name="videoPartnerCost" placeholder={t('PARTNER_VIDEO_COST')} type="number" min={0} step={0.01} value={values.videoPartnerCost} onChange={handleChange} required/>
                                  </Form.Group>
                                </Col>
                              </Row>                              
                              <Button type="submit">{t('SAVE')}</Button>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                    <Form.Group style={{marginTop: 20}}>
                      <Button variant="secondary" onClick={() => newXmlPartnerVideoModal(partner.uid)}>{t('XML_UPLOAD')}</Button>
                    </Form.Group>
                    <br/><br/><br/><br/><br/>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      {
        isXmlModalVisible ?
          <Modal show={isXmlModalVisible} onHide={hideXmlModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('XML')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={selectedXml}
                onSubmit={(values)=> {
                  values.selectedFile = selectedFile;
                  createXml(values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('XML_TYPE')}*</Form.Label>
                        <Form.Check required name="xmlType" type="radio" label={t('XML_GEOMETRY')} checked={values.xmlType === xmlType.geometry} onChange={() => setFieldValue('xmlType', xmlType.geometry)}/>
                        <Form.Check required name="xmlType" type="radio" label={t('XML_HOTSPOT')} checked={values.xmlType === xmlType.hotspot} onChange={() => setFieldValue('xmlType', xmlType.hotspot)}/>
                        <Form.Check required name="xmlType" type="radio" label={t('XML_ADVERTISING')} checked={values.xmlType === xmlType.advertising} onChange={() => setFieldValue('xmlType', xmlType.advertising)}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('SELECT_FILE')}*</Form.Label>
                        <FormFileInput required id="xml" name="xml" type="file" class="form-control-file" onChange={fileChangeHandler}/>
                      </Form.Group>
                      <Button variant="secondary" type="submit">{t('SAVE')}</Button>
                    </Form>
                  );
                }}
              </Formik>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={hideXmlModal}>
                {t('CLOSE_BUTTON')}
              </Button>
            </Modal.Footer>
          </Modal> :
          null
      }
      {
        isXmlPartnerVideoModalVisible ?
          <Modal show={isXmlPartnerVideoModalVisible} onHide={hideXmlPartnerVideoModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('XML')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={selectedXml}
                onSubmit={(values)=> {
                  values.selectedFile = selectedFile;
                  createPartnerVideoXml(values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('XML_TYPE')}*</Form.Label>
                        <Form.Check required name="xmlType" type="radio" label={t('XML_GEOMETRY')} checked={values.xmlType === xmlType.geometry} onChange={() => setFieldValue('xmlType', xmlType.geometry)}/>
                        <Form.Check required name="xmlType" type="radio" label={t('XML_HOTSPOT')} checked={values.xmlType === xmlType.hotspot} onChange={() => setFieldValue('xmlType', xmlType.hotspot)}/>
                        <Form.Check required name="xmlType" type="radio" label={t('XML_ADVERTISING')} checked={values.xmlType === xmlType.advertising} onChange={() => setFieldValue('xmlType', xmlType.advertising)}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('SELECT_FILE')}*</Form.Label>
                        <FormFileInput required id="xml" name="xml" type="file" class="form-control-file" onChange={fileChangeHandler}/>
                      </Form.Group>
                      <Button variant="secondary" type="submit">{t('SAVE')}</Button>
                    </Form>
                  );
                }}
              </Formik>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={hideXmlPartnerVideoModal}>
                {t('CLOSE_BUTTON')}
              </Button>
            </Modal.Footer>
          </Modal> :
          null
      }
      <Modal show={isCameraModalVisible} onHide={hideCameraModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCamera && selectedCamera.name ? selectedCamera.name : t('NEW_CAMERA')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={selectedCamera}
            onSubmit={ values => createOrEditCamera(values, partner.uid) }
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {values.id ? <p style={{textAlign: 'right', marginRight: 5}}>id: {values.id}</p> : null}
                  <Form.Group style={{marginTop: values.id ? -38 : 0}}>
                    <Form.Label>{t('CAMERA_NAME')}</Form.Label>
                    <Form.Control required name="name" placeholder={t('CAMERA_NAME')} type="text" value={values.name || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('CAMERA_TYPE')}*</Form.Label>
                    <Form.Control required as="select" name="format" value={String(values.format) || ''} onChange={handleChange}>
                      <option value="">{t('GIVE_TYPE')}</option>
                      <option value={1}>{t('PANORAMIC')}</option>
                      <option value={0}>{t('FLAT')}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('CHOOSE_POSITION')}</Form.Label>
                    {
                      fields.find(f => f.id === values.fieldId).cameras.map((element, index) => {
                        return <Form.Check key={index} required name="position" type="radio" label={index+1} checked={values.position === index+1} onChange={() => setFieldValue('position', index+1)}/>
                      })
                    }
                    {
                      !values.id ?
                      <Form.Check required name="position" type="radio" label={fields.find(f => f.id === values.fieldId).cameras.length+1} checked={values.position === fields.find(f => f.id === values.fieldId).cameras.length+1} onChange={() => setFieldValue('position', fields.find(f => f.id === values.fieldId).cameras.length+1)}/> 
                      : null
                    }
                  </Form.Group>
                  <div>
                  <Button variant="secondary" type="submit">{t('SAVE')}</Button>
                  {values.id ?
                  <Button className="btn_black_rp" onClick={() => deleteCameraDialog(values.id, partner.uid)} style={{marginLeft: 10}}>{t('DELETE')}</Button> : null}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideCameraModal}>{t('CANCEL')}</Button>
        </Modal.Footer>
      </Modal>
      <Dialog
        ref={el => {
        dialog = el;
        }}/>
    </Fragment>
  );
};

AdminShowPartner.propTypes = {
  partner: PropTypes.object,
  token: PropTypes.string,
  fields: PropTypes.array,
  services: PropTypes.array,
  rtvChannels: PropTypes.array,
  updatePartner: PropTypes.func.isRequired,
  updatePartnerField: PropTypes.func.isRequired,
  updatePartnerService: PropTypes.func.isRequired,
  selectedXml: PropTypes.object,
  newXmlModal: PropTypes.func.isRequired,
  isXmlModalVisible: PropTypes.bool.isRequired,
  createXml: PropTypes.func.isRequired,
  hideXmlModal: PropTypes.func.isRequired,
};

AdminShowPartner.defaultProps = {
  partner: null,
  token: null,
  fields: [],
  services: [],
};

const mapStateToProps = ({login: {token}, admin: {selectedPartner, selectedPartnerFields, selectedPartnerServices, isXmlModalVisible, isXmlPartnerVideoModalVisible, selectedXml, isCameraModalVisible, selectedCamera}}) => ({
  token,
  partner: selectedPartner,
  fields: selectedPartnerFields,
  services: selectedPartnerServices,
  isXmlModalVisible,
  isXmlPartnerVideoModalVisible,
  isCameraModalVisible,
  selectedXml,
  selectedCamera,
});

const mapDispatchToProps = dispatch => ({
  updatePartner: partner => {
    dispatch(updatePartnerByAdmin(partner));
  },

  updatePartnerVideoCost: partner => {
    dispatch(updatePartnerVideoCostByAdmin(partner));
  },

  updatePartnerField: field => {
    dispatch(updatePartnerFieldByAdmin(field));
  },

  updatePartnerService: service => {
    dispatch(updatePartnerServiceByAdmin(service));
  },

  newXmlModal: fieldId => {
    dispatch(newXml(fieldId));
    dispatch(showXmlModal());
  },

  newXmlPartnerVideoModal: partnerId => {
    dispatch(newXmlPartnerVideo(partnerId));
    dispatch(showXmlPartnerVideoModal());
  },

  cameraModal: (camera, fieldId) => {
    if(camera === null){
      dispatch(newCamera(fieldId));
    }
    else{
      dispatch(editCamera(camera));
    }
    dispatch(showCameraModal());
  },

  createXml: values => {
    dispatch(saveXml(values));
  },
  
  createPartnerVideoXml: values => {
    dispatch(savePartnerVideoXml(values));
  },

  hideXmlModal: () => {
    dispatch(hideXmlModal());
  },

  hideXmlPartnerVideoModal: () => {
    dispatch(hideXmlPartnerVideoModal());
  },

  hideCameraModal: () => {
    dispatch(hideCameraModal());
  },

  showCameraModal: () => {
    dispatch(hideCameraModal());
  },

  createOrEditCamera: (values, partnerId) => {
    values.format = parseInt(values.format);
    if(!values.id){
      dispatch(createCamera(values, partnerId));
    }
    else{
      dispatch(modifyCamera(values, partnerId));
    }
  },
  deleteCamera: (cameraId, partnerId) => {
    dispatch(deleteCamera(cameraId, partnerId));
  },
  changePartnerPermissions: (values, partnerId) => {
    dispatch(changePartnerPermissions(values, partnerId));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminShowPartner));

/* eslint complexity: ["error", 100] */

const defaultState = {
  partners: [],
  favouritePartners: [],
  bookings: [],
  video: [],
  videoStreaming: [],
  storagePrices: {},
  selectedPartner: null,
  selectedPartnerFields: [],
  selectedPartnerServices: [],
  slots: [],
  bookingSlot: null,
  bookingSlots: null,
  isBookingSlotModalVisible: false,
  isBookingServiceSlotModalVisible: false,
  bookingFieldId: null,
  permanentVideos: [],
  isVideoHighlightModalVisible: false,
  isVideoSharingModalVisible: false,
  selectedPermanentVideo: null,
  sharedVideo: null,
  subscriptions: null,
  selectedSubscription: null,
  isPlayerNewSubscriptionModalVisible: false,
  isPlayerNewEventSubscriptionModalVisible: false,
  isPlayerInsufficientFundsModalVisible: false,
  rtvVideos: [],
  rtvPartnerVideos: [],
  rtvVideosSoon: [],
  videosStore: [],
  videosStoreSoon: [],
  videosStorePartnerVideo: [],
  isPlayerHowPurchaseModalVisible: false,
  permanentVideosPlaying: [],
  newVideosPlaying: [],
  rtvVideosPlaying: [],
  filterBookableFields: false,
  events: [],
  selectedPartnerEvents: [],
  sponsors: [],
  userTags: [],
  isUserTagModalVisible: false,
  selectedUserTag: null,
  myVideoActiveTab: 'video',
  selectedVideoForTagging: null,
  userVideoTags: [],
  isUserVideoTagModalVisible: false,
  selectedUserVideoTag: null,
  taggedUserVideos: [],
  taggedVideosPlaying: [],
  isPlayerChooseRtvChannelModalVisible: false,
  selectedRtvChannel: null,
  favouriteRtvChannels: [],
  isPlayersModalVisible: false,
  playersList: [],
  isStorageModalVisible: false,
  playlists: [],
  isCameraModalVisible: false,
  exportHighlightOrPlaylist : null,
  selectedUserVideoTags: [],
  permanentPartnerVideos: [],
  rtvCategories: [],
  selectedCategory: {},
  isEditPlayersModalVisible: false,
  isLoginModalVisible: false,
  playerVideoStoreActiveTab : 'watch_now',
  liveVideos: [],
  comingSoonVideos: [],
  isBuySubscriptionOrVideoModalVisible: false,
  affiliations: [],
};

const defaultBooking = {

};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PLAYER_PARTNERS_RECEIVED': {
      return Object.assign({}, state, {partners: action.partners});
    }

    case 'PLAYER_BOOKINGS_RECEIVED': {
      return Object.assign({}, state, {bookings: action.bookings});
    }

    case 'PLAYER_PARTNER_SELECTED': {
      return Object.assign({}, state, {selectedPartner: action.partner});
    }

    case 'PLAYER_PARTNER_FIELDS_RECEIVED': {
      return Object.assign({}, state, {selectedPartnerFields: action.partnerFields});
    }

    case 'PLAYER_PARTNER_SERVICES_RECEIVED': {
      return Object.assign({}, state, {selectedPartnerServices: action.partnerServices});
    }

    case 'PLAYER_SLOTS_RECEIVED': {
      return Object.assign({}, state, {slots: action.slots});
    }

    case 'PLAYER_BOOKING_SLOT_SELECTED': {
      return Object.assign({}, state, {bookingSlot: action.bookingSlot, bookingSlots: null});
    }

    case 'PLAYER_BOOKING_MULTI_SLOT_SELECTED': {
      return Object.assign({}, state, {bookingSlots: action.bookingSlots, bookingSlot: null});
    }

    case 'PLAYER_SHOW_BOOKING_SLOT_MODAL': {
      return Object.assign({}, state, {isBookingSlotModalVisible: true});
    }

    case 'PLAYER_HIDE_BOOKING_SLOT_MODAL': {
      return Object.assign({}, state, {isBookingSlotModalVisible: false});
    }

    case 'PLAYER_SHOW_SERVICE_BOOKING_SLOT_MODAL': {
      return Object.assign({}, state, {isBookingServiceSlotModalVisible: true});
    }

    case 'PLAYER_HIDE_SERVICE_BOOKING_SLOT_MODAL': {
      return Object.assign({}, state, {isBookingServiceSlotModalVisible: false});
    }

    case 'PLAYER_BOOKING_FIELD_ID': {
      return Object.assign({}, state, {bookingFieldId: action.bookingFieldId});
    }

    case 'PLAYER_NEW_BOOKING': {
      return Object.assign({}, state, {booking: Object.assign(defaultBooking, {...action.initialValues})});
    }

    case 'PLAYER_BOOKING_SELECTED': {
      return Object.assign({}, state, {booking: action.booking});
    }

    case 'PLAYER_VIDEO_RECEIVED': {
      return Object.assign({}, state, {video: action.video});
    }

    case 'PLAYER_VIDEO_STREAMING_RECEIVED': {
      return Object.assign({}, state, {videoStreaming: action.video});
    }

    case 'PLAYER_PERMANENT_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {permanentVideos: action.videos});
    }

    case 'PLAYER_PERMANENT_PARTNER_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {permanentPartnerVideos: action.videos});
    }

    case 'PLAYER_PLAYLISTS_RECEIVED': {
      return Object.assign({}, state, {playlists: action.playlists});
    }

    case 'PLAYER_STORAGE_PRICES_RECEIVED': {
      return Object.assign({}, state, {storagePrices: action.prices});
    }

    case 'PLAYER_PERMANENT_VIDEO_SELECTED': {
      return Object.assign({}, state, {selectedPermanentVideo: action.selectedPermanentVideo});
    }

    case 'PLAYER_SHOW_VIDEO_HIGHLIGHT_MODAL': {
      return Object.assign({}, state, {isVideoHighlightModalVisible: true});
    }

    case 'PLAYER_HIDE_VIDEO_HIGHLIGHT_MODAL': {
      return Object.assign({}, state, {isVideoHighlightModalVisible: false});
    }

    case 'PLAYER_SHOW_VIDEO_SHARING_MODAL': {
      return Object.assign({}, state, {isVideoSharingModalVisible: true});
    }

    case 'PLAYER_HIDE_VIDEO_SHARING_MODAL': {
      return Object.assign({}, state, {isVideoSharingModalVisible: false});
    }

    case 'PLAYER_FAVOURITE_PARTNERS_RECEIVED': {
      return Object.assign({}, state, {favouritePartners: action.favouritePartners});
    }

    case 'PLAYER_SHARED_VIDEO_RECEIVED': {
      return Object.assign({}, state, {sharedVideo: action.sharedVideo});
    }

    case 'PLAYER_SUBSCRIPTIONS_RECEIVED': {
      return Object.assign({}, state, {subscriptions: action.subscriptions});
    }

    case 'PLAYER_SUBSCRIPTION_SELECTED': {
      return Object.assign({}, state, {selectedSubscription: action.subscription});
    }

    case 'PLAYER_SHOW_NEW_SUBSCRIPTION_MODAL': {
      return Object.assign({}, state, {isPlayerNewSubscriptionModalVisible: true});
    }

    case 'PLAYER_HIDE_NEW_SUBSCRIPTION_MODAL': {
      return Object.assign({}, state, {isPlayerNewSubscriptionModalVisible: false});
    }

    case 'PLAYER_SHOW_NEW_EVENT_SUBSCRIPTION_MODAL': {
      return Object.assign({}, state, {isPlayerNewEventSubscriptionModalVisible: true});
    }

    case 'PLAYER_HIDE_NEW_EVENT_SUBSCRIPTION_MODAL': {
      return Object.assign({}, state, {isPlayerNewEventSubscriptionModalVisible: false});
    }

    case 'PLAYER_RTV_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {rtvVideos: action.rtvVideos, rtvVideosSoon: action.rtvVideosSoon});
    }

    case 'PLAYER_RTV_PARTNER_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {rtvPartnerVideos: action.rtvPartnerVideos});
    }

    case 'PLAYER_SHOW_INSUFFICIENT_FUNDS_MODAL': {
      return Object.assign({}, state, {isPlayerInsufficientFundsModalVisible: true});
    }

    case 'PLAYER_HIDE_INSUFFICIENT_FUNDS_MODAL': {
      return Object.assign({}, state, {isPlayerInsufficientFundsModalVisible: false});
    }

    case 'PLAYER_VIDEOS_STORE_RECEIVED': {
      return Object.assign({}, state, {videosStore: action.videosStore, videosStoreSoon: action.videosStoreSoon});
    }

    case 'PLAYER_PARTNER_VIDEOS_STORE_RECEIVED': {
      return Object.assign({}, state, {videosStorePartnerVideo: action.videosStorePartnerVideo});
    }

    case 'PLAYER_SHOW_HOW_PURCHASE_MODAL': {
      return Object.assign({}, state, {isPlayerHowPurchaseModalVisible: true});
    }

    case 'PLAYER_HIDE_HOW_PURCHASE_MODAL': {
      return Object.assign({}, state, {isPlayerHowPurchaseModalVisible: false});
    }

    case 'PLAYER_ADD_PERMANENT_VIDEO_PLAYING': {
      return Object.assign({}, state, {permanentVideosPlaying: [...state.permanentVideosPlaying, action.videoId]});
    }

    case 'PLAYER_ADD_NEW_VIDEO_PLAYING': {
      return Object.assign({}, state, {newVideosPlaying: [...state.newVideosPlaying, action.videoId]});
    }

    case 'PLAYER_ADD_RTV_VIDEO_PLAYING': {
      return Object.assign({}, state, {rtvVideosPlaying: [...state.rtvVideosPlaying, action.videoId]});
    }

    case 'PLAYER_FILTER_BOOKABLE_FIELDS': {
      return Object.assign({}, state, {filterBookableFields: action.filterBookableFields});
    }

    case 'PLAYER_EVENTS_RECEIVED': {
      return Object.assign({}, state, {events: action.events});
    }

    case 'PLAYER_PARTNER_EVENTS_RECEIVED': {
      return Object.assign({}, state, {selectedPartnerEvents: action.partnerEvents});
    }

    case 'PLAYER_SPONSORS_RECEIVED': {
      return Object.assign({}, state, {sponsors: action.sponsors});
    }

    case 'PLAYER_SHOW_USER_TAG_MODAL': {
      return Object.assign({}, state, {isUserTagModalVisible: true});
    }

    case 'PLAYER_HIDE_USER_TAG_MODAL': {
      return Object.assign({}, state, {isUserTagModalVisible: false});
    }

    case 'PLAYER_SHOW_STORAGE_MODAL': {
      return Object.assign({}, state, {isStorageModalVisible: true});
    }

    case 'PLAYER_HIDE_STORAGE_MODAL': {
      return Object.assign({}, state, {isStorageModalVisible: false});
    }

    case 'PLAYER_USER_TAGS_RECEIVED': {
      return Object.assign({}, state, {userTags: action.userTags});
    }

    case 'PLAYER_NEW_USER_TAG': {
      return Object.assign({}, state, {selectedUserTag: {label: null, duration: 10, color: '#142e7d'}});
    }

    case 'PLAYER_USER_TAG_SELECTED': {
      return Object.assign({}, state, {selectedUserTag: action.selectedUserTag});
    }

    case 'PLAYER_MY_VIDEO_ACTIVE_TAB': {
      return Object.assign({}, state, {myVideoActiveTab: action.activeTab});
    }

    case 'PLAYER_VIDEO_FOR_TAGGING_SELECTED': {
      return Object.assign({}, state, {selectedVideoForTagging: action.video});
    }

    case 'PLAYER_USER_VIDEO_TAGS_RECEIVED': {
      return Object.assign({}, state, {userVideoTags: action.userVideoTags});
    }

    case 'PLAYER_SHOW_USER_VIDEO_TAG_MODAL': {
      return Object.assign({}, state, {isUserVideoTagModalVisible: true});
    }

    case 'PLAYER_HIDE_USER_VIDEO_TAG_MODAL': {
      return Object.assign({}, state, {isUserVideoTagModalVisible: false});
    }

    case 'PLAYER_NEW_USER_VIDEO_TAG': {
      return Object.assign({}, state, {selectedUserVideoTag: {userVideoId: action.videoId, userTagId: action.userTagId, startTime: action.startTime, stopTime: action.stopTime, description: null}});
    }

    case 'PLAYER_USER_VIDEO_TAG_SELECTED': {
      return Object.assign({}, state, {selectedUserVideoTag: action.selectedUserVideoTag});
    }

    case 'PLAYER_TAGGED_USER_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {taggedUserVideos: action.taggedUserVideos});
    }

    case 'PLAYER_ADD_TAGGED_VIDEO_PLAYING': {
      return Object.assign({}, state, {taggedVideosPlaying: [...state.taggedVideosPlaying, action.videoId]});
    }

    case 'ADMIN_STORAGE_PRICES_RECEIVED': {
      return Object.assign({}, state, {storagePrices: action.prices});
    }

    case 'PLAYER_SHOW_CHOOSE_RTV_CHANNEL_MODAL': {
      return Object.assign({}, state, {isPlayerChooseRtvChannelModalVisible: true});
    }

    case 'PLAYER_HIDE_CHOOSE_RTV_CHANNEL_MODAL': {
      return Object.assign({}, state, {isPlayerChooseRtvChannelModalVisible: false});
    }

    case 'PLAYER_RTV_CHANNEL_SELECTED': {
      return Object.assign({}, state, {selectedRtvChannel: action.rtvChannel});
    }

    case 'PLAYER_FAVOURITE_RTV_CHANNELS_RECEIVED': {
      return Object.assign({}, state, {favouriteRtvChannels: action.favouriteRtvChannels});
    }

    case 'PLAYER_SHOW_BOOKING_PLAYERS_MODAL': {
      return Object.assign({}, state, {isPlayersModalVisible: true});
    }

    case 'PLAYER_HIDE_BOOKING_PLAYERS_MODAL': {
      return Object.assign({}, state, {isPlayersModalVisible: false});
    }

    case 'PLAYER_PLAYERS_LIST_RECEIVED': {
      return Object.assign({}, state, {playersList: action.players})
    }

    case 'PLAYER_PLAYERS_CLEAN_LIST_RECEIVED': {
      return Object.assign({}, state, {playersList: []})
    }

    case 'PLAYER_SHOW_CAMERA_MODAL': {
      return Object.assign({}, state, {isCameraModalVisible: true});
    }

    case 'PLAYER_HIDE_CAMERA_MODAL': {
      return Object.assign({}, state, {isCameraModalVisible: false});
    }

    case 'HIGHLIGHT_OR_PLAYLIST_SELECTED': {
      return Object.assign({}, state, {exportHighlightOrPlaylist: action.value})
    }

    case 'PLAYER_SET_SELECTED_USER_VIDEO_TAGS': {
      return Object.assign({}, state, {selectedUserVideoTags: action.tags})
    }

    case 'PLAYER_CLEAR_SELECTED_USER_VIDEO_TAGS': {
      return Object.assign({}, state, {selectedUserVideoTags: []})
    }

    case 'PLAYER_RTV_CATEGORIES_RECEIVED': {
      return Object.assign({}, state, {rtvCategories: action.categories})
    }

    case 'PLAYER_SELECT_RTV_CATEGORY': {
      return Object.assign({}, state, {selectedCategory: action.category});
    }

    case 'PLAYER_SHOW_BOOKING_EDIT_PLAYERS_MODAL': {
      return Object.assign({}, state, {isEditPlayersModalVisible: true});
    }

    case 'PLAYER_HIDE_BOOKING_EDIT_PLAYERS_MODAL': {
      return Object.assign({}, state, {isEditPlayersModalVisible: false});
    }

    case 'PLAYER_SHOW_LOGIN_MODAL': {
      return Object.assign({}, state, {isLoginModalVisible: true});
    }

    case 'PLAYER_HIDE_LOGIN_MODAL': {
      return Object.assign({}, state, {isLoginModalVisible: false});
    }

    case 'PLAYER_VIDEO_STORE_ACTIVE_TAB': {
      return Object.assign({}, state, {playerVideoStoreActiveTab: action.activeTab});
    }

    case 'PLAYER_LIVE_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {liveVideos: action.liveVideos});
    }

    case 'PLAYER_COMING_SOON_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {comingSoonVideos: action.comingSoonVideos});
    }

    case 'PLAYER_SHOW_NEW_SUBSCRIPTION_OR_VIDEO_MODAL': {
      return Object.assign({}, state, {isBuySubscriptionOrVideoModalVisible: true});
    }

    case 'PLAYER_HIDE_NEW_SUBSCRIPTION_OR_VIDEO_MODAL': {
      return Object.assign({}, state, {isBuySubscriptionOrVideoModalVisible: false});
    }

    case 'PLAYER_AFFILIATIONS_RECEIVED': {
      return Object.assign({}, state, {affiliations: action.affiliations});
    }

    default:
      return state;
  }
};

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PlayerBookingCalendar from '../components/player-booking-calendar';
import {push} from 'connected-react-router';
import queryString from 'querystring';
import {addToBookingQueue, filterBookingFieldId, bookingSlotSelected, bookingMultiSlotsSelected, showBookingSlotModal, hideBookingSlotModal, newBooking, saveBooking, bookingSelected, purchaseVideo, acceptPrivacy, getSlots, getVideosStore, getVideosStorePartnerVideo, deleteBooking, setPlayerVideoStoreActiveTab} from '../actions/player';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({player: {filterBookableFields, selectedPartner, selectedPartnerFields, slots, isBookingSlotModalVisible, bookingSlot, bookingSlots, bookingFieldId, bookings, booking, affiliations}, login: {token, profile}}) => ({
  isLogged: token !== null,
  selectedPartner,
  selectedPartnerFields,
  slots,
  isBookingSlotModalVisible,
  bookingSlot,
  bookingSlots,
  bookingFieldId,
  bookings,
  booking,
  profile,
  filterBookableFields,
  affiliations
});

const mapDispatchToProps = dispatch => ({
  bookingSlotSelected: ev => {
    if (new Date(ev.slot.startDatetime) < Date.now()) {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
    } else if (ev.slot.state === 'reserved') {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_RESERVED'));
    }
      else if (ev.slot.state === "unavailable") {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_UNAVAILABLE'));
    } else if (ev.slot.bookingId && ev.booking) {
      dispatch(bookingSlotSelected(ev.slot));
      dispatch(bookingSelected(ev.booking));
      dispatch(showBookingSlotModal());
    } else {
      dispatch(bookingSlotSelected(ev.slot));
      dispatch(newBooking({description: null, payment: false, playersNumber: 1, otherPlayers: [''], slots: [ev.slot.slotId], rtvOrAcademy: false, invitedPlayers: [], invitedGuests: []}));
      dispatch(showBookingSlotModal());
    }
  },
  bookingMultiSlots: slots => {
    dispatch(bookingMultiSlotsSelected(slots));
    dispatch(newBooking({description: null, payment: false, playersNumber: 1, otherPlayers: [''], slots: slots.map(s => s.slotId), rtvOrAcademy: false, invitedPlayers: [], invitedGuests: []}));
    dispatch(showBookingSlotModal());
  },
  addToBookingQueue: slotId => {
    dispatch(addToBookingQueue(slotId));
  },
  hideBookingSlotModal: () => {
    dispatch(hideBookingSlotModal());
  },
  saveBooking: booking => {
    dispatch(saveBooking(booking));
  },
  requestDelete: phoneNumber => {
      dispatch(showMessage('BOOKINGS', 'BOOKING_HOW_CANCEL', {phoneNumber}));
  },
  purchaseVideo: slots => {
    dispatch(purchaseVideo(slots));
  },
  showMessage: (title, body) => {
    dispatch(showMessage(title, body));
  },
  acceptPrivacy: () => {
    dispatch(acceptPrivacy());
  },
  getSlotsByWeek: (partnerId, fieldId) => targetDate => {
    dispatch(getSlots(partnerId, fieldId, targetDate));
  },
  getSlotsByDay: (partnerId, fieldId) => targetDate => {
    dispatch(getSlots(partnerId, fieldId, targetDate, 'field', 'DAY'));
  },
  showVideoStore: (partnerUid, fieldId, fromDatetime, toDatetime) => {
    dispatch(getVideosStore(partnerUid, fieldId, null, fromDatetime, toDatetime));
    dispatch(getVideosStorePartnerVideo(partnerUid));
    const params = Object.fromEntries(Object.entries({fieldId, fromDatetime, toDatetime}).filter(([_, v]) => v !== null));
    if (new Date(fromDatetime) > new Date()) {
      dispatch(setPlayerVideoStoreActiveTab('soon'));
    }
    else{
      dispatch(setPlayerVideoStoreActiveTab('watch_now'));
    }
    dispatch(push(`/player-video-store-partner?${queryString.stringify(params)}`));
  },
  showCalendar: (partnerId, fieldId = null) => () => {
    dispatch(filterBookingFieldId(fieldId));
    dispatch(getSlots(partnerId, fieldId, new Date()));
    dispatch(push('player-booking-calendar'));
  },
  deleteBooking: (bookingId, partnerId, multiBooking) => {
    dispatch(deleteBooking(bookingId, partnerId, multiBooking));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerBookingCalendar));

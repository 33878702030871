import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerProfile from '../components/partner-profile';
import {savePartnerProfile, deletePartnerImageFile, deleteRtvChannelImageFile, refreshPartnerProfile} from '../actions/partner';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({login: {profile, token}, partner: {ownedRtvChannels}}) => ({
  profile,
  token,
  ownedRtvChannels
});

const mapDispatchToProps = dispatch => ({
  save: profile => {
    dispatch(savePartnerProfile(profile));
  },
  removeFile: fileName => {
    dispatch(deletePartnerImageFile(fileName));
  },
  deleteRtvChannelImageFile: (file, rtvChannelId) => {
    dispatch(deleteRtvChannelImageFile(file, rtvChannelId));
  },
  topUpWalletSuccess: () => {
    dispatch(refreshPartnerProfile());
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_SUCCESSFUL'));
  },
  showNotClosePaypalMessage: () => {
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_ORDER_IN_PROGRESS'));
  },
  showInvalidAmountMessage: () => {
    dispatch(showMessage('ACCOUNT_TOPUP', 'MIN_MAX',{min: '5,00 €', max: '9999,00 €'}));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerProfile));

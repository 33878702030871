/* eslint complexity: ["error", 40] */

import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Button, Container, Fade, Form, Tab, Tabs, ToggleButton, ToggleButtonGroup, Modal} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import Dialog from 'react-bootstrap-dialog';
import {FaMapMarkerAlt, FaShieldAlt} from 'react-icons/fa';
import {AiOutlineClockCircle, AiOutlineCalendar} from 'react-icons/ai';
import {Formik} from 'formik';
import PartnerHighLight from './partner-highlight';
import PartnerVideoSharing from './partner-video-sharing';
import {sharingType} from '../constants';
import downloadIcon from '../images/download_icon.svg';
import editIcon from '../images/edit_icon.svg';
import binIcon from '../images/bin_icon.svg';
import privateIcon from '../images/user_icon_private_red.svg';
import publicIcon from '../images/user_icon_public_red.svg';
import replayerIcon from '../images/user_icon_replayer_red.svg';
import Configuration from '../config';
import playArrowWhite from '../images/play_arrow_white_48dp.png';
import replayerlogoBianco from '../images/replayer_logo_bianco_40x32px.png';
import noLogo from '../images/club_icon_grey.svg';
import {useTranslation, Trans} from 'react-i18next';
import PartnerTags from './partner-tags';
import PartnerVideoTagging from './partner-video-tagging';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {imageTypes} from '../constants';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import defaultAvatar from '../images/Replayer_default_Avatar.png'

const PartnerVideo = ({
  token,
  profile,
  permanentVideos,
  storagePrices,
  selectVideoForHighlight,
  selectedPermanentVideo,
  deletePartnerPermanentVideo,
  deletePlaylist,
  modifyPartnerPermanentVideo,
  modifyPlaylist,
  showVideosSharing,
  showPartnerTags,
  userTags,
  myVideoActiveTab,
  setActiveTab,
  showVideoTagging,
  selectedVideoForTagging,
  searchTaggedVideo,
  taggedUserVideos,
  changeStorageType,
  isStorageModalVisible,
  hideStorageModal,
  showStorageModal,
  isPartnerVideoCreationVisible,
  hidePartnerVideoCreationModal,
  showPartnerVideoCreationModal,
  playlists,
  createPartnerVideo,
  deletePartnerVideo,
  partnerVideos,
  showPartnerVideoEditModal,
  hidePartnerVideoEditModal,
  isPartnerVideoEditModalVisible,
  selectedPartnerVideo,
  editPartnerVideo,
  removeFile,
  showPlayersModal,
  hidePlayersModal,
  isPlayersModalVisible,
  searchPlayers,
  playersList,
  goto,
  showVideosStorePartner,
  sendUsageData,
}) => {
  const [value, setValue] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  });

  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const getScrollPage = () => {
    let docScrollTop = 0;
    if (document.documentElement && document.documentElement !== null) {
      docScrollTop = document.documentElement.scrollTop;
    }
    return window.pageYOffset || docScrollTop;
  };

  let dialog = null;
  const confirmDeleteVideo = videoId => () => {
    dialog.show({
      title: t('CONFIRM_DELETE_VIDEO'),
      body: t('CONFIRM_DELETE_VIDEO_DESC'),
      actions: [
        Dialog.Action(
          t('YES'),
          () => deletePartnerPermanentVideo(videoId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const confirmDeletePartnerVideo = videoId => () => {
    dialog.show({
      title: t('CONFIRM_DELETE_VIDEO'),
      body: <Trans i18nKey='CONFIRM_DELETE_PARTNER_VIDEO_DESC'/>,
      actions: [
        Dialog.Action(
          t('YES'),
          () => deletePartnerVideo(videoId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const confirmDeletePlaylist = playlistId => () => {
    dialog.show({
      title: t('CONFIRM_DELETE_VIDEO'),
      body: t('CONFIRM_DELETE_VIDEO_DESC'),
      actions: [
        Dialog.Action(
          t('YES'),
          () => deletePlaylist(playlistId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };
  
  const confirmDownloadVideo = video => () => {
    dialog.show({
      title: t('CONFIRM_DOWNLOAD_VIDEO'),
      body: <Fragment>
              <Form.Group>
                {t('CONFIRM_DOWNLOAD_VIDEO_DESC', {name: video.name})}
              </Form.Group>
              <Form.Group>
              {video.urls.map(url =>(
                <div style={{paddingTop: 20}}>
                  <a href={url.url} download={video.name.split('.').join('') + '_' + url.cameraName} class="btn btn-secondary">{t('DOWNLOAD') + " " + (video.urls.length > 1 ? url.cameraName : "")}</a>
                </div>
                ))}
              </Form.Group>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const metadataVideo = video => () => {
    dialog.show({
      title: t('VIDEO_METADATA'),
      body: <Fragment>
              <Form.Group>
                {t('VIDEO_METADATA_DESC')}
              </Form.Group>
              <Form.Group>
                <Form.Label><FaMapMarkerAlt size="1.5em" style={{marginRight: 10}}/>{video.city}</Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label><FaShieldAlt size="1.5em" style={{marginRight: 10}}/>{video.partnerName}, {video.fieldName}</Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label><AiOutlineCalendar size="1.5em" style={{marginRight: 10}}/>{new Date(video.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label><AiOutlineClockCircle size="1.5em" style={{marginRight: 10}}/>Dalle ore {new Date(video.startDatetime).toLocaleTimeString(i18n.language).slice(0, 5)} alle ore {new Date(video.endDatetime).toLocaleTimeString(i18n.language).slice(0, 5)}</Form.Label>
              </Form.Group>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const uplodInfoDialog = () => {
    dialog.show({
      title: t('UPLOAD_INFO'),
      body: t('UPLOAD_VIDEO_SPECS'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const getPartnerLogo = () => {
    if (profile && profile.storeImage && profile.storeImage.length > 0) {
      return `${Configuration.apiEndPoint}/images/${profile.uid}?load=${profile.storeImage[0]}`;
    }

    return noLogo;
  };

  const styleBackgroundImage = (partnerId, fieldId, fieldImage) => {
    if (partnerId && fieldImage) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage})`, backgroundSize: 'cover'};
    }

    return null;
  };

  const styleBackgroundImageVideoPartner = (coverImage, defaultImage, videoId) => {
    if (coverImage && coverImage[0]) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/video_partners/${videoId}/images?load=${coverImage[0]}`, backgroundSize: 'cover'};
    }
    if (defaultImage && defaultImage[0]) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/images/${profile.uid}?load=${defaultImage[0]}`, backgroundSize: 'cover'};
    }

    return null;
  };

  const handleTagSelection = val => {
    setValue(val);
    searchTaggedVideo(val);
  };

  const storageLimit = {
    xs: 20,
    s: 30,
    m: 50,
    l: 100,
    xl: 200,
    xxl: 500,
    xxxl: 1000,
  };

  const usageTypes = {
    replayer_video: 1,
    partner_video: 2,
    highlight: 3,
    playlist: 4,
  };


  const onUpload = id => () => {
    window.location.href = "/upload?video_id=" + id;
  };

  const renderPlayer = (players) => {
    return(
      <div>
        <ul class="list-group list-group-light mb-4">
          {
            players.map( (p, index) =>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  {p.image && p.image[0] ?
                  <img
                    className="img-fluid"
                    src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.image[0]}`}
                    alt={t('PROFILE_IMAGE')}
                    style={{width: 45, height: 45, paddingLeft:0}}
                    class="rounded-circle"
                  />
                  :
                  <img
                    className="img-fluid"
                    src={defaultAvatar}
                    alt={t('PROFILE_IMAGE')}
                    style={{width: 45, height: 45, paddingLeft:0}}
                    class="rounded-circle"
                  />
                  }
                  <div class="ms-3">
                    <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.name + " " + p.surname}</p>
                    <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.nickname}</p>
                  </div>
                </div>
                <li className="card_video_archive_list_icons_item"><a href="#" onClick={() => players.splice(index, 1)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
              </li>
            )
          }
        </ul>
      </div>
    )
  }

  const renderPlayerSearch = player => {
    return(
      <div key={player.uid}>
        <ul class="list-group list-group-light mb-4">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              {player && player.image && player.image[0] ?
              <img
                className="img-fluid"
                src={`${Configuration.apiEndPoint}/images/${player.uid}?load=${player.image[0]}`}
                alt={t('PROFILE_IMAGE')}
                style={{width: 45, height: 45, paddingLeft:0}}
                class="rounded-circle"
              />
              :
              <img
                className="img-fluid"
                src={defaultAvatar}
                alt={t('PROFILE_IMAGE')}
                style={{width: 45, height: 45, paddingLeft:0}}
                class="rounded-circle"
              />
              }
              <div class="ms-3">
                <p class="fw-bold mb-1" style={{marginLeft: 10}}>{player.name + " " + player.surname}</p>
                <p class="fw-bold mb-1" style={{marginLeft: 10}}>{player.nickname + (player.groupType ? " - " + player.groupType : "")}</p>
              </div>
            </div>
            <Form.Check required name="selectedPlayer" type="radio" onChange={() => setSelectedPlayer(player)}/>
          </li>
        </ul>
      </div>
    )
  }

  const showPartnerVideoChangeModal = v => {
    setSelectedPlayers(v.selectedPlayers);
    showPartnerVideoEditModal(v);
  }

  const playPartnerVideo = v => () => {
    if (v.videoName){
      sendUsageData(usageTypes['partner_video'], v.partnerId, null, v.title, v.videoName); 
      window.open(`${Configuration.krpanoPlayerUrl}?videoPartnerId=${v.videoId}&token=${v.token}`, '_blank');
    }
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp" style={{minHeight: 800}}>
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <h4 className="section_title_neg_rp">{t('MY_VIDEO')}</h4>
                    <br/>
                    <h4 className="section_title_neg_rp">{(Math.floor(profile.videoQuota)/(1000000000)).toFixed(2)}GB/{storageLimit[profile.storageType]}GB</h4>
                  </div>
                  <div className="col-md-12 ml-auto mr-auto" style={{textAlign: 'right', marginTop: -42}}>
                    <a href="#" className="btn btn_red_rp mb-3 ml-1 mr-1" onClick={showStorageModal}>{t('MANAGE_SPACE')}</a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <Tabs activeKey={myVideoActiveTab} className="myvideo-tabs" onSelect={t => setActiveTab(t)}>
                      <Tab eventKey="highlight" title={t('HIGHLIGHT')} tabClassName="myvideo-tab">
                        <div className="row" style={{marginTop: 50}}>
                          {
                            permanentVideos.filter(v => v.highlight).map(v =>
                              (
                                <div key={v.slotId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                  <div className="card video_card_bg_rp">
                                    <div className="video_card_image" style={styleBackgroundImage(v.partnerId, v.fieldId, v.fieldImage)}>
                                      <div className="videoWrapper" onClick={() => {sendUsageData(usageTypes['highlight'], v.partnerId, v.slotId); window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&videoId=${v.videoId}&token=${v.token}`, '_blank');}}>
                                        <Fragment>
                                          <div className="preload h-100 justify-content-center align-items-center">
                                            <img className="play_arrow" src={playArrowWhite}/>
                                          </div>
                                          <div className="preload h-100 justify-content-start align-items-start">
                                            <img className="logo-replayer" src={replayerlogoBianco}/>
                                          </div>
                                          <div className="preload h-100 justify-content-end align-items-start">
                                            <img className="logo-partner" src={getPartnerLogo()}/>
                                          </div>
                                          <div className="preload h-100 justify-content-center align-items-end">
                                            <div className="video-description justify-content-center align-items-center">{v.name.substring(0,60)}</div>
                                          </div>
                                        </Fragment>
                                      </div>
                                    </div>
                                    <Formik
                                      enableReinitialize
                                      initialValues={{videoId: v.videoId, name: v.name, description: v.description, renaming: false}}
                                      onSubmit={values => modifyPartnerPermanentVideo(values.videoId, {name: values.name, description: values.description})}
                                    >
                                      {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        setFieldValue
                                      }) => {
                                        const rename = formData => {
                                          handleSubmit(formData);
                                          setFieldValue('renaming', false);
                                        };

                                        const setRenameAndScroll = () => {
                                          setScrollPosition(getScrollPage());
                                          setFieldValue('renaming', true);
                                        };

                                        if (values.renaming) {
                                          return (
                                            <div className="card-body d-flex flex-column">
                                              <Form onSubmit={rename}>
                                                <Form.Group>
                                                  <Form.Label>{t('TITLE')}*</Form.Label>
                                                  <Form.Control required name="name" type="text" placeholder={t('TITLE')} maxLength={255} value={values.name || ''} onChange={handleChange}/>
                                                </Form.Group>
                                                <Form.Group>
                                                  <Form.Label>{t('DESCRIPTION')}</Form.Label>
                                                  <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                                                </Form.Group>
                                                <div className="d-flex flex-row-reverse">
                                                  <a className="btn btn_red_outline_rp ml-1" onClick={() => setFieldValue('renaming', false)}>{t('CANCEL')}</a>
                                                  <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                                                </div>
                                              </Form>
                                            </div>
                                          );
                                        }

                                        return (
                                          <Fragment>
                                            <div className="card-body d-flex flex-column">
                                              <h5 className="card-title">{v.name}</h5>
                                              <div className="video_card_star_btn_active" onClick={metadataVideo(v)}/>
                                              <ul className="list-unstyled">
                                                <li>{v.description}</li>
                                              </ul>
                                            </div>
                                            <div className="card_border_bottom_white text-center mt-auto mb-3">
                                              <button type="button" className="btn btn_blue_rp mb-3 ml-1 mr-1" onClick={showVideosSharing(v)}>{t('SHARE')}</button>
                                              <a href="#" className="btn btn_red_rp mb-3 ml-1 mr-1" onClick={selectVideoForHighlight(v)}>{t('HIGHLIGHT')}</a>
                                            </div>
                                            <ul className="card_video_archive_list_icons mb-3">
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDownloadVideo(v)}><img src={downloadIcon} alt={t('ICON_DOWNLOAD')}/></a></li>
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={setRenameAndScroll}><img src={editIcon} alt={t('ICON_CHANGE')}/></a></li>
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeleteVideo(v.videoId)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
                                              {
                                                v.sharing === sharingType.none ?
                                                  <li className="card_video_archive_list_icons_item d-block" onClick={showVideosSharing(v)}><img src={privateIcon} alt={t('ICON_USER_PRIVATE')}/><br/>{t('PRIVATE')}</li> :
                                                  null
                                              }
                                              {
                                                v.sharing === sharingType.public ?
                                                  <li className="card_video_archive_list_icons_item d-block" onClick={showVideosSharing(v)}><img src={publicIcon} alt={t('ICON_USER_PUBLIC')}/><br/>{t('PUBLIC')}</li> :
                                                  null
                                              }
                                              {
                                                v.sharing === sharingType.replayer ?
                                                  <li className="card_video_archive_list_icons_item d-block" onClick={showVideosSharing(v)}><img src={replayerIcon} alt={t('ICON_USER_REPLAYER')}/><br/>{t('REPLAYER_USERS')}</li> :
                                                  null
                                              }
                                              <p style={{textAlign: 'right', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap'}}>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB</p>
                                            </ul>
                                          </Fragment>
                                        );
                                      }}
                                    </Formik>
                                  </div>
                                </div>
                              ))
                          }
                          <div className="col-md-12 col-lg-4 mb-sm-4 card-group">
                            <div className="empty_slot card video_card_bg_rp">
                              <div className="card_image_rp">
                                <div className="videoWrapper"/>
                              </div>
                              <div className="card-body d-flex flex-column">
                                <h5 className="card-title">{t('ADD_NEW_HIGHLIGHT_HERE')}</h5>
                                <p>
                                  {t('HIGH_DEFINITION_HIGHLIGHT_SHOOTING_FIELDS')}
                                </p>
                                <div className="text-center mt-auto">
                                  <a href="#" className="btn btn_red_rp mb-2" onClick={() => setActiveTab('video')}>{t('MY_VIDEO')}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="video" title={t('REPLAYER_VIDEOS')} tabClassName="myvideo-tab">
                        <div className="row" style={{marginTop: 50}}>
                          {
                            permanentVideos.filter(v => !v.highlight).map(v =>
                              (
                                <div key={v.slotId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                  <div className="card video_card_bg_rp">
                                    <div className="video_card_image" style={styleBackgroundImage(v.partnerId, v.fieldId, v.fieldImage)}>
                                      <div className="videoWrapper" onClick={() => {sendUsageData(usageTypes['replayer_video'], v.partnerId, v.slotId); window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&token=${v.token}`, '_blank');}}>
                                        <Fragment>
                                          <div className="preload h-100 justify-content-center align-items-center">
                                            <img className="play_arrow" src={playArrowWhite}/>
                                          </div>
                                          <div className="preload h-100 justify-content-start align-items-start">
                                            <img className="logo-replayer" src={replayerlogoBianco}/>
                                          </div>
                                          <div className="preload h-100 justify-content-end align-items-start">
                                            <img className="logo-partner" src={getPartnerLogo()}/>
                                          </div>
                                          <div className="preload h-100 justify-content-center align-items-end">
                                            <div className="video-description justify-content-center align-items-center">{v.name.substring(0,60)}</div>
                                          </div>
                                        </Fragment>
                                      </div>
                                    </div>
                                    <Formik
                                      enableReinitialize
                                      initialValues={{videoId: v.videoId, name: v.name, description: v.description, renaming: false}}
                                      onSubmit={values => modifyPartnerPermanentVideo(values.videoId, {name: values.name, description: values.description})}
                                    >
                                      {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        setFieldValue
                                      }) => {
                                        const rename = formData => {
                                          handleSubmit(formData);
                                          setFieldValue('renaming', false);
                                        };

                                        const setRenameAndScroll = () => {
                                          setScrollPosition(getScrollPage());
                                          setFieldValue('renaming', true);
                                        };

                                        if (values.renaming) {
                                          return (
                                            <div className="card-body d-flex flex-column">
                                              <Form onSubmit={rename}>
                                                <Form.Group>
                                                  <Form.Label>{t('TITLE')}*</Form.Label>
                                                  <Form.Control required name="name" type="text" placeholder={t('TITLE')} maxLength={255} value={values.name || ''} onChange={handleChange}/>
                                                </Form.Group>
                                                <Form.Group>
                                                  <Form.Label>{t('DESCRIPTION')}</Form.Label>
                                                  <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                                                </Form.Group>
                                                <div className="d-flex flex-row-reverse">
                                                  <a className="btn btn_red_outline_rp ml-1" onClick={() => setFieldValue('renaming', false)}>{t('CANCEL')}</a>
                                                  <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                                                </div>
                                              </Form>
                                            </div>
                                          );
                                        }

                                        return (
                                          <Fragment>
                                            <div className="card-body d-flex flex-column">
                                              <h5 className="card-title">{v.name}</h5>
                                              <div className="video_card_star_btn_active" onClick={metadataVideo(v)}/>
                                              <ul className="list-unstyled">
                                                <li>{v.description}</li>
                                              </ul>
                                            </div>
                                            <div className="card_border_bottom_white text-center mt-auto mb-3">
                                              <button type="button" className="btn btn_blue_rp mb-3 ml-1 mr-1" onClick={showVideoTagging(v)}>{t('VIDEO_TAG_CREATE')}</button>
                                              <a href="#" className="btn btn_red_rp mb-3 ml-1 mr-1" onClick={selectVideoForHighlight(v)}>{t('HIGHLIGHT')}</a>
                                            </div>
                                            <ul className="card_video_archive_list_icons mb-3">
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDownloadVideo(v)}><img src={downloadIcon} alt={t('ICON_DOWNLOAD')}/></a></li>
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={setRenameAndScroll}><img src={editIcon} alt={t('ICON_CHANGE')}/></a></li>
                                              <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeleteVideo(v.videoId)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
                                              <p style={{textAlign: 'right', paddingRight: 10, paddingTop: 10}}>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB</p>
                                            </ul>
                                          </Fragment>
                                        );
                                      }}
                                    </Formik>
                                  </div>
                                </div>
                              ))
                          }
                          <div className="col-md-12 col-lg-4 mb-sm-4 card-group">
                            <div className="empty_slot card video_card_bg_rp">
                              <div className="card_image_rp">
                                <div className="videoWrapper"/>
                              </div>
                              <div className="card-body d-flex flex-column">
                                <h5 className="card-title">{t('ADD_NEW_VIDEO_HERE')}</h5>
                                <p>
                                  {t('HIGH_DEFINITION_VIDEO_SHOOTING_FIELDS')}
                                </p>
                                <div className="text-center mt-auto">
                                  <a href="#" className="btn btn_red_rp mb-2" onClick={goto('partner-replayer-tv-home')} style={{marginRight: 10}}>{t('REPLAYER_TV')}</a>
                                  <a href="#" className="btn btn_red_rp mb-2" onClick={goto('partner-video-store', showVideosStorePartner)}>{t('VIDEO_STORE')}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="partner_videos" title={t('PARTNER_VIDEOS')} tabClassName="myvideo-tab">
                        <div className="container pt-5">
                          <div className="row mb-4">
                            <div className="col-12">
                                <div className="d-flex" style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                                  <div className="ml-auto d-xs-block"><Button variant="primary" onClick={showPartnerVideoCreationModal}>{t('ADD_VIDEO')}</Button></div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: 50}}>
                        {
                          partnerVideos.map(v =>
                            (
                              <div key={v.videoId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                <div className="card video_partner_card_bg_rp">
                                  <div className="video_card_image" style={styleBackgroundImageVideoPartner(v.coverImage, profile.videoImage, v.videoId)}>
                                    <div className="videoWrapper" onClick={playPartnerVideo(v)}>
                                      <Fragment>
                                        {v.videoName ?
                                        <div className="preload h-100 justify-content-center align-items-center">
                                          <img className="play_arrow" src={playArrowWhite}/>
                                        </div>
                                        : null}
                                        <div className="preload h-100 justify-content-start align-items-start">
                                          <img className="logo-replayer" src={replayerlogoBianco}/>
                                        </div>
                                        <div className="preload h-100 justify-content-end align-items-start">
                                          <img className="logo-partner" src={getPartnerLogo()}/>
                                        </div>
                                        <div className="preload h-100 justify-content-center align-items-end">
                                          <div className="video-description justify-content-center align-items-center">{v.title}</div>
                                        </div>
                                      </Fragment>
                                    </div>
                                  </div>
                                  <Fragment>
                                    <div className="card-body d-flex flex-column">
                                      <h5 className="card-title">{v.title}</h5>
                                      <ul className="list-unstyled">
                                        <li>{v.description}</li>
                                      </ul>
                                    </div>
                                    <div className="card_border_bottom_red mt-auto mb-3" style={{paddingLeft: 20, paddingRight: 20}}>
                                    {v.videoName ?
                                    <ul className="list-unstyled">
                                        <li>{t('FILE_NAME')}{": " + v.uploadFilename}</li>
                                        <li>{t('UPLOAD_DATE')}{": " + v.uploadDate.replace(/T.*/,'').split('-').reverse().join('/')}</li>
                                        <li>{t('VIDEO_COST')}{": " + v.videoCost.toFixed(2).replace('.',',') + " €"}</li>
                                        <li>{t('VIDEO_PRICE')}{": " + v.videoPrice.toFixed(2).replace('.',',') + " €"}</li>
                                        <li>{t('VIDEO_DURATION')}{": " + v.duration_minutes + ":" + String(Math.floor(v.duration_seconds)).padStart(2,'0')}</li>
                                        <li style={{color: v.public !== null ? '#dd004a' : '#C9CED4'}}>{v.public ? t('VIDEO_FOR_SALE_PUBLIC') : v.public === null ? t('VIDEO_NOT_FOR_SALE') : t('VIDEO_FOR_SALE_PRIVATE')}</li>
                                        {profile.rtvChannels.filter(c => c.type === 1).length ?
                                        <li style={{color: v.rtv ? '#dd004a' : '#C9CED4'}}>{v.rtv ? t('VIDEO_ON_RTV') : t('VIDEO_NOT_ON_RTV')}</li>
                                        : null}
                                      </ul>
                                    : null}
                                    </div>
                                    <ul className="card_video_archive_list_icons mb-3" style={{display: 'flex', justifyContent: 'space-between'}}>
                                      <div>
                                      <li className="card_video_archive_list_icons_item"><a href="#" onClick={() => showPartnerVideoChangeModal(v)}><img src={editIcon} alt={t('ICON_CHANGE')}/></a></li>
                                      <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeletePartnerVideo(v.videoId)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
                                      </div>
                                      {v.videoName ?
                                      <p style={{textAlign: 'right', paddingRight: 10, paddingTop: 10}}>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB</p>
                                      :
                                      <Button variant="warning" onClick={onUpload(v.videoId)}>
                                        {t('VIDEO_UPLOAD')}
                                      </Button>
                                      }
                                      </ul>
                                  </Fragment>
                                </div>
                              </div>
                            ))
                          }
                          <div className="col-md-12 col-lg-4 mb-sm-4 card-group">
                              <div className="empty_slot card video_card_bg_rp">
                                <div className="card_image_rp">
                                  <div className="videoWrapper"/>
                                </div>
                                <div className="card-body d-flex flex-column">
                                  <h5 className="card-title">{t('ADD_NEW_VIDEO_HERE')}</h5>
                                  <p>
                                    {t('PARTNER_VIDEOS_INFO')}
                                  </p>
                                  <div className="text-center mt-auto">
                                    <a href="#" className="btn btn_red_rp mb-2" onClick={uplodInfoDialog}>{t('UPLOAD_INFO')}</a>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="tags" title={t('TAGS')} tabClassName="myvideo-tab">
                        {
                          selectedVideoForTagging ?
                            <PartnerVideoTagging/> :
                            <div className="container pt-5">
                              <div className="row mb-4">
                                <div className="col-12">
                                  <div>
                                    <div className="d-flex" style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                                      <div><h4 className="section_title_neg_rp">{t('TAG_SELECT')}</h4></div>
                                      <div className="ml-auto d-xs-block"><Button variant="secondary" onClick={showPartnerTags}>{t('TAG_CREATE_OR_UPDATE')}</Button></div>
                                    </div>
                                    <hr className="hr_blue"/>
                                    <div className="row">
                                      <div className="col-lg-12 mt-sm-2">
                                        {userTags.length > 0 ?
                                          <p>{t('TAG_INFO')}</p>
                                          : <p>{t('NO_TAG_CREATED')}</p>
                                        }
                                        <h3>
                                          <ToggleButtonGroup type="checkbox" className="flex-wrap" onChange={handleTagSelection}>
                                            {userTags.map(t => <ToggleButton key={t.id} style={{fontFamily:"Arial", margin: 2, backgroundColor: t.color, borderColor: t.color, marginRight: 5, opacity: value && value.includes(t.id) ? 1 : 0.5}} value={t.id}>{t.label}</ToggleButton>)}
                                          </ToggleButtonGroup>
                                        </h3>
                                        {userTags.length > 0 && taggedUserVideos.length === 0 ?
                                          <p>{t('NO_VIDEO_TAGGED')}</p>
                                        : null}
                                      </div>
                                    </div>
                                    <div className="row" style={{marginTop: 50}}>
                                      {
                                        taggedUserVideos.map(v =>
                                          (
                                            <div key={v.slotId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                              <div className="card video_card_bg_rp">
                                                <div className="video_card_image" style={styleBackgroundImage(v.partnerId, v.fieldId, v.fieldImage)}>
                                                  <div className="videoWrapper" onClick={showVideoTagging(v)}>
                                                    <Fragment>
                                                      <div className="preload h-100 justify-content-start align-items-start">
                                                        <img className="logo-replayer" src={replayerlogoBianco}/>
                                                      </div>
                                                      <div className="preload h-100 justify-content-end align-items-start">
                                                        <img className="logo-partner" src={getPartnerLogo()}/>
                                                      </div>
                                                      <div className="preload h-100 justify-content-center align-items-end">
                                                        <div className="video-description justify-content-center align-items-center">{v.name.substring(0,60)}</div>
                                                      </div>
                                                    </Fragment>
                                                  </div>
                                                </div>
                                                <Formik
                                                  enableReinitialize
                                                  initialValues={{videoId: v.videoId, name: v.name, description: v.description, renaming: false}}
                                                  onSubmit={values => modifyPartnerPermanentVideo(values.videoId, {name: values.name, description: values.description})}
                                                >
                                                  {({
                                                    handleSubmit,
                                                    handleChange,
                                                    values,
                                                    setFieldValue
                                                  }) => {
                                                    const rename = formData => {
                                                      handleSubmit(formData);
                                                      setFieldValue('renaming', false);
                                                    };

                                                    const setRenameAndScroll = () => {
                                                      setScrollPosition(getScrollPage());
                                                      setFieldValue('renaming', true);
                                                    };            

                                                    if (values.renaming) {
                                                      return (
                                                        <div className="card-body d-flex flex-column">
                                                          <Form onSubmit={rename}>
                                                            <Form.Group>
                                                              <Form.Label>{t('TITLE')}*</Form.Label>
                                                              <Form.Control required name="name" type="text" placeholder={t('TITLE')} maxLength={255} value={values.name || ''} onChange={handleChange}/>
                                                            </Form.Group>
                                                            <Form.Group>
                                                              <Form.Label>{t('DESCRIPTION')}</Form.Label>
                                                              <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                                                            </Form.Group>
                                                            <div className="d-flex flex-row-reverse">
                                                              <a className="btn btn_red_outline_rp ml-1" onClick={() => setFieldValue('renaming', false)}>{t('CANCEL')}</a>
                                                              <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                                                            </div>
                                                          </Form>
                                                        </div>
                                                      );
                                                    }

                                                    return (
                                                      <Fragment>
                                                        <div className="card-body d-flex flex-column">
                                                          <h5 className="card-title">{v.name}</h5>
                                                          <div className="video_card_star_btn_active" onClick={metadataVideo(v)}/>
                                                          <ul className="list-unstyled">
                                                            <li>{v.description}</li>
                                                          </ul>
                                                        </div>
                                                        <div className="card_border_bottom_white text-center mt-auto mb-3">
                                                          <button type="button" className="btn btn_blue_rp mb-3 ml-1 mr-1" onClick={showVideoTagging(v)}>{t('VIDEO_TAG_CREATE')}</button>
                                                          <a href="#" className="btn btn_red_rp mb-3 ml-1 mr-1" onClick={selectVideoForHighlight(v)}>{t('HIGHLIGHT')}</a>
                                                        </div>
                                                        <ul className="card_video_archive_list_icons mb-3">
                                                          <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDownloadVideo(v)}><img src={downloadIcon} alt={t('ICON_DOWNLOAD')}/></a></li>
                                                          <li className="card_video_archive_list_icons_item"><a href="#" onClick={setRenameAndScroll}><img src={editIcon} alt={t('ICON_CHANGE')}/></a></li>
                                                          <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeleteVideo(v.videoId)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
                                                          <p style={{textAlign: 'right', paddingRight: 10, paddingTop: 10}}>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB</p>
                                                        </ul>
                                                      </Fragment>
                                                    );
                                                  }}
                                                </Formik>
                                              </div>
                                            </div>
                                          ))
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        }
                        <PartnerTags/>
                      </Tab>
                      <Tab eventKey="playlist" title={'Playlist'} tabClassName="myvideo-tab">
                        <div className="row" style={{marginTop: 50}}>
                        {
                          playlists.map(v =>
                            (
                              <div key={v.slotId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                <div className="card video_card_bg_rp">
                                  <div className="video_card_image" style={styleBackgroundImage(v.partnerId, v.fieldId, v.fieldImage)}>
                                    <div className="videoWrapper" onClick={() => {sendUsageData(usageTypes['playlist'], v.partnerId, v.slotId); window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&playlistId=${v.id}&token=${v.token}`, '_blank');}}>
                                      <Fragment>
                                        <div className="preload h-100 justify-content-center align-items-center">
                                          <img className="play_arrow" src={playArrowWhite}/>
                                        </div>
                                        <div className="preload h-100 justify-content-start align-items-start">
                                          <img className="logo-replayer" src={replayerlogoBianco}/>
                                        </div>
                                        <div className="preload h-100 justify-content-end align-items-start">
                                          <img className="logo-partner" src={getPartnerLogo()}/>
                                        </div>
                                        <div className="preload h-100 justify-content-center align-items-end">
                                          <div className="video-description justify-content-center align-items-center">{v.name.substring(0,60)}</div>
                                        </div>
                                      </Fragment>
                                    </div>
                                  </div>
                                  <Formik
                                    enableReinitialize
                                    initialValues={{id: v.id, name: v.name, description: v.description, renaming: false}}
                                    onSubmit={values => modifyPlaylist(values.id, {name: values.name, description: values.description})}
                                  >
                                    {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      setFieldValue
                                    }) => {
                                      const rename = formData => {
                                        handleSubmit(formData);
                                        setFieldValue('renaming', false);
                                      };

                                      const setRenameAndScroll = () => {
                                        setScrollPosition(getScrollPage());
                                        setFieldValue('renaming', true);
                                      };

                                      if (values.renaming) {
                                        return (
                                          <div className="card-body d-flex flex-column">
                                            <Form onSubmit={rename}>
                                              <Form.Group>
                                                <Form.Label>{t('TITLE')}*</Form.Label>
                                                <Form.Control required name="name" type="text" placeholder={t('TITLE')} maxLength={255} value={values.name || ''} onChange={handleChange}/>
                                              </Form.Group>
                                              <Form.Group>
                                                <Form.Label>{t('DESCRIPTION')}</Form.Label>
                                                <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                                              </Form.Group>
                                              <div className="d-flex flex-row-reverse">
                                                <a className="btn btn_red_outline_rp ml-1" onClick={() => setFieldValue('renaming', false)}>{t('CANCEL')}</a>
                                                <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                                              </div>
                                            </Form>
                                          </div>
                                        );
                                      }

                                      return (
                                        <Fragment>
                                          <div className="card-body d-flex flex-column">
                                            <h5 className="card-title">{v.name}</h5>
                                            <div className="video_card_star_btn_active" onClick={metadataVideo(v)}/>
                                            <ul className="list-unstyled">
                                              <li>{v.description}</li>
                                            </ul>
                                          </div>
                                          <div className="card_border_bottom_white text-center mt-auto mb-3">
                                            <button type="button" className="btn btn_blue_rp mb-3 ml-1 mr-1" onClick={showVideosSharing(v)}>{t('SHARE')}</button>
                                          </div>
                                          <ul className="card_video_archive_list_icons mb-3">
                                            <li className="card_video_archive_list_icons_item"><a href="#" onClick={setRenameAndScroll}><img src={editIcon} alt={t('ICON_CHANGE')}/></a></li>
                                            <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeletePlaylist(v.id)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
                                            {
                                              v.sharing === sharingType.none ?
                                                <li className="card_video_archive_list_icons_item d-block" onClick={showVideosSharing(v)}><img src={privateIcon} alt={t('ICON_USER_PRIVATE')}/><br/>{t('PRIVATE')}</li> :
                                                null
                                            }
                                            {
                                              v.sharing === sharingType.public ?
                                                <li className="card_video_archive_list_icons_item d-block" onClick={showVideosSharing(v)}><img src={publicIcon} alt={t('ICON_USER_PUBLIC')}/><br/>{t('PUBLIC')}</li> :
                                                null
                                            }
                                            {
                                              v.sharing === sharingType.replayer ?
                                                <li className="card_video_archive_list_icons_item d-block" onClick={showVideosSharing(v)}><img src={replayerIcon} alt={t('ICON_USER_REPLAYER')}/><br/>{t('REPLAYER_USERS')}</li> :
                                                null
                                            }
                                            <p style={{textAlign: 'right', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap'}}>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB</p>
                                          </ul>
                                        </Fragment>
                                      );
                                    }}
                                  </Formik>
                                </div>
                              </div>
                            ))
                        }
                        <div className="col-md-12 col-lg-4 mb-sm-4 card-group">
                          <div className="empty_slot card video_card_bg_rp">
                            <div className="card_image_pl">
                              <div className="videoWrapper"/>
                            </div>
                            <div className="card-body d-flex flex-column">
                              <h5 className="card-title">{t('ADD_NEW_PLAYLIST_HERE')}</h5>
                              <p>
                                {t('PLAYLIST_INFO')}
                              </p>
                              <div className="text-center mt-auto">
                                <a href="#" className="btn btn_red_rp mb-2" onClick={() => setActiveTab('video')}>{t('MY_VIDEO')}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      {
        selectedPermanentVideo === null ?
          null :
          <PartnerHighLight video={selectedPermanentVideo}/>
      }
      {
        selectedPermanentVideo === null ?
          null :
          <PartnerVideoSharing video={selectedPermanentVideo}/>
      }
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
      <Modal show={isStorageModalVisible} onHide={hideStorageModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('MY_VIDEO_MANAGE_SPACE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            {t('MY_VIDEO_CHOOSE_SPACE')}
            <Formik
              initialValues={{storageType: profile.storageType}}
              onSubmit={changeStorageType}
            >
              {({
                handleSubmit,
                setFieldValue,
                values
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XS',{price: storagePrices.xs})} checked={values.storageType === "xs"} onChange={() => setFieldValue('storageType', "xs")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_S',{price: storagePrices.s})} checked={values.storageType === "s"} onChange={() => setFieldValue('storageType', "s")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_M',{price: storagePrices.m})} checked={values.storageType === "m"} onChange={() => setFieldValue('storageType', "m")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_L',{price: storagePrices.l})} checked={values.storageType === "l"} onChange={() => setFieldValue('storageType', "l")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XL',{price: storagePrices.xl})} checked={values.storageType === "xl"} onChange={() => setFieldValue('storageType', "xl")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XXL',{price: storagePrices.xxl})} checked={values.storageType === "xxl"} onChange={() => setFieldValue('storageType', "xxl")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XXXL',{price: storagePrices.xxxl})} checked={values.storageType === "xxxl"} onChange={() => setFieldValue('storageType', "xxxl")}/>
                    </Form.Group>
                    <Button type="submit" variant="secondary" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideStorageModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isPartnerVideoCreationVisible} onHide={hidePartnerVideoCreationModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('ADD_PARTNER_VIDEO')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            <Formik
              enableReinitialize
              initialValues={{title: null, description: null}}
              onSubmit={createPartnerVideo}
            >
              {({
                handleSubmit,
                setFieldValue,
                handleChange,
                values
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>{t('TITLE')}</Form.Label>
                      <Form.Control required name="title" placeholder={t('TITLE')} type="text" maxLength="60" value={values.title || ''} onChange={handleChange}/>
                      <Form.Text>{t('CHARACTERS_LIMIT',{limit: 60})}</Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('DESCRIPTION')}</Form.Label>
                      <Form.Control required name="description" placeholder={t('DESCRIPTION')} type="text" maxLength="250" value={values.description || ''} onChange={handleChange}/>
                      <Form.Text>{t('CHARACTERS_LIMIT', {limit: 250})}</Form.Text>
                    </Form.Group>
                    <Button type="submit" variant="secondary" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hidePartnerVideoCreationModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
      {selectedPartnerVideo ?
      <Modal show={isPartnerVideoEditModalVisible} onHide={hidePartnerVideoEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('PARTNER_VIDEO_EDIT')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            <Formik
              initialValues={{title: selectedPartnerVideo.title, description: selectedPartnerVideo.description, videoName: selectedPartnerVideo.videoName, videoPrice: selectedPartnerVideo.videoPrice, videoCost: selectedPartnerVideo.videoCost, uploadFilename: selectedPartnerVideo.uploadFilename, uploadDate: selectedPartnerVideo.uploadDate, filesize: selectedPartnerVideo.filesize, public: selectedPartnerVideo.public, rtv: selectedPartnerVideo.rtv}}
              onSubmit={values => editPartnerVideo(selectedPartnerVideo.videoId, values, selectedPlayers)}
            >
              {({
                handleSubmit,
                setFieldValue,
                handleChange,
                values
              }) => {
                const imagePartnerVideo = (selectedPartnerVideo.coverImage || []).map(f => {
                  return {
                    source: f,
                    options: {
                      type: 'local'
                    }
                  };
                });
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                    <div style={{textAlign: 'right'}}>
                      <p>id: {selectedPartnerVideo.videoId}</p>
                    </div>
                      <Form.Label>{t('TITLE')}</Form.Label>
                      <Form.Control required name="title" placeholder={t('TITLE')} type="text" maxLength="60" value={values.title || ''} onChange={handleChange}/>
                      <Form.Text>{t('CHARACTERS_LIMIT',{limit: 60})}</Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('DESCRIPTION')}</Form.Label>
                      <Form.Control required name="description" placeholder={t('DESCRIPTION')} type="text" maxLength="250" value={values.description || ''} onChange={handleChange}/>
                      <Form.Text>{t('CHARACTERS_LIMIT', {limit: 250})}</Form.Text>
                    </Form.Group>
                    {values.videoName ?
                    <div>
                      <Form.Group>
                        <Form.Label>{t('FILE_NAME')}</Form.Label>
                        <Form.Control name="uploadFilename" type="text" value={values.uploadFilename || ''} readOnly/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('FILE_SIZE')}</Form.Label>
                        <Form.Control name="filesize" type="text" value={(Math.floor(values.filesize)/(1000000000)).toFixed(2) + ' GB' || ''} readOnly/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('UPLOAD_DATE')}</Form.Label>
                        <Form.Control name="uploadDate" type="text" value={values.uploadDate.replace(/T.*/,'').split('-').reverse().join('/') || ''} readOnly/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('VIDEO_COST')}</Form.Label>
                        <Form.Control name="videoCost" type="text" value={values.videoCost.toFixed(2).replace('.',',') || ''} readOnly/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('VIDEO_PRICE')}*</Form.Label>
                        <Form.Control required name="videoPrice" type="number" placeholder={t('VIDEO_PRICE')} min={values.videoCost} step={0.01} value={values.videoPrice} onChange={handleChange}/>
                      </Form.Group>
                      {profile.rtvChannels.filter(c => c.type === 1).length ?
                      <Form.Group>
                        <Form.Check name="rtv" type="checkbox" label={t('PUBLISH_ON_RTV')} checked={values.rtv} onChange={() => setFieldValue('rtv', !values.rtv)}/>
                      </Form.Group>
                      : null}
                      <Form.Group>
                        <Form.Check name="videoSellPermission" type="checkbox" label={t('SALABLE_VIDEO')} checked={values.public !== null} onChange={() => setFieldValue('public', values.public !== null ? null : true)}/>
                      </Form.Group>
                    </div>
                    : null}
                    {values.public !== null ?
                      <Form.Group>
                        <Form.Check required name="public" type="radio" label={t('PUBLIC')} checked={values.public} onChange={() => setFieldValue('public', true)}/>
                        <Form.Check required name="public" type="radio" label={t('PRIVATE')} checked={!values.public} onChange={() => setFieldValue('public', false)}/>
                      </Form.Group>
                    : null}
                    {values.public === false ?
                      <Form.Group>
                        <Form.Label>{t('PLAYERS_SELECTION')}</Form.Label>
                        {renderPlayer(selectedPlayers)}
                        <Button variant="secondary" onClick={showPlayersModal}>{t('ADD_PLAYER')}</Button>
                      </Form.Group>
                    : null}
                    <Form.Label>{t('VIDEO_COVER')}</Form.Label>
                    <FilePond
                      files={imagePartnerVideo}
                      server={
                        {
                          url: `${Configuration.apiEndPoint}/`,
                          process: {
                            url: `./video_partners/${selectedPartnerVideo.videoId}/images`,
                            ondata: formData => {
                              formData.append('image_type', imageTypes.partnerVideoCover);
                              return formData;
                            }
                          },
                          revert: `./video_partners/${selectedPartnerVideo.videoId}/images`,
                          restore: `./video_partners/${selectedPartnerVideo.videoId}/images`,
                          fetch: `./video_partners/${selectedPartnerVideo.videoId}/images`,
                          load: `./video_partners/${selectedPartnerVideo.videoId}/images?load=`,
                          headers: {Authorization: `Bearer ${token}`},
                          remove: (source, load) => {
                            removeFile(source, selectedPartnerVideo.videoId, selectedPartnerVideo, selectedPartnerVideo);
                            load();
                          }
                        }
                      }
                    />
                    <Button type="submit" variant="secondary" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                  </Form>
                );
              }}
              
            </Formik>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hidePartnerVideoEditModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
      : null}
      {isPlayersModalVisible ?
        <Modal show={isPlayersModalVisible} onHide={hidePlayersModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('ADD_PLAYER')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
                enableReinitialize
                initialValues={{selectedPlayers}}
                onSubmit={values => setSelectedPlayers(values.selectedPlayers)}
              >
                {({
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  values
                }) => {

                const addInvitedPlayer = (player) => {
                  let alreadyInvited = false;
                  values.selectedPlayers.forEach(p => {
                    if(p.uid === player.uid) alreadyInvited = true;
                  });
                  if(!alreadyInvited) values.selectedPlayers.push(player);
                  setSelectedPlayer({});
                  values.playerName = "";
                  values.playerSurname = "";
                  values.playerNickname = "";
                  hidePlayersModal();
                };

                return(
                  <Form onSubmit={handleSubmit}>
                      <Fragment>
                        <p>{t('THREE_CHAR_MINIMUM')}</p>
                        <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                          <Form.Control name="playerNickname" style={{maxWidth: 150}} placeholder={t('NICKNAME')} type="text" maxLength="25" minLength="3" value={values.playerNickname || ""} onChange={handleChange}/>
                          <Form.Control name="playerName" style={{maxWidth: 150}} placeholder={t('NAME')} type="text" maxLength="25" minLength="3" value={values.playerName || ""} onChange={handleChange}/>
                          <Form.Control name="playerSurname" style={{maxWidth: 150}} placeholder={t('SURNAME')} type="text" maxLength="25" minLength="3" value={values.playerSurname || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
                          <Form.Check name="playerAffiliate" type="checkbox" label={t('PLAYER_AFFILIATE',{partner: profile.name})} checked={values.playerAffiliate === true} onChange={() => setFieldValue('playerAffiliate', !values.playerAffiliate)}/>
                          <Button disabled={!(values.playerNickname && values.playerNickname.length > 2 || values.playerName && values.playerName.length > 2 || values.playerSurname && values.playerSurname.length > 2)} variant="secondary" onClick={() => searchPlayers(values.playerNickname, values.playerName, values.playerSurname, values.playerAffiliate)}>{t('SEARCH')}</Button>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', flexDirection: 'column'}}>
                        {playersList.map(p => 
                          renderPlayerSearch(p)
                        )}
                        <Button style={{maxWidth: 100}}disabled={selectedPlayer.uid === undefined} variant="secondary" onClick={() => addInvitedPlayer(selectedPlayer)}>{t('SELECT')}</Button>
                        </Form.Group>
                      </Fragment>
                  </Form>
                )}}
            </Formik>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={hidePlayersModal}>
              {t('CLOSE_BUTTON')}
            </Button>
          </Modal.Footer>
        </Modal>
        : null}
    </Fragment>
  );
};

PartnerVideo.propTypes = {
  token: PropTypes.string,
  prifile: PropTypes.object,
  permanentVideos: PropTypes.array,
  storagePrices: PropTypes.object,
  selectVideoForHighlight: PropTypes.func.isRequired,
  selectedPermanentVideo: PropTypes.object,
  deletePartnerPermanentVideo: PropTypes.func.isRequired,
  deletePlaylist: PropTypes.func.isRequired,
  modifyPlaylist: PropTypes.func.isRequired,
  modifyPartnerPermanentVideo: PropTypes.func.isRequired,
  showVideosSharing: PropTypes.func.isRequired,
  showPartnerTags: PropTypes.func.isRequired,
  userTags: PropTypes.array,
  myVideoActiveTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
  showVideoTagging: PropTypes.func.isRequired,
  selectedVideoForTagging: PropTypes.object,
  searchTaggedVideo: PropTypes.func.isRequired,
  taggedUserVideos: PropTypes.array,
  changeStorageType: PropTypes.func.isRequired,
  isStorageModalVisible: PropTypes.bool.isRequired,
  showStorageModal: PropTypes.func.isRequired,
  hideStorageModal: PropTypes.func.isRequired,
  playlists: PropTypes.array.isRequired,
  partnerVideos: PropTypes.array.isRequired,
};

PartnerVideo.defaultProps = {
  token: null,
  profile: null,
  permanentVideos: [],
  storagePrices: {},
  selectedPermanentVideo: null,
  userTags: [],
  myVideoActiveTab: "video",
  selectedVideoForTagging: null,
  taggedUserVideos: [],
  isStorageModalVisible: false,
  playlists: [],
  partnerVideos: [],
};

export default PartnerVideo;

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Nav, Navbar, NavDropdown, Button, Modal, Container} from 'react-bootstrap';
import {push} from 'connected-react-router';
import {useTranslation} from 'react-i18next';
import Cookies from 'universal-cookie';
import Flags from 'country-flag-icons/react/3x2';
import {logout} from '../actions/login';
import {hideMessage} from '../actions/messages';
import {getOwnedFields, refreshPartnerProfile, getOwnedFieldBookings, getOwnedFieldSlots, ownedFieldSelected, updatePartnerLanguage, getOwnedEvents, getVideosStorePartner, partnerGetStoragePrices, getUserTagsPartner, getTaggedUserVideosPartner, selectVideoForTaggingPartner, getPartnerVideos, myVideoActiveTab, partnerGetAffiliations, getAffiliatedPlayers, getAffiliationRenewRequests} from '../actions/partner';
import {getNameFromProfile} from '../util';
import {ROLES} from '../constants';
import logo from '../images/replayer_logo_academy.svg';
import {refreshPlayerProfile, updatePlayerLanguage, getUserTags, getSponsors as getPlayerSponsor, getTaggedUserVideos, playerGetStoragePrices, selectVideoForTagging, getLiveVideos} from '../actions/player';
import {getSponsors, searchPartners} from '../actions/admin';
import lang_icon from '../images/language.svg';
import menu_icon from '../images/menu_mobile.svg';
import player_icon from '../images/Replayer_default_Avatar.png';
import Configuration from '../config';

const MyNavbar = ({
  profile,
  message,
  hideMessage,
  logout,
  goto,
  showPartnerBookingCalendar,
  showPartnerServiceBookingCalendar,
  updatePartnerLanguage,
  updatePlayerLanguage,
  showMyVideo,
  ownedServices,
  showMyVideoPartner,
  showMyVideoPartnerSpecialist,
  gotoLiveVideos,
  gotoAffiliations
}) => {
  const {t, i18n} = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    const cookies = new Cookies();
    cookies.set('replayerLang', i18n.language);
    if (profile && profile.user.role === ROLES.partner) {
      updatePartnerLanguage(lng);
    } else if (profile && profile.user.role === ROLES.player) {
      updatePlayerLanguage(lng);
    }
  };

  return (
    <Container>
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" fixed="top" className="bg_blue_rp pt-3 pb-3">
        <Navbar.Brand href="/" onClick={profile !== null && profile.user.role === ROLES.partner ? goto('partner-fields', getOwnedFields) : goto('/')}>
          <img
            className="d-none d-md-block"
            src={logo}
            alt={t('LOGO')}
          />
          <img
            className="d-xs-block d-md-none"
            src={logo}
            alt={t('LOGO')}
            width="100"
          />
        </Navbar.Brand>
        <Nav className="mr-auto"/>
        {
          /* Menù per ruolo partner */
          profile !== null && profile.user.role === ROLES.partner ?
            <Fragment>
              <a className="btn btn_red_outline_rp mr-2 d-none d-md-block" onClick={goto('user-top-up-account')}>{t('WALLET')} &euro; {profile.user.wallet}</a>
              {/* Menù per schermi grandi */}
              <NavDropdown className="d-none d-md-block" title={getNameFromProfile(profile)} alignRight>
                <NavDropdown.Item href="/partner-profile" onClick={goto('partner-profile', refreshPartnerProfile)}>{t('PROFILE')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-fields" onClick={goto('partner-fields', getOwnedFields)}>{t('RESOURCES')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-affiliations" onClick={gotoAffiliations}>{t('MEMBERSHIPS')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-events" onClick={goto('partner-events', getOwnedEvents)}>{t('SPECIAL_EVENTS')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-booking-calendar" onClick={showPartnerBookingCalendar}>{t('FIELDS_BOOKING')}</NavDropdown.Item>
                {ownedServices.length > 0 ? <NavDropdown.Item href="/partner-service-booking-calendar" onClick={showPartnerServiceBookingCalendar}>{t('SERVICES_BOOKING')}</NavDropdown.Item> : null }
                <NavDropdown.Item href="/partner-video-store" onClick={goto('partner-video-store', getVideosStorePartner)}>{t('VIDEO_STORE')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-replayer-tv-home" onClick={goto('partner-replayer-tv-home')}>{t('REPLAYER_TV')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-video" onClick={profile.isSpecialist ? showMyVideoPartnerSpecialist : showMyVideoPartner}>{t('MY_VIDEO')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-report" onClick={goto('partner-report')}>Report</NavDropdown.Item>
                <NavDropdown.Item href="https://www.replayer.it/it/aiuto/" target="_blank">{t('HELP')}</NavDropdown.Item>
                <NavDropdown.Item href="/" onClick={logout}>{t('EXIT')}</NavDropdown.Item>
              </NavDropdown>
              {/* Menù per schermi piccoli */}
              <NavDropdown className="d-xs-block d-sm-block d-md-none" title={<img src={menu_icon} alt={t('MENU')} width={30} />} alignRight>
                <NavDropdown.Item href="/partner-profile" onClick={goto('partner-profile', refreshPartnerProfile)}>{t('PROFILE')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-fields" onClick={goto('partner-fields', getOwnedFields)}>{t('RESOURCES')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-affiliations" onClick={gotoAffiliations}>{t('MEMBERSHIPS')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-events" onClick={goto('partner-events', getOwnedEvents)}>{t('SPECIAL_EVENTS')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-booking-calendar" onClick={showPartnerBookingCalendar}>{t('FIELDS_BOOKING')}</NavDropdown.Item>
                {ownedServices.length > 0 ? <NavDropdown.Item href="/partner-service-booking-calendar" onClick={showPartnerServiceBookingCalendar}>{t('SERVICES_BOOKING')}</NavDropdown.Item> : null }
                <NavDropdown.Item href="/partner-video-store" onClick={goto('partner-video-store', getVideosStorePartner)}>{t('VIDEO_STORE')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-replayer-tv-home" onClick={goto('partner-replayer-tv-home')}>{t('REPLAYER_TV')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-video" onClick={profile.isSpecialist ? showMyVideoPartnerSpecialist : showMyVideoPartner}>{t('MY_VIDEO')}</NavDropdown.Item>
                <NavDropdown.Item href="/partner-report" onClick={goto('partner-report')}>Report</NavDropdown.Item>
                {
                  profile.user.wallet > 5 ?
                    <NavDropdown.Item href="/user-top-up-account" onClick={goto('user-top-up-account')}>{t('WALLET')} &euro; {profile.user.wallet}</NavDropdown.Item> :
                    <NavDropdown.Item href="/user-top-up-account" style={{color: 'red'}} onClick={goto('user-top-up-account')}>{t('ACCOUNT_TOPUP')}</NavDropdown.Item>
                }
                <NavDropdown.Item href="https://www.replayer.it/it/aiuto/" target="_blank">{t('HELP')}</NavDropdown.Item>
                <NavDropdown.Item href="/" onClick={logout}>{t('EXIT')}</NavDropdown.Item>
              </NavDropdown>
            </Fragment> :
            null
        }
        {
          /* Menù per ruolo player */
          profile !== null && profile.user.role === ROLES.player ?
            <Fragment>
              <a className={profile.user.wallet >= 0 ? "btn btn_red_outline_rp mr-2 d-none d-md-block" : "btn btn_red_black_text mr-2 d-none d-md-block"} onClick={goto('user-top-up-account')}>{t('WALLET')} &euro; {profile.user.wallet}</a>
              {/* Menù per schermi grandi */}
              <NavDropdown className="d-none d-md-block" title={getNameFromProfile(profile)} alignRight>
                <NavDropdown.Item href="/" onClick={goto('/')}>{t('HOME')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-show-sport-centers" onClick={goto('player-show-sport-centers')}>{t('SPORT_CENTERS')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-show-sport-centers" onClick={goto('player-show-sport-specialists')}>{'Sport Specialists'}</NavDropdown.Item>
                <NavDropdown.Item href="/player-live-videos" onClick={gotoLiveVideos}>{t('LIVE_VIDEOS')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-video-store" onClick={goto('player-video-store')}>{t('VIDEO_STORE')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-replayer-tv-home" onClick={goto('player-replayer-tv-home')}>{t('REPLAYER_TV')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-video" onClick={showMyVideo}>{t('MY_VIDEO')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-bookings" onClick={goto('player-bookings')}>{t('BOOKINGS')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-profile" onClick={goto('player-profile', refreshPlayerProfile)}>{t('PROFILE')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-sponsor" onClick={goto('player-sponsor', getPlayerSponsor)}>{t('SPONSOR_OURS')}</NavDropdown.Item>
                <NavDropdown.Item href="https://www.replayer.it/it/video-tutorial-per-lutilizzo-dei-servizi-replayer/" target="_blank">{t('VIDEO_TUTORIAL')}</NavDropdown.Item>
                <NavDropdown.Item href="https://www.replayer.it/it/aiuto/" target="_blank">{t('HELP')}</NavDropdown.Item>
                <NavDropdown.Item href="/" onClick={logout}>{t('EXIT')}</NavDropdown.Item>
              </NavDropdown>
              {/* Menù per schermi piccoli */}
              <NavDropdown className="d-xs-block d-sm-block d-md-none" title={profile.image && profile.image[0] ? <img src={`${Configuration.apiEndPoint}/images/${profile.uid}?load=${profile.image[0]}`} alt={t('MENU')} width={30} /> : <img src={player_icon} alt={t('MENU')} width={30} />} alignRight>
                <NavDropdown.Item href="/" onClick={goto('/')}>{t('HOME')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-profile" style={{color: '#dd004a'}} onClick={goto('player-profile', refreshPlayerProfile)}>{getNameFromProfile(profile)}</NavDropdown.Item>
                <NavDropdown.Item href="/player-show-sport-centers" onClick={goto('player-show-sport-centers')}>{t('SPORT_CENTERS')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-show-sport-centers" onClick={goto('player-show-sport-specialists')}>{'Sport Specialists'}</NavDropdown.Item>
                <NavDropdown.Item href="/player-live-videos" onClick={gotoLiveVideos}>{t('LIVE_VIDEOS')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-video-store" onClick={goto('player-video-store')}>{t('VIDEO_STORE')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-replayer-tv-home" onClick={goto('player-replayer-tv-home')}>{t('REPLAYER_TV')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-video" onClick={showMyVideo}>{t('MY_VIDEO')}</NavDropdown.Item>
                <NavDropdown.Item href="/player-bookings" onClick={goto('player-bookings')}>{t('BOOKINGS')}</NavDropdown.Item>
                {
                  profile.user.wallet > 5 ?
                    <NavDropdown.Item href="/user-top-up-account" style={{color: '#dd004a'}} onClick={goto('user-top-up-account')}>{t('WALLET')} &euro; {profile.user.wallet}</NavDropdown.Item> :
                    <NavDropdown.Item href="/user-top-up-account" style={{color: 'red'}} onClick={goto('user-top-up-account')}><strong>{t('ACCOUNT_TOPUP')}</strong></NavDropdown.Item>
                }
                <NavDropdown.Item href="/player-sponsor" onClick={goto('player-sponsor', getPlayerSponsor)}>{t('SPONSOR_OURS')}</NavDropdown.Item>
                <NavDropdown.Item href="https://www.replayer.it/it/video-tutorial-per-lutilizzo-dei-servizi-replayer/" target="_blank">{t('VIDEO_TUTORIAL')}</NavDropdown.Item>
                <NavDropdown.Item href="https://www.replayer.it/it/aiuto/" target="_blank">{t('HELP')}</NavDropdown.Item>
                <NavDropdown.Item href="/" onClick={logout}>{t('EXIT')}</NavDropdown.Item>
              </NavDropdown>
            </Fragment> :
            null
        }
        {
          /* Menù per ruolo admin */
          profile !== null && profile.user.role === ROLES.admin ?
            <Fragment>
              {/* Menù per schermi grandi */}
              <NavDropdown className="d-none d-md-block" title="ADMIN" alignRight>
                <NavDropdown.Item href="/" onClick={goto('/')}>{t('HOME')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-create-partner" onClick={goto('/admin-create-partner')}>{t('CREATE_PARTNER')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-partner-management" onClick={goto('/admin-partner-management')}>{t('PARTNER_ADMINISTRATION')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-rtv-categories" onClick={goto('/admin-rtv-categories', searchPartners)}>{t('RTV_CHANNELS_CATEGORIES')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-player-management" onClick={goto('/admin-player-management')}>{t('PLAYER_ADMINISTRATION')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-slot-management" onClick={goto('/admin-slot-management')}>{t('SLOT_ADMINISTRATION')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-report" onClick={goto('/admin-report')}>Report</NavDropdown.Item>
                <NavDropdown.Item href="/admin-additional-space" onClick={goto('/admin-additional-space')}>{t('ADDITIONAL_SPACE')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-sponsor" onClick={goto('/admin-sponsor', getSponsors)}>{t('SPONSOR')}</NavDropdown.Item>
                <NavDropdown.Item href="/" onClick={logout}>{t('EXIT')}</NavDropdown.Item>
              </NavDropdown>
              {/* Menù per schermi piccoli */}
              <NavDropdown className="d-xs-block d-sm-block d-md-none" title={<img src={menu_icon} alt={t('LOGO')} width={30} />} alignRight>
                <NavDropdown.Item href="/" onClick={goto('/')}>{t('HOME')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-create-partner" onClick={goto('/admin-create-partner')}>{t('CREATE_PARTNER')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-partner-management" onClick={goto('/admin-partner-management')}>{t('PARTNER_ADMINISTRATION')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-rtv-categories" onClick={goto('/admin-rtv-categories', searchPartners)}>{t('RTV_CHANNELS_CATEGORIES')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-player-management" onClick={goto('/admin-player-management')}>{t('PLAYER_ADMINISTRATION')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-slot-management" onClick={goto('/admin-slot-management')}>{t('SLOT_ADMINISTRATION')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-report" onClick={goto('/admin-report')}>Report</NavDropdown.Item>
                <NavDropdown.Item href="/admin-additional-space" onClick={goto('/admin-additional-space')}>{t('ADDITIONAL_SPACE')}</NavDropdown.Item>
                <NavDropdown.Item href="/admin-sponsor" onClick={goto('/admin-sponsor', getSponsors)}>{t('SPONSOR')}</NavDropdown.Item>
                <NavDropdown.Item href="/" onClick={logout}>{t('EXIT')}</NavDropdown.Item>
              </NavDropdown>
            </Fragment> :
            null
        }
        {
          <NavDropdown title={
            i18n.language === 'it' ? <Flags.IT style={{width: 25}}/> :
            i18n.language === 'en' ? <Flags.GB style={{width: 25}}/> :
            i18n.language === 'fr' ? <Flags.FR style={{width: 25}}/> :
            <Flags.DE style={{width: 25}}/>} 
           alignRight>
            <NavDropdown.Item onClick={() => changeLanguage('it')}><Flags.IT title='Italian' style={{width: 25}}></Flags.IT></NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('en')}><Flags.GB title='English' style={{width: 25}}></Flags.GB></NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('fr')}><Flags.FR title='French' style={{width: 25}}></Flags.FR></NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('de')}><Flags.DE title='German' style={{width: 25}}></Flags.DE></NavDropdown.Item>
          </NavDropdown>
        }
      </Navbar>
      <Modal className="modal_rp" contentClassName="bg_blue_rp" show={message !== null} onHide={hideMessage}>
        <Modal.Header closeButton>
          <Modal.Title>{message === null ? null : t(message.title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message === null ? null : t(message.body, message.options)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideMessage}>{t('CLOSE_BUTTON')}</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

MyNavbar.propTypes = {
  profile: PropTypes.object,
  message: PropTypes.object,
  goto: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showPartnerBookingCalendar: PropTypes.func.isRequired,
  showPartnerServiceBookingCalendar: PropTypes.func.isRequired,
  updatePartnerLanguage: PropTypes.func.isRequired,
  updatePlayerLanguage: PropTypes.func.isRequired,
  showMyVideo: PropTypes.func.isRequired
};

MyNavbar.defaultProps = {
  profile: null,
  message: null,
  ownedServices: [],
};

const mapStateToProps = ({login: {profile}, partner: {ownedServices}, message, loading}) => ({
  profile,
  message,
  loading,
  ownedServices
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  },
  hideMessage: () => {
    dispatch(hideMessage());
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  showPartnerBookingCalendar: e => {
    e.preventDefault();
    dispatch(getOwnedFieldBookings(new Date(), 'WEEK'));
    dispatch(getOwnedFieldSlots(null, true, 'field', new Date(), 'WEEK'));
    dispatch(ownedFieldSelected(null));
    dispatch(push('partner-booking-calendar'));
  },
  showPartnerServiceBookingCalendar: e => {
    e.preventDefault();
    dispatch(getOwnedFieldBookings(new Date(), 'WEEK', 'service'));
    dispatch(getOwnedFieldSlots(null, true, 'service', new Date(), 'WEEK'));
    dispatch(ownedFieldSelected(null));
    dispatch(push('partner-service-booking-calendar'));
  },
  updatePartnerLanguage: language => {
    dispatch(updatePartnerLanguage(language));
  },
  updatePlayerLanguage: language => {
    dispatch(updatePlayerLanguage(language));
  },
  showMyVideo: e => {
    e.preventDefault();
    dispatch(getUserTags());
    dispatch(getTaggedUserVideos());
    dispatch(playerGetStoragePrices());
    dispatch(selectVideoForTagging(null));
    dispatch(push('player-video'));
  },
  showMyVideoPartner: e => {
    e.preventDefault();
    dispatch(getUserTagsPartner());
    dispatch(getPartnerVideos());
    dispatch(getTaggedUserVideosPartner());
    dispatch(partnerGetStoragePrices());
    dispatch(selectVideoForTaggingPartner(null));
    dispatch(push('partner-video'));
  },
  showMyVideoPartnerSpecialist: e => {
    e.preventDefault();
    dispatch(getUserTagsPartner());
    dispatch(getPartnerVideos());
    dispatch(getTaggedUserVideosPartner());
    dispatch(partnerGetStoragePrices());
    dispatch(selectVideoForTaggingPartner(null));
    dispatch(myVideoActiveTab("partner_videos"));
    dispatch(push('partner-video'));
  },
  gotoLiveVideos: e => {
    e.preventDefault();
    dispatch(getLiveVideos());
    dispatch(push('/player-live-videos'));
  },
  gotoAffiliations: e => {
    e.preventDefault();
    dispatch(getAffiliatedPlayers());
    dispatch(getAffiliationRenewRequests());
    dispatch(push('/partner-affiliations'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyNavbar));

/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Fade, Container, Row, Col, Form, FormGroup} from 'react-bootstrap';
import {PayPalButton} from 'react-paypal-button-v2';
import {Redirect} from 'react-router-dom';
import CommonMetaTag from './common-meta-tag';
import {Formik} from 'formik';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Configuration from '../config';
import {imageTypes} from '../constants';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {AiOutlineEdit, AiOutlinePlusSquare} from 'react-icons/ai';
import {useTranslation} from 'react-i18next';
registerPlugin(FilePondPluginImagePreview);

const PartnerProfile = ({
  profile, save, token, removeFile, topUpWalletSuccess, showNotClosePaypalMessage, ownedRtvChannels, deleteRtvChannelImageFile, showInvalidAmountMessage
}) => {
  const {t, i18n} = useTranslation();
  if (profile === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const imageLogo = (profile.storeImage || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  const imagesNames = profile.imagesNames.map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  const imageRtv = (profile.rtvImage || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  const imagePartnerVideo = (profile.videoImage || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  const isValidTopupAmount = (amount) => {
    return amount === undefined || amount === "" || (amount >= 5 && amount <= 9999);
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100}}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6}>
              <h4>{profile.isSpecialist ? t('PARTNER_PROFILE_SPECIALIST') : t('PARTNER_PROFILE')}</h4>
              <Form.Label>{t('BUSINESS_NAME')}: {profile.businessName}</Form.Label><br/>
              <Form.Label>{t('REGISTERED_OFFICE')}: {profile.registeredOffice}</Form.Label><br/>
              <Form.Label>{t('VAT_NUMBER')}: {profile.iva}</Form.Label><br/>
              <Form.Label>{t('SDI_PEC')}: {profile.sdiPec}</Form.Label><br/>
              <Form.Label>{t('EMAIL')}: {profile.user.email}</Form.Label><br/>
              <Form.Label>{t('LOGO')}</Form.Label>
              <Form.Text className="text-muted">{t('IMAGES_SIZE', {size1: '512', size2: '512'})}</Form.Text>
              <FilePond
                files={imageLogo}
                server={
                  {
                    url: `${Configuration.apiEndPoint}/`,
                    process: {
                      url: './images',
                      ondata: formData => {
                        formData.append('image_type', imageTypes.logo);
                        return formData;
                      }
                    },
                    revert: './images',
                    restore: './images',
                    fetch: './images',
                    load: `./images/${profile.uid}?load=`,
                    headers: {Authorization: `Bearer ${token}`},
                    remove: (source, load) => {
                      removeFile(source);
                      load();
                    }
                  }
                }
              />
              <Form.Label>{t('PHOTO')}</Form.Label>
              <FilePond
                allowMultiple
                files={imagesNames}
                server={
                  {
                    url: `${Configuration.apiEndPoint}/`,
                    process: {
                      url: './images',
                      ondata: formData => {
                        formData.append('image_type', imageTypes.partner);
                        return formData;
                      }
                    },
                    revert: './images',
                    restore: './images',
                    fetch: './images',
                    load: `./images/${profile.uid}?load=`,
                    headers: {Authorization: `Bearer ${token}`},
                    remove: (source, load) => {
                      removeFile(source);
                      load();
                    }
                  }
                }
                maxFiles={3}
              />

              {
                profile.rtvChannels.length > 0 ?
                  <Fragment>
                    <Form.Label>{t('REPLAYER_TV_BACKGROUND')}</Form.Label>
                    <Form.Text className="text-muted">{t('IMAGES_SIZE', {size1: '2560', size2: '1440'})}</Form.Text>
                  </Fragment> :
                  null
              }
              
              {
                profile.rtvChannels.map(c => (
                  <Fragment>
                    <Form.Label>RTV {c.name}</Form.Label>
                    <FilePond
                      allowMultiple
                      files={(c.imagesNames || []).map(f => {
                        return {
                          source: f,
                          options: {
                            type: 'local'
                          }
                        };
                      })}
                      server={
                        {
                          url: `${Configuration.apiEndPoint}/partners/`,
                          process: {
                            url: `./rtv_channels/${c.id}/images`,
                            ondata: formData => {
                              formData.append('image_type', imageTypes.rtv);
                              return formData;
                            }
                          },
                          revert: `./rtv_channels/${c.id}/images`,
                          restore: `./rtv_channels/${c.id}/images`,
                          fetch: `./rtv_channels/${c.id}/images`,
                          load: `./rtv_channels/${c.id}/images?load=`,
                          headers: {Authorization: `Bearer ${token}`},
                          remove: (source, load) => {
                            deleteRtvChannelImageFile(source, c.id);
                            load();
                          }
                        }
                      }
                      maxFiles={3}
                    />
                  </Fragment>
                ))
              }              
              
              {
                profile.rtvChannels.length > 0 ?
                  <Fragment>
                    <Form.Label>{t('LOGO')}</Form.Label>
                    <Form.Text className="text-muted">{t('IMAGES_SIZE', {size1: '512', size2: '512'})}</Form.Text>
                  </Fragment> :
                  null
              }

              {
                profile.rtvChannels.map(c => (
                  <Fragment>
                    <Form.Label>RTV {c.name}</Form.Label>
                    <FilePond
                      allowMultiple
                      files={(c.logoNames || []).map(f => {
                        return {
                          source: f,
                          options: {
                            type: 'local'
                          }
                        };
                      })}
                      server={
                        {
                          url: `${Configuration.apiEndPoint}/partners/`,
                          process: {
                            url: `./rtv_channels/${c.id}/images`,
                            ondata: formData => {
                              formData.append('image_type', imageTypes.logo);
                              return formData;
                            }
                          },
                          revert: `./rtv_channels/${c.id}/images`,
                          restore: `./rtv_channels/${c.id}/images`,
                          fetch: `./rtv_channels/${c.id}/images`,
                          load: `./rtv_channels/${c.id}/images?load=`,
                          headers: {Authorization: `Bearer ${token}`},
                          remove: (source, load) => {
                            deleteRtvChannelImageFile(source, c.id);
                            load();
                          }
                        }
                      }
                      maxFiles={3}
                    />
                  </Fragment>
                ))
              }
              <Form.Label>{t('PARTNER_VIDEOS')}</Form.Label>
              <FilePond
                files={imagePartnerVideo}
                server={
                  {
                    url: `${Configuration.apiEndPoint}/`,
                    process: {
                      url: './images',
                      ondata: formData => {
                        formData.append('image_type', imageTypes.partnerVideoCover);
                        return formData;
                      }
                    },
                    revert: './images',
                    restore: './images',
                    fetch: './images',
                    load: `./images/${profile.uid}?load=`,
                    headers: {Authorization: `Bearer ${token}`},
                    remove: (source, load) => {
                      removeFile(source);
                      load();
                    }
                  }
                }
              />        
              <Formik
                enableReinitialize
                initialValues={Object.assign({}, profile)}
                onSubmit={save}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values
                }) => {
                  return (
                    <Container>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label>{t('NAME')}*</Form.Label>
                          <Form.Control required name="name" type="text" placeholder={t('NAME')} maxLength={50} value={values.name || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('NAME')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('ADDRESS')}*</Form.Label>
                          <Form.Control required name="address" type="text" placeholder={t('ADDRESS')} maxLength={255} value={values.address || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('ADDRESS')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>{t('PROVINCE')}*</Form.Label>
                              <Form.Control required name="province" type="text" placeholder={t('PROVINCE')} maxLength={2} value={values.province || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('PROVINCE')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>{t('ZIP_CODE')}*</Form.Label>
                              <Form.Control required name="CAP" type="text" placeholder={t('ZIP_CODE')} maxLength={5} value={values.CAP || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('ZIP_CODE')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>{t('CITY')}*</Form.Label>
                              <Form.Control required name="city" type="text" placeholder={t('CITY')} maxLength={255} value={values.city || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('CITY')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Form.Group>
                          <Form.Label>{t('TELEPHONE')}*</Form.Label>
                          <Form.Control required name="phoneNumber" type="tel" placeholder={t('TELEPHONE')} maxLength={13} value={values.phoneNumber || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('TELEPHONE')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                          <Form.Control required name="description" type="text" placeholder={t('DESCRIPTION')} maxLength={255} value={values.description || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('DESCRIPTION')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('DESCRIPTION_ACCURATE')}*</Form.Label>
                          <Form.Control required name="extDescription" as="textarea" rows="7" placeholder={t('DESCRIPTION_ACCURATE')} maxLength={1000} value={values.extDescription || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('DESCRIPTION_ACCURATE')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('SPORTS_CENTER_HOURS')}</Form.Label>
                          <Form.Control name="openingHour.sportsCenter" as="textarea" rows="7" placeholder={t('SPORTS_CENTER_HOURS')} maxLength={1000} value={values.openingHour ? values.openingHour.sportsCenter : ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('SPORTS_CENTER_HOURS')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('SECRETARIAT_HOURS')}</Form.Label>
                          <Form.Control name="openingHour.secretary" as="textarea" rows="7" placeholder={t('SECRETARIAT_HOURS')} maxLength={1000} value={values.openingHour ? values.openingHour.secretary : ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('SECRETARIAT_HOURS')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('VISIBILITY_CALENDAR')}*</Form.Label>
                          <Form.Control required name="defaultVisibilityCalendar" type="number" min="0" max="90" step="1" placeholder={t('VISIBILITY_CALENDAR')} value={values.defaultVisibilityCalendar >= 0 ? values.defaultVisibilityCalendar : ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('VISIBILITY_CALENDAR')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('SERVICES')}</Form.Label>
                          <Form.Check name="facilities.parking" type="checkbox" label={t('PARK')} checked={values.facilities && values.facilities.parking} onChange={() => setFieldValue('facilities.parking', !(values.facilities && values.facilities.parking))}/>
                          <Form.Check name="facilities.lockerRooms" type="checkbox" label={t('LOCKER_ROOMS')} checked={values.facilities && values.facilities.lockerRooms} onChange={() => setFieldValue('facilities.lockerRooms', !(values.facilities && values.facilities.lockerRooms))}/>
                          <Form.Check name="facilities.showers" type="checkbox" label={t('SHOWERS')} checked={values.facilities && values.facilities.showers} onChange={() => setFieldValue('facilities.showers', !(values.facilities && values.facilities.showers))}/>
                          <Form.Check name="facilities.restaurant" type="checkbox" label={t('RESTAURANT_PIZZERIA')} checked={values.facilities && values.facilities.restaurant} onChange={() => setFieldValue('facilities.restaurant', !(values.facilities && values.facilities.restaurant))}/>
                          <Form.Check name="facilities.bar" type="checkbox" label={t('BAR_PUB')} checked={values.facilities && values.facilities.bar} onChange={() => setFieldValue('facilities.bar', !(values.facilities && values.facilities.bar))}/>
                          <Form.Check name="facilities.shop" type="checkbox" label={t('SHOP_RENTAL')} checked={values.facilities && values.facilities.shop} onChange={() => setFieldValue('facilities.shop', !(values.facilities && values.facilities.shop))}/>
                          <Form.Check name="facilities.picnic" type="checkbox" label={t('PARTY_PICNIC')} checked={values.facilities && values.facilities.picnic} onChange={() => setFieldValue('facilities.picnic', !(values.facilities && values.facilities.picnic))}/>
                          <Form.Check name="facilities.tournaments" type="checkbox" label={t('TOURNAMENTS')} checked={values.facilities && values.facilities.tournaments} onChange={() => setFieldValue('facilities.tournaments', !(values.facilities && values.facilities.tournaments))}/>
                          <Form.Check name="facilities.petFriendly" type="checkbox" label={t('PET_FRIENDLY')} checked={values.facilities && values.facilities.petFriendly} onChange={() => setFieldValue('facilities.petFriendly', !(values.facilities && values.facilities.petFriendly))}/>
                          <Form.Check name="facilities.accessibility" type="checkbox" label={t('DISABLED_ACCESS')} checked={values.facilities && values.facilities.accessibility} onChange={() => setFieldValue('facilities.accessibility', !(values.facilities && values.facilities.accessibility))}/>
                          <Form.Check name="facilities.relaxArea" type="checkbox" label={t('RELAX_AREA')} checked={values.facilities && values.facilities.relaxArea} onChange={() => setFieldValue('facilities.relaxArea', !(values.facilities && values.facilities.relaxArea))}/>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('WEBSITE')}</Form.Label>
                          <Form.Control name="website" type="url" placeholder={t('WEBSITE')} maxLength={253} value={values.website || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('WEBSITE')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('FACEBOOK')}</Form.Label>
                          <Form.Control name="facebook" type="url" placeholder={t('FACEBOOK')} maxLength={2048} value={values.facebook || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('FACEBOOK')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('INSTAGRAM')}</Form.Label>
                          <Form.Control name="instagram" type="url" placeholder={t('INSTAGRAM')} maxLength={2048} value={values.instagram || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('INSTAGRAM')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('YOUTUBE')}</Form.Label>
                          <Form.Control name="youtube" type="url" placeholder={t('YOUTUBE')} maxLength={2048} value={values.youtube || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                            {t('YOUTUBE')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('READY_TO_GO')}</Form.Label>
                            <Col className="d-flex flex-column">
                              <Form.Check required name="visible" type="radio" label={t('PARTNER_DRAFT')} checked={!values.visible} onChange={() => setFieldValue('visible', false)}/>
                              <Form.Check required name="visible" type="radio" label={t('PARTNER_PUBLIC')} checked={values.visible} onChange={() => setFieldValue('visible', true)}/>
                            </Col>
                        </Form.Group>
                        <Button type="submit" style={{marginBottom: 50}}>Salva</Button>
                        <h4>{t('MY_CREDIT')}</h4>
                        <Form.Group>
                          <Form.Label>{t('CREDIT_VALUE', {value: values.user.wallet})}</Form.Label>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('ACCOUNT_TOPUP')} (€)</Form.Label>
                          <Form.Control type="number" name="topupAmount" value={values.topupAmount || null} step={1} min={5} max={9999} onChange={handleChange}/>
                          <Form.Text className="text-muted">{t('MIN_MAX',{min: '5,00 €', max: '9999,00 €'})}</Form.Text>
                        </Form.Group>
                        <PayPalButton
                          options={{
                            clientId: Configuration.paypalClientId,
                            currency: 'EUR'
                          }}
                          createOrder={(data, actions) => {
                            if (!isValidTopupAmount(values.topupAmount)) {
                              showInvalidAmountMessage();
                              return Promise.reject(new Error('Errore durante l\'operazione'));
                            }
                            return actions.order.create({
                              purchase_units: [{
                                amount: {
                                  value: values && values.topupAmount ? values.topupAmount : 5
                                }
                              }],
                              application_context: {
                                locale: 'it-IT',
                                shipping_preference: 'NO_SHIPPING'
                              }
                            }).then(orderID => {
                              // Send order created to the server
                              return fetch(`${Configuration.apiEndPoint}/paypal/order_created`, {
                                method: 'POST',
                                headers: {
                                  'content-type': 'application/json',
                                  Accept: 'application/json',
                                  Authorization: `Bearer ${token}`
                                },
                                mode: 'cors',
                                body: JSON.stringify({
                                  orderID
                                })
                              }).then(res => {
                                // TODO: Mostrare errore in caso di fallimento di questa richiesta
                                return res.json();
                              }).then(data => {
                                return orderID;
                              });
                            });
                          }}
                          onApprove={(data, actions) => {
                            showNotClosePaypalMessage();
                            // Capture the funds from the transaction
                            return actions.order.capture().then(details => {
                              // Send approved order to the server
                              return fetch(`${Configuration.apiEndPoint}/paypal/order_approved`, {
                                method: 'POST',
                                headers: {
                                  'content-type': 'application/json',
                                  Accept: 'application/json',
                                  Authorization: `Bearer ${token}`
                                },
                                mode: 'cors',
                                body: JSON.stringify({
                                  orderID: data.orderID
                                })
                              }).then(res => {
                                return res.json();
                              }).then(() => {
                                topUpWalletSuccess();
                              });
                            });
                          }}
                          onShippingChange={(data,actions) => {
                            return actions.resolve();
                          }}
                        />
                      </Form>
                      <div style={{border: '1px solid #DD004A', padding: 20, marginTop: 30}}>
                        <h4>{t('WARNING')}</h4>
                        <p>{t('ACCOUNT_TOPUP_NOTES')}</p>
                      </div>
                    </Container>
                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

PartnerProfile.propTypes = {
  profile: PropTypes.object,
  save: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  token: PropTypes.string,
  topUpWalletSuccess: PropTypes.func.isRequired,
  showNotClosePaypalMessage: PropTypes.func.isRequired,
  deleteRtvChannelImageFile: PropTypes.func.isRequired,
};

PartnerProfile.defaultProps = {
  profile: null,
  token: null
};

export default PartnerProfile;

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Fade, Container, Row} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import queryString from 'query-string';
import {confirmPlayerAffiliation} from '../actions/player';
import {useTranslation} from 'react-i18next';

const ConfirmPlayerAffiliation = ({location: {search}, confirmPlayerAffiliation}) => {
  const {t, i18n} = useTranslation();
  const {affiliationId, playerId, token} = queryString.parse(search);
  confirmPlayerAffiliation(affiliationId, playerId, token);

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
        <Container>
          <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <h4>{t('PLAYER_GROUP_CONFIRM')}</h4>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

ConfirmPlayerAffiliation.propTypes = {
  location: PropTypes.object.isRequired,
  confirmPlayerAffiliation: PropTypes.func.isRequired
};

export default (connect(
  null,
  dispatch => ({
    confirmPlayerAffiliation: (affiliationId, playerId, token) => {
      dispatch(confirmPlayerAffiliation(affiliationId, playerId, token));
    }
  })
)(ConfirmPlayerAffiliation));

